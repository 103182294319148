import { RootState } from '@app/redux/root-reducer';

export const selectInventoryState = (store: RootState) => store.inventorySlice.inventories;
export const selectInventoryDetailState = (store: RootState) =>
  store.inventorySlice.inventoryDetail;
export const selectAddInventoryState = (store: RootState) => store.inventorySlice.addInventory;
export const selectAddBulkInventoryState = (store: RootState) =>
  store.inventorySlice.addBulkInventory;
export const selectEditInventoryStockState = (store: RootState) =>
  store.inventorySlice.editInventoryStock;
export const selectEditInventoryState = (store: RootState) => store.inventorySlice.editInventory;
