import { TableRow, TableCell, Typography } from '@mui/material';

import { Strings } from '@app/constants';

// ----------------------------------------------------------------------

export default function TableNoData() {
  return (
    <TableRow>
      <TableCell colSpan={12}>
        <Typography align="center">{Strings.common.noRecord}</Typography>
      </TableCell>
    </TableRow>
  );
}
