import { useNavigate } from 'react-router-dom';

import { PaginatedTable } from '@app/components/paginated-table';
import { TableProps } from '@app/components/table';
import { ISku } from '../types';
import { SkuRow } from './sku-row';
import { PATH_DASHBOARD } from '@app/routes/paths';

const TABLE_HEAD = [
  { id: 'productImage', label: 'Image', align: 'left', sortable: false },
  { id: 'skuId', label: 'SKU Id', align: 'center', sortable: false },
  { id: 'ProductName', label: 'ProductName', align: 'center', sortable: false },
  { id: 'SkuName', label: 'SkuName', align: 'center', sortable: false },
  { id: 'Categories', label: 'Categories', align: 'center', sortable: false },
  { id: 'Parent Categories', label: 'Parent Categories', align: 'center', sortable: false },
  { id: '', label: 'Delete', align: 'left', sortable: false },
];

interface Props {
  tableProps: TableProps;
  rows?: ISku[];
  loading: boolean;
  bannerId: number;
}

const SkuList = ({ tableProps, rows, loading, bannerId }: Props) => {
  const navigation = useNavigate();
  const handleSelect = (data: any) => {
    const id = data.id;
    navigation(PATH_DASHBOARD.skuDetail, { state: { id } });
  };

  const filteredRows = rows ? rows.filter((row) => row !== null) : [];
  return (
    <PaginatedTable<ISku>
      head={TABLE_HEAD}
      tableProps={tableProps}
      data={filteredRows}
      loading={loading}
      isServerSidePaging={false}
    >
      {(row) => (
        <SkuRow
          row={row}
          bannerId={bannerId}
          selected={tableProps.selected.includes(row.id)}
          onSelectRow={() => {
            handleSelect(row);
          }}
        />
      )}
    </PaginatedTable>
  );
};
export default SkuList;
