import { Stack } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { selectUserDetailState } from '../selectors';
import { useRequestState, useDialog } from '@app/hooks';
import { PATH_DASHBOARD } from '@app/routes/paths';
import { Strings } from '@app/constants';
import { Header } from '@app/sections/header';
import { DetailCard } from '@app/components/detail-card';
import AddVendor from './add-vendor';
import AddAdmin from './add-admin';
import { getUserDetail } from '../slice';

const ViewUser = () => {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const editVendorDialog = useDialog();
  const editAdminDialog = useDialog();

  const { state } = useLocation();

  const { data } = useRequestState({ stateSelector: selectUserDetailState, errorShown: true });

  useEffect(() => {
    dispatch(getUserDetail({ id: state.id }));
  }, [dispatch, state.id]);

  const handleBack = () => {
    navigation(PATH_DASHBOARD.users);
  };

  return (
    <Stack>
      <Header
        backButton
        onClickBack={() => {
          handleBack();
        }}
        title={Strings.pageTitle.userDetails}
        helperText={''}
        actionButtonTitle={Strings.button.edit}
        onClickActionButton={data?.userType === 'VU' ? editVendorDialog.show : editAdminDialog.show}
      />
      <DetailCard detailPage={'users'} details={data} />

      <AddVendor open={editVendorDialog.visible} onClose={editVendorDialog.hide} row={data} />
      <AddAdmin open={editAdminDialog.visible} onClose={editAdminDialog.hide} row={data} />
    </Stack>
  );
};

export { ViewUser };
