import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Link, type BoxProps, Stack } from '@mui/material';

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}

const LogoSidebar = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sx, ...other }, ref) => {
    const logoSide = (
      <Box
        mt={2}
        py={1}
        px={2}
        gap={1}
        ref={ref}
        alignItems={'center'}
        justifyContent={'center'}
        component="div"
        display={'flex'}
        sx={{
          ...sx,
          width: '100%',
        }}
        {...other}
      >
        <Stack width={80}>
          <img src="/assets/logo/logoOnly.png" alt="logo" />
        </Stack>
      </Box>
    );

    if (disabledLink) {
      return logoSide;
    }

    return (
      <Link component={RouterLink} to="/" sx={{ display: 'contents' }}>
        {logoSide}
      </Link>
    );
  }
);

export { LogoSidebar };
