import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Stack } from '@mui/material';
import { useDispatch } from 'react-redux';

import { Header } from '@app/sections/header';
import { Strings } from '@app/constants';
import { useDialog, useRequestState } from '@app/hooks';
import { PATH_DASHBOARD } from '@app/routes/paths';
import AddProduct from '@app/modules/products/view/add-product';
import { DetailCard } from '@app/components/detail-card';
import { getProductDetail } from '@app/modules/products/slice';
import { selectProductDetailState } from '@app/modules/products/selectors';

const ProductDetailPage = () => {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const editDialog = useDialog();

  const { state } = useLocation();

  const { data } = useRequestState({ stateSelector: selectProductDetailState, errorShown: true });
  useEffect(() => {
    dispatch(getProductDetail({ id: state.id }));
  }, [dispatch, state.id]);

  const handleBack = () => {
    navigation(PATH_DASHBOARD.product);
  };

  return (
    <Stack sx={{ overflow: 'auto', height: '100%' }}>
      <Stack>
        <Header
          backButton
          title={Strings.pageTitle.productDetail}
          onClickBack={() => {
            handleBack();
          }}
          actionButtonTitle={Strings.button.edit}
          onClickActionButton={editDialog.show}
        />
        <DetailCard detailPage={'products'} details={data} />
      </Stack>
      <AddProduct open={editDialog.visible} onClose={editDialog.hide} row={data} />
    </Stack>
  );
};

export { ProductDetailPage };
