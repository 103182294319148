import { useNavigate } from 'react-router-dom';

import { PaginatedTable } from '@app/components/paginated-table';
import { TableProps } from '@app/components/table';
import { ISku } from '../types';
import { SkuRow } from './sku-row';
import { PATH_DASHBOARD } from '@app/routes/paths';

const TABLE_HEAD = [
  { id: 'id', label: 'Id', align: 'left', sortable: false },
  { id: 'productImage', label: 'Image', align: 'left', sortable: false },
  { id: 'skuId', label: 'SKU Id', align: 'center', sortable: false },
  { id: 'name', label: 'Name', align: 'center', sortable: false },
  { id: 'product', label: 'Product', align: 'center', sortable: false },
  { id: 'parentCategory', label: 'Parent Category', align: 'center', sortable: false },
  { id: 'category', label: 'Category', align: 'center', sortable: false },
  { id: 'status', label: 'Status', align: 'center', sortable: false },
];

interface Props {
  tableProps: TableProps;
  rows?: ISku[];
  loading: boolean;
  count?: number;
  page: number;
}

const SkuList = ({ tableProps, rows, loading, count, page }: Props) => {
  const navigation = useNavigate();
  const handleSelect = (data: any) => {
    const id = data.id;
    navigation(PATH_DASHBOARD.skuDetail, { state: { id, page } });
  };
  return (
    <>
      <PaginatedTable<ISku>
        head={TABLE_HEAD}
        tableProps={tableProps}
        data={rows}
        loading={loading}
        isServerSidePaging={true}
        totalCount={count}
      >
        {(row) => (
          <SkuRow
            row={row}
            selected={tableProps.selected.includes(row.id)}
            onSelectRow={() => {
              handleSelect(row);
            }}
          />
        )}
      </PaginatedTable>
    </>
  );
};
export default SkuList;
