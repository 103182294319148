import { Box, type BoxProps } from '@mui/material';

import { NAV } from '@app/configs';

// ----------------------------------------------------------------------

export default function Main({ children, sx, ...other }: BoxProps) {
  return (
    <Box
      component="main"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        width: `calc(100% - ${NAV.W_DASHBOARD}px)`,
        maxHeight: '100vh',
        overflow: 'auto',
        ...sx,
      }}
      {...other}
    >
      {children}
    </Box>
  );
}
