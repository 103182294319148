import { Stack } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { DetailCard } from '@app/components/detail-card';
import { Header } from '@app/sections/header';
import { Strings } from '@app/constants';
import { useDialog, useRequestState } from '@app/hooks';
import { PATH_DASHBOARD } from '@app/routes/paths';
import { selectInventoryDetailState } from '@app/modules/inventory-banner-management/selectors';
import { getInventoryDetail } from '@app/modules/inventory-banner-management/slice';
import { InventoryEditListPage } from './inventory-edit-list-page';
import EditInventoryData from '@app/modules/inventory-banner-management/view/edit-inventory-data';

const InventoryDetailPage = () => {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const editDialog = useDialog();
  const { state } = useLocation();

  const { data } = useRequestState({ stateSelector: selectInventoryDetailState, errorShown: true });

  useEffect(() => {
    dispatch(getInventoryDetail({ id: state.id }));
  }, [dispatch, state.id]);

  const handleBack = () => {
    navigation(PATH_DASHBOARD.inventoryBannerManagement);
  };

  return (
    <>
      <Stack>
        <Header
          backButton
          title={Strings.pageTitle.inventoryDetail}
          onClickBack={() => {
            handleBack();
          }}
          actionButtonTitle={Strings.button.edit}
          onClickActionButton={editDialog.show}
        />
        <DetailCard detailPage={'inventory'} details={data} />
        <InventoryEditListPage id={state.id} />
      </Stack>
      <EditInventoryData open={editDialog.visible} onClose={editDialog.hide} row={data} />
    </>
  );
};

export { InventoryDetailPage };
