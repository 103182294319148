import { Checkbox, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useState } from 'react';

import { IMAGE_BASE_URL } from '@app/configs';
import { TableCellCustom } from '@app/components/table';
import { CustomAvatar } from '@app/components/custom-avatar';
import { ICategory } from '../types';
import { StatusTag } from '@app/components';
import { TruncatedTypography } from '@app/components/typography';
import { useRequestState } from '@app/hooks';
import { updateCategoryStatus } from '../slice';
import { selectUpdateCategoryStatusState } from '../selectors';

interface Props {
  row: ICategory;
  selected: boolean;
  onSelectRow: VoidFunction;
}

const CategoryRow = (props: Props) => {
  const { row, onSelectRow } = props;
  const dispatch = useDispatch();
  const [isChecked, setIsChecked] = useState(row.active);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    dispatch(updateCategoryStatus({ id: row.id }));
  };

  useRequestState({
    stateSelector: selectUpdateCategoryStatusState,
    successMessageShown: true,
  });

  return (
    <>
      <TableCellCustom onClick={onSelectRow} align="left">
        <TruncatedTypography text={row.id ?? 'NA'} height={20} lines={1} />
      </TableCellCustom>
      <TableCellCustom onClick={onSelectRow} align="center">
        <CustomAvatar name={row.name} color="primary" alt="pic" src={IMAGE_BASE_URL + row.image} />
      </TableCellCustom>
      <TableCellCustom onClick={onSelectRow} align="center">
        <TruncatedTypography text={row.name ?? 'NA'} height={20} lines={1} />
      </TableCellCustom>
      <TableCellCustom onClick={onSelectRow} align="center">
        <TruncatedTypography text={row.parentCategoryName ?? 'NA'} height={20} lines={1} />
      </TableCellCustom>
      <TableCellCustom onClick={onSelectRow} align="center">
        <Typography variant="body2">{row.type ?? 'NA'}</Typography>
      </TableCellCustom>
      <TableCellCustom
        onClick={onSelectRow}
        align="center"
        sx={{ color: row.topCategory ? 'green' : 'red' }}
      >
        <StatusTag name={row.topCategory ? 'Active' : 'InActive'} active={row.topCategory} />
      </TableCellCustom>
      <TableCellCustom align="center">
        <Checkbox key={row.id} checked={isChecked} onChange={handleCheckboxChange} />
      </TableCellCustom>
    </>
  );
};

export { CategoryRow };
