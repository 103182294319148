import { IconButton } from '@mui/material';

import { DeleteDialog } from '@app/components/dialog';
import {
  Iconify,
  MenuPopover,
  RowActions as MenuRowActions,
  useMenuPopover,
} from '@app/components';
import { Strings } from '@app/constants';
import { useDialog } from '@app/hooks';
import { IService } from '../types';
import AddService from './add-service';

interface Props {
  row: IService;
}

const RowActions = ({ row }: Props) => {
  const menuPopover = useMenuPopover();
  const deleteDialog = useDialog();
  const editDialog = useDialog();

  const actions = [
    {
      label: Strings.rowActions.edit,
      callback: editDialog.show,
    },
    {
      label: Strings.rowActions.delete,
      callback: deleteDialog.show,
    },
  ];

  return (
    <>
      <IconButton onClick={menuPopover.show}>
        <Iconify icon="eva:more-vertical-fill" />
      </IconButton>

      <MenuPopover
        open={menuPopover.rowMenuAnchorElement}
        arrow="right-top"
        sx={{ width: 180 }}
        onClose={menuPopover.hide}
      >
        <MenuRowActions actions={actions} onClickAction={menuPopover.hide} />
      </MenuPopover>

      <DeleteDialog
        title={`${Strings.rowActions.delete} ${Strings.header.service}?`}
        caption={Strings.common.deleteCaption(Strings.header.service)}
        deleteButtonTitle={Strings.button.confirm}
        loading={false}
        onClose={deleteDialog.hide}
        visible={deleteDialog.visible}
        onClickDelete={() => {
          ('');
        }}
      />

      <AddService open={editDialog.visible} onClose={editDialog.hide} row={row} />
    </>
  );
};

export { RowActions };
