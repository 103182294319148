import { Divider, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Header } from '@app/sections/header';
import { Strings } from '@app/constants';
import { useTable } from '@app/components/table';
import { useDialog, useRequestState } from '@app/hooks';
import AddSlotManagement from './add-slot-management';
import SlotManagementList from './slot-management-list';
import { PATH_DASHBOARD } from '@app/routes/paths';
import { selectAllSlotState } from '@app/modules/slot-management-detail/selectors';
import { getAllSlot } from '@app/modules/slot-management-detail';
import { usePageCount } from '@app/hooks/use-page-count';
const SlotManagementPage = () => {
  const tableProps = useTable();
  const addSlotDialog = useDialog();
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const page = tableProps.page;
  const { count } = usePageCount(getAllSlot);

  const [showFilter, setShowFilter] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState('false');
  const [selectedSlotType, setSelectedSlotType] = useState('');

  const [query, setQuery] = useState('');
  useEffect(() => {
    if (query.length !== 0) {
      dispatch(getAllSlot({ page, q: query }));
    } else {
      dispatch(getAllSlot({ page }));
    }
  }, [dispatch, page, query]);
  const handleFilter = () => {
    setShowFilter(!showFilter);
  };
  useEffect(() => {
    dispatch(getAllSlot({ page }));
  }, [dispatch, page]);

  const { data, loading } = useRequestState({
    stateSelector: selectAllSlotState,
    errorShown: true,
  });

  const handleStatusChange = (event: any) => {
    setSelectedStatus(event.target.value);
  };
  const handleSlotTypeChange = (event: any) => {
    setSelectedSlotType(event.target.value);
  };

  const handleSearchChange = (event: any) => {
    setQuery(event.target.value);
  };

  const handleSelect = () => {
    navigation(PATH_DASHBOARD.slotManagementDetail);
  };
  return (
    <>
      <Header
        filter
        onClickFilter={() => {
          handleFilter();
        }}
        title={Strings.pageTitle.slotManagement}
        helperText={''}
        actionButtonTitle={Strings.button.addSlot}
        onClickActionButton={() => {
          handleSelect();
        }}
        searchBarProps={{
          placeholder: Strings.common.search,
          onChange: handleSearchChange,
        }}
      />
      {showFilter && (
        <Stack sx={{ p: 2 }}>
          <Stack direction={'row'} alignItems={'center'} gap={1} pb={3}>
            <Typography variant="h5">Filter</Typography>
            <Stack height={19} width={19}>
              <img src={`/assets/icons/navbar/filter.svg`} alt="back" />
            </Stack>
          </Stack>
          <Divider />
          <Stack direction={'row'} pt={3} pb={1} width={'60%'} gap={3}>
            <TextField
              fullWidth
              name="slotType"
              label={Strings.field.slotType}
              variant="outlined"
              select
              onChange={handleSlotTypeChange}
            >
              <MenuItem key={'normal'} value={'normal'}>
                Normal
              </MenuItem>
              <MenuItem key={'express'} value={'express'}>
                Express
              </MenuItem>
            </TextField>
            <TextField
              fullWidth
              name="status"
              label={Strings.field.status}
              variant="outlined"
              onChange={handleStatusChange}
              select
            >
              <MenuItem key={'active'} value={'active'}>
                Active
              </MenuItem>
              <MenuItem key={'inActive'} value={'inActive'}>
                InActive
              </MenuItem>
            </TextField>
          </Stack>
        </Stack>
      )}

      <SlotManagementList
        loading={loading}
        tableProps={tableProps}
        rows={data}
        selectedStatus={selectedStatus}
        selectedSlotType={selectedSlotType}
        count={count}
      />

      {addSlotDialog.visible && (
        <AddSlotManagement open={addSlotDialog.visible} onClose={addSlotDialog.hide} />
      )}
    </>
  );
};

export { SlotManagementPage };
