import { IconButton, useTheme } from '@mui/material';

import { TableCellCustom } from '@app/components/table';
import { IInventory } from '../types';
import { Iconify } from '@app/components';
import { TruncatedTypography } from '@app/components/typography';
import EditInventoryStock from './edit-inventory-stock';
import { useDialog } from '@app/hooks';

interface Props {
  row: IInventory;
  selected: boolean;
  onSelectRow: VoidFunction;
}

const InventoryRow = (props: Props) => {
  const { row, onSelectRow } = props;
  const EditDialog = useDialog();
  const theme = useTheme();

  return (
    <>
      <TableCellCustom flex onClick={onSelectRow}>
        <TruncatedTypography text={row.id} />
      </TableCellCustom>
      <TableCellCustom flex onClick={onSelectRow}>
        <TruncatedTypography text={row.vendor.firstName} />
      </TableCellCustom>
      <TableCellCustom onClick={onSelectRow} align="center">
        <TruncatedTypography text={row.sku.name} />
      </TableCellCustom>
      <TableCellCustom onClick={onSelectRow} align="center">
        <TruncatedTypography text={row.sku.skuId} />
      </TableCellCustom>
      <TableCellCustom flex onClick={onSelectRow} align="center">
        <TruncatedTypography text={row.price} />
      </TableCellCustom>
      <TableCellCustom flex onClick={onSelectRow} align="center">
        <TruncatedTypography text={row.ourPrice} />
      </TableCellCustom>
      <TableCellCustom flex onClick={onSelectRow} align="center">
        <TruncatedTypography text={row.discountPrice} />
      </TableCellCustom>
      <TableCellCustom flex onClick={onSelectRow} align="center">
        <TruncatedTypography text={row.ordered} />
      </TableCellCustom>
      <TableCellCustom flex onClick={onSelectRow} align="center">
        <TruncatedTypography text={row.stock} />
      </TableCellCustom>
      <TableCellCustom flex align="center">
        <IconButton sx={{ p: 0.25, borderRadius: '5px' }} onClick={EditDialog.show}>
          <Iconify icon="uiw:edit" width={18} color={theme.palette.primary.main} />
        </IconButton>
      </TableCellCustom>
      {EditDialog.visible && <EditInventoryStock open onClose={EditDialog.hide} row={row} />}
    </>
  );
};

export { InventoryRow };
