export const Services = [
  {
    id: 1,
    name: 'Washing',
    sku: 'Dog01',
    price: '10',
    category: 'Washing',
    active: true,
    day: [
      { name: 'S', status: true },
      { name: 'M', status: true },
      { name: 'T', status: true },
      { name: 'W', status: false },
      { name: 'T', status: false },
      { name: 'F', status: true },
      { name: 's', status: false },
    ],
  },
  {
    id: 2,
    name: 'Hair Cut',
    sku: 'Cat02',
    price: '20',
    category: 'Grooming',
    active: false,
    day: [
      { name: 'S', status: true },
      { name: 'M', status: false },
      { name: 'T', status: true },
      { name: 'W', status: true },
      { name: 'T', status: false },
      { name: 'F', status: true },
      { name: 's', status: false },
    ],
  },
  {
    id: 3,
    name: 'Injection',
    sku: 'Pet03',
    price: '30',
    category: 'Washing',
    active: true,
    day: [
      { name: 'S', status: true },
      { name: 'M', status: false },
      { name: 'T', status: true },
      { name: 'W', status: false },
      { name: 'T', status: false },
      { name: 'F', status: true },
      { name: 's', status: true },
    ],
  },
];
