/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { request } from '@app/services';
import {
  addCategory,
  addCategoryFailed,
  addCategorySuccess,
  addParentCategory,
  addParentCategoryFailed,
  addParentCategorySuccess,
  getCategory,
  getCategoryDetail,
  getCategoryDetailFailed,
  getCategoryDetailSuccess,
  getCategoryFailed,
  getCategorySuccess,
  getParentCategory,
  getParentCategoryFailed,
  getParentCategorySuccess,
  updateTopCategory,
  updateTopCategoryFailed,
  updateTopCategorySuccess,
  updateCategoryStatus,
  updateCategoryStatusFailed,
  updateCategoryStatusSuccess,
} from './slice';
import * as endpoints from './endpoints';
import { setPageCount } from '../common';
import { RootState } from '@app/redux/root-reducer';

// ----------------------------------------
function* getCategorySagas(action: ReturnType<typeof getCategory>): any {
  const { page, q } = action.payload;
  try {
    const result = yield call(request.get, endpoints.getCategory, {
      params: {
        page: q ? 1 : page,
        limit: 100,
        q,
        type: 'product',
      },
    });
    yield put(setPageCount({ id: action.type, count: result.data.total }));
    yield put(getCategorySuccess(result.data.category));
  } catch (err: any) {
    yield put(getCategoryFailed(err));
  }
}

// ----------------------------------------
function* getParentCategorySagas(action: ReturnType<typeof getParentCategory>): any {
  const { page, q } = action.payload;
  try {
    const result = yield call(request.get, endpoints.getParentCategory, {
      params: {
        page: q ? 1 : page,
        limit: 100,
        type: 'product',
        q,
      },
    });
    yield put(getParentCategorySuccess(result.data.data));
  } catch (err: any) {
    yield put(getParentCategoryFailed(err));
  }
}
// ----------------------------------------
function* getCategoryDetailSaga(action: ReturnType<typeof getCategoryDetail>): any {
  const { id } = action.payload;
  try {
    const result = yield call(request.get, endpoints.getCategoryDetail + id);
    yield put(getCategoryDetailSuccess(result.data.category));
  } catch (err: any) {
    yield put(getCategoryDetailFailed(err));
  }
}
// ----------------------------------------

function* addCategorySagas(action: ReturnType<typeof addCategory>): any {
  try {
    const { name, parentCategory, parentCategoryName, image, type, id } = action.payload;

    const { data } = yield select((store: RootState) => store.categoryReducer.categoryDetail);

    const formData = new FormData();

    formData.append('name', name);
    formData.append('type', type);

    if (typeof image === 'object') {
      formData.append('image', image);
    }

    if (parentCategory) {
      formData.append('parentCategoryId', parentCategory);
    }

    let result;
    if (id) {
      result = yield call(request.put, endpoints.updateCategory + id, formData);
    } else {
      result = yield call(request.post, endpoints.addCategory, formData);
    }
    yield put(addCategorySuccess(result.data));
    if (id) {
      yield put(
        getCategoryDetailSuccess({
          ...data,
          ...result?.data?.category,
          parentCategory: { ...data.parentCategory, name: parentCategoryName },
        })
      );
    }
  } catch (err: any) {
    yield put(addCategoryFailed(err));
  }
}

// ----------------------------------------
function* addParentCategorySagas(action: ReturnType<typeof addParentCategory>): any {
  try {
    const { name, image, type, id } = action.payload;

    const formData = new FormData();

    formData.append('name', name);
    formData.append('type', type);

    if (typeof image === 'object') {
      formData.append('image', image);
    }

    let result;
    if (id) {
      result = yield call(request.put, endpoints.updateCategory + id, formData);
    } else {
      result = yield call(request.post, endpoints.addParentCategory, formData);
    }
    yield put(addParentCategorySuccess(result.data));
  } catch (err: any) {
    yield put(addParentCategoryFailed(err));
  }
}
// ----------------------------------------
function* updateTopCategorySagas(action: ReturnType<typeof updateTopCategory>): any {
  const { id } = action.payload;
  try {
    const { data } = yield select((store: RootState) => store.categoryReducer.categoryDetail);
    const result = yield call(request.patch, endpoints.updateTopCategory + id);
    yield put(updateTopCategorySuccess(result.data.message));
    yield put(
      getCategoryDetailSuccess({
        ...data,
        topCategory: !data.topCategory,
      })
    );
  } catch (err: any) {
    yield put(updateTopCategoryFailed(err));
  }
}
// ----------------------------------------
function* updateCategoryStatusSagas(action: ReturnType<typeof updateCategoryStatus>): any {
  const { id } = action.payload;
  try {
    const { data } = yield select((store: RootState) => store.categoryReducer.categoryDetail);
    const result = yield call(request.patch, endpoints.updateCategoryStatus + id);
    yield put(updateCategoryStatusSuccess(result.data.message));
    yield put(
      getCategoryDetailSuccess({
        ...data,
        active: !data.active,
      })
    );
  } catch (err: any) {
    yield put(updateCategoryStatusFailed(err));
  }
}
// ----------------------------------------

function* categorySagas() {
  yield all([
    takeLatest([getCategory, addCategorySuccess, addParentCategorySuccess], getCategorySagas),
    takeLatest([getParentCategory, addParentCategorySuccess], getParentCategorySagas),
    takeLatest([addCategory], addCategorySagas),
    takeLatest([addParentCategory], addParentCategorySagas),
    takeLatest([updateTopCategory], updateTopCategorySagas),
    takeLatest([updateCategoryStatus], updateCategoryStatusSagas),
    takeLatest([getCategoryDetail], getCategoryDetailSaga),
  ]);
}

export { categorySagas };
