import React, { useState } from 'react';
import { FormControlLabel, Checkbox } from '@mui/material';

const CheckboxLabel: React.FC<{
  label: string;
  active: boolean;
  handleCheck?: (label: string, value: boolean) => void;
}> = ({ label, active, ...props }) => {
  const [isChecked, setIsChecked] = useState(active);

  const onClick = () => {
    setIsChecked(!isChecked);
    if (props?.handleCheck) {
      props.handleCheck(label, active);
    }
  };

  return (
    <FormControlLabel control={<Checkbox checked={isChecked} onChange={onClick} />} label={label} />
  );
};

export default CheckboxLabel;
