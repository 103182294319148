import { Stack } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Header } from '@app/sections/header';
import { Strings } from '@app/constants';
import { useDialog, useRequestState } from '@app/hooks';
import { PATH_DASHBOARD } from '@app/routes/paths';
import AddCategory from '@app/modules/categories/view/add-category-form';
import { DetailCard } from '@app/components/detail-card';
import { getCategoryDetail } from '@app/modules/categories/slice';
import { selectCategoryDetailState } from '@app/modules/categories/selectors';

const CategoryDetailPage = () => {
  const dispatch = useDispatch();
  const editDialog = useDialog();
  const navigation = useNavigate();

  const { state } = useLocation();

  const { data } = useRequestState({ stateSelector: selectCategoryDetailState, errorShown: true });

  useEffect(() => {
    dispatch(getCategoryDetail({ id: state.id }));
  }, [dispatch, state.id]);

  const handleBack = () => {
    navigation(PATH_DASHBOARD.categories);
  };

  return (
    <Stack sx={{ overflow: 'auto', height: '100%' }}>
      <Stack>
        <Header
          backButton
          title={Strings.pageTitle.categoryDetail}
          onClickBack={() => {
            handleBack();
          }}
          actionButtonTitle={Strings.button.edit}
          onClickActionButton={editDialog.show}
        />
        <DetailCard details={data} detailPage={'category'} />
      </Stack>
      <AddCategory open={editDialog.visible} onClose={editDialog.hide} row={data} />
    </Stack>
  );
};

export { CategoryDetailPage };
