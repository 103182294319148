import { useNavigate } from 'react-router';

import { PaginatedTable } from '@app/components/paginated-table';
import { TableProps } from '@app/components/table';
import { ICategory } from '../types';
import { CategoryRow } from './category-row';
import { PATH_DASHBOARD } from '@app/routes/paths';

const TABLE_HEAD = [
  { id: 'id', label: 'id', align: 'left', sortable: false },
  { id: 'image', label: 'Image', align: 'left', sortable: false },
  { id: 'category', label: 'Category', align: 'center', sortable: false },
  { id: 'parentCategory', label: 'Parent Category', align: 'center', sortable: false },
  { id: 'type', label: 'Type', align: 'center', sortable: false },
  { id: '', label: 'Top Category', align: 'center', sortable: false },
  { id: 'status', label: 'Status', align: 'center', sortable: false },
];

interface Props {
  tableProps: TableProps;
  rows?: ICategory[];
  loading: boolean;
  count?: number;
  page: number;
}

const CategoryList = ({ tableProps, rows, loading, count, page }: Props) => {
  const navigation = useNavigate();
  const handleSelect = (data: any) => {
    const id = data.id;
    navigation(PATH_DASHBOARD.categoryDetail, { state: { id, page } });
  };

  return (
    <PaginatedTable<ICategory>
      head={TABLE_HEAD}
      tableProps={tableProps}
      data={rows}
      loading={loading}
      isServerSidePaging={true}
      totalCount={count}
    >
      {(row) => (
        <CategoryRow
          row={row}
          selected={tableProps.selected.includes(row.id)}
          onSelectRow={() => {
            handleSelect(row);
          }}
        />
      )}
    </PaginatedTable>
  );
};
export default CategoryList;
