import { all, call, put, takeLatest } from 'redux-saga/effects';

import { request } from '@app/services';
import {
  addBanner,
  addBannerFailed,
  addBannerSuccess,
  getBanner,
  getBannerFailed,
  getBannerSuccess,
  getUpdateBannerStatusFailed,
  getUpdateBannerStatusSuccess,
  updateBannerStatus,
} from './slice';
import * as endpoints from './endpoints';
import { setPageCount } from '../common';

// ----------------------------------------
function* getBannerSaga(action: ReturnType<typeof getBanner>): any {
  const { page, q } = action.payload;
  try {
    const result = yield call(request.get, endpoints.getBanner, {
      params: {
        limit: 100,
        page: q ? 1 : page,
        q,
      },
    });
    yield put(getBannerSuccess(result?.data?.banner));
    yield put(setPageCount({ id: action.type, count: result.data.total }));
  } catch (err: any) {
    yield put(getBannerFailed(err));
  }
}

// ----------------------------------------
function* updateBannerStatusSaga(action: ReturnType<typeof updateBannerStatus>): any {
  try {
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    const result = yield call(request.patch, endpoints.updateBanner + action.payload);
    yield put(getUpdateBannerStatusSuccess(result.data.message));
  } catch (err: any) {
    yield put(getUpdateBannerStatusFailed(err));
  }
}

// ----------------------------------------
function* addBannerSaga(action: ReturnType<typeof addBanner>): any {
  try {
    const { id, title, description, bannerType, image, buttonTitle, isActive } = action.payload;

    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('bannerType', bannerType);
    if (typeof image === 'object') {
      formData.append('image', image);
    }
    formData.append('buttonTitle', buttonTitle);
    formData.append('isActive', isActive === 'active' ? 'true' : 'false');

    let result;
    if (id) {
      result = yield call(request.put, endpoints.addBanner, formData);
    } else {
      result = yield call(request.post, endpoints.addBanner, formData);
    }

    yield put(addBannerSuccess(result.data));
  } catch (err: any) {
    yield put(addBannerFailed(err));
  }
}
// ----------------------------------------

function* bannerSagas() {
  yield all([
    takeLatest([getBanner, getUpdateBannerStatusSuccess, addBannerSuccess], getBannerSaga),
  ]);
  yield all([takeLatest(updateBannerStatus, updateBannerStatusSaga)]);
  yield all([takeLatest([addBanner], addBannerSaga)]);
}

export { bannerSagas };
