import { Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';

import { CustomAvatar } from '@app/components/custom-avatar';
import { TableCellCustom } from '@app/components/table';
import { ICustomer } from '../types';

interface Props {
  row: ICustomer;
  selected: boolean;
  onSelectRow: VoidFunction;
}

const CustomerRow = (props: Props) => {
  const { row, onSelectRow } = props;
  const fullAddress =
    `${String(row?.addresses[0]?.city)},${String(row?.addresses[0]?.country)}` ?? 'NA';

  return (
    <>
      <TableCellCustom flex onClick={onSelectRow} align="left">
        <Typography variant="body2">{row.id ?? 'NA'}</Typography>
      </TableCellCustom>
      <TableCellCustom onClick={onSelectRow}>
        <Stack style={{ flexDirection: 'row', alignItems: 'center', gap: '9px' }}>
          <CustomAvatar
            src={row.profileImage}
            name={`${row?.firstName} ${row?.lastName}`}
            color="primary"
          />
          <Typography variant="body2">
            {row?.firstName} {row?.lastName}
          </Typography>
        </Stack>
      </TableCellCustom>
      <TableCellCustom flex onClick={onSelectRow} align="center">
        <Typography variant="body2">{row?.phone ?? 'NA'}</Typography>
      </TableCellCustom>
      <TableCellCustom flex onClick={onSelectRow} align="center">
        {row?.createdAt ? dayjs(row?.createdAt).format('YYYY-MM-DD') : 'NA'}
      </TableCellCustom>
      <TableCellCustom flex onClick={onSelectRow} align="center">
        <Typography variant="body2">
          <Typography variant="body2">{row.addresses.length > 0 ? fullAddress : 'NA'}</Typography>
        </Typography>
      </TableCellCustom>
    </>
  );
};

export { CustomerRow };
