import { PATH_DASHBOARD } from '@app/routes/paths';

// App Configs
export const PATH_AFTER_LOGIN = PATH_DASHBOARD.app;
// ----------------------------------------------------------------------

// Api Configs
export const BASE_URL = process.env.REACT_APP_BASE_URL ?? '';
export const IMAGE_BASE_URL = '';
export const API_TIMEOUT = process.env.REACT_APP_API_TIMEOUT ?? 0;
export const API_PREFIX = '/api/v1';
// ----------------------------------------------------------------------

export const COMPANY_NAME = 'PetVesta';

// ----------------------------------------------------------------------

export const NAV = {
  W_DASHBOARD: 280,
  //
  H_DASHBOARD_ITEM: 48,
  H_DASHBOARD_ITEM_SUB: 36,
};

// ----------------------------------------------------------------------
export const VAT_TAX = 5;

// ----------------------------------------------------------------------
export const ACCOUNT_DETAILS = {
  ACC_HOLDER: 'BETTER POINT PAVILION PORTAL LLC',
  BANK_NAME: 'WIO BANK PJSC',
  ACC_NO: ' A/C# 9453321350',
  I_BAN: 'IBAN: AE84 0860 0000 0945 3321 350',
};
