import { Stack } from '@mui/material';

import { Label } from '../label';

interface Props {
  selectedDays: string;
}

const DayTag = ({ selectedDays }: Props) => {
  const daysList = 'mon,tue,wed,thu,fri,sat,sun';

  return (
    <Stack m={1} flexDirection="row">
      {daysList.split(',').map((day, index) => (
        <Stack key={index} sx={{ marginLeft: '5px' }}>
          <Label color={(selectedDays.includes(day) && 'success') || 'default'}>
            {day.charAt(0)}
          </Label>
        </Stack>
      ))}
    </Stack>
  );
};

export { DayTag };
