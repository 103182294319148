import { styled } from '@mui/material';

export const StyledContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: '45%',
  margin: 'auto',
  [theme.breakpoints.down('sm')]: {
    width: '90%',
  },
}));

export const StyledCopyright = styled('div')(() => ({
  display: 'flex',
  position: 'absolute',
  left: 0,
  right: 0,
  textAlign: 'center',
  bottom: 10,
}));
