import { useNavigate } from 'react-router-dom';

import { PaginatedTable } from '@app/components/paginated-table';
import { TableProps } from '@app/components/table';
import { IProduct } from '../types';
import { ProductRow } from './product-row';
import { PATH_DASHBOARD } from '@app/routes/paths';

const TABLE_HEAD = [
  { id: 'id', label: 'Id', align: 'left', sortable: false },
  { id: 'name', label: 'Name', align: 'left', sortable: false },
  { id: 'categoryId', label: 'Category', align: 'center', sortable: false },
  { id: 'categoryName', label: 'CategoryName', align: 'center', sortable: false },
  { id: 'description', label: 'Description', align: 'center', sortable: false },
  { id: 'active', label: 'Active', align: 'center', sortable: false },
];

interface Props {
  tableProps: TableProps;
  rows?: IProduct[];
  loading: boolean;
  count?: number;
}

const ProductList = ({ tableProps, rows, loading, count }: Props) => {
  const navigation = useNavigate();

  const handleSelect = (data: any) => {
    const id = data.id;
    navigation(PATH_DASHBOARD.productDetail, { state: { id } });
  };
  return (
    <PaginatedTable<IProduct>
      head={TABLE_HEAD}
      tableProps={tableProps}
      data={rows}
      loading={loading}
      isServerSidePaging={true}
      totalCount={count}
    >
      {(row) => (
        <ProductRow
          row={row}
          selected={tableProps.selected.includes(row.id)}
          onSelectRow={() => {
            handleSelect(row);
          }}
        />
      )}
    </PaginatedTable>
  );
};
export default ProductList;
