import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Collapse, Divider, Stack } from '@mui/material';

import useActiveLink from '@app/hooks/useActiveLink';
import { type NavListProps } from '../types';
import { StyledSubSection } from './styles';
import NavItem from './NavItem';

// ----------------------------------------------------------------------

interface NavListRootProps {
  data: NavListProps;
  depth: number;
  hasChild: boolean;
}

export default function NavList({ data, depth, hasChild }: NavListRootProps) {
  const { pathname } = useLocation();

  const { active, isExternalLink } = useActiveLink(data.path);

  const [open, setOpen] = useState(active);

  useEffect(() => {
    if (!active) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (data.divider) {
    return <Divider sx={{ mt: 'auto', mb: 3, mx: 1 }} />;
  }

  return (
    <Stack>
      <NavItem
        item={data}
        depth={depth}
        open={open}
        active={data.children ? false : active}
        isExternalLink={isExternalLink}
        onClick={handleToggle}
      />

      {hasChild && (
        <Collapse in={open} unmountOnExit>
          <NavSubList data={data.children ?? []} depth={depth} />
        </Collapse>
      )}
    </Stack>
  );
}

// ----------------------------------------------------------------------

interface NavListSubProps {
  data: NavListProps[];
  depth: number;
}

function NavSubList({ data, depth }: NavListSubProps) {
  return (
    <StyledSubSection depth={depth}>
      {data.map((list, index) => (
        <NavList key={index} data={list} depth={depth} hasChild={!!list.children} />
      ))}
    </StyledSubSection>
  );
}
