import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { RequestStatus } from '@app/constants';
import { IRequestFailed, IRequestStatus } from '@app/types';
import { ICollectionDetail, ICollectionDetailState } from './types';

const initialState: ICollectionDetailState = {
  collectionDetail: {},
  deleteCollectionDetail: {},
  getNewCollectionDetail: {},
  addNewCollection: {},
};

const slice = createSlice({
  name: 'collectionDetail',
  initialState,
  reducers: {
    getCollectionDetail(state, action: PayloadAction<number>) {
      state.collectionDetail.status = RequestStatus.RUNNING;
    },
    getCollectionDetailSuccess(
      state,
      action: PayloadAction<ICollectionDetailState['collectionDetail']['data']>
    ) {
      state.collectionDetail.status = RequestStatus.SUCCESS;
      state.collectionDetail.data = action.payload;
    },
    getCollectionDetailFailed(state, action: PayloadAction<IRequestFailed>) {
      state.collectionDetail.status = RequestStatus.ERROR;
      state.collectionDetail.error = action.payload;
    },

    //
    getNewCollectionDetail(state, action: PayloadAction<{ page: number; q?: any }>) {
      state.getNewCollectionDetail.status = RequestStatus.RUNNING;
    },
    getNewCollectionDetailSuccess(state, action: PayloadAction<ICollectionDetail>) {
      state.getNewCollectionDetail.status = RequestStatus.SUCCESS;
      state.getNewCollectionDetail.data = action.payload;
    },
    getNewCollectionDetailFailed(state, action: PayloadAction<IRequestFailed>) {
      state.getNewCollectionDetail.status = RequestStatus.ERROR;
      state.getNewCollectionDetail.error = action.payload;
    },
    //
    addNewCollectionDetailProduct(state, action: PayloadAction<{ id: number; skuId: number[] }>) {
      state.addNewCollection.status = RequestStatus.RUNNING;
    },
    addNewCollectionDetailProductSuccess(state, action: PayloadAction<IRequestStatus>) {
      state.addNewCollection.status = RequestStatus.SUCCESS;
      state.addNewCollection.data = action.payload;
    },
    addNewCollectionDetailProductFailed(state, action: PayloadAction<IRequestFailed>) {
      state.addNewCollection.status = RequestStatus.ERROR;
      state.addNewCollection.error = action.payload;
      state.addNewCollection.data = action.payload;
    },
    //
    deleteCollectionDetail(state, action: PayloadAction<{ id: number; skuId: number }>) {
      state.deleteCollectionDetail.status = RequestStatus.RUNNING;
    },
    deleteCollectionDetailSuccess(state, action: PayloadAction<IRequestStatus>) {
      state.deleteCollectionDetail.status = RequestStatus.SUCCESS;
      state.deleteCollectionDetail.data = action.payload;
    },
    deleteCollectionDetailFailed(state, action: PayloadAction<IRequestFailed>) {
      state.deleteCollectionDetail.status = RequestStatus.ERROR;
      state.deleteCollectionDetail.error = action.payload;
    },
  },
});
export default slice.reducer;

export const {
  getCollectionDetail,
  getCollectionDetailSuccess,
  getCollectionDetailFailed,
  deleteCollectionDetail,
  deleteCollectionDetailSuccess,
  deleteCollectionDetailFailed,
  getNewCollectionDetail,
  getNewCollectionDetailSuccess,
  getNewCollectionDetailFailed,
  addNewCollectionDetailProduct,
  addNewCollectionDetailProductSuccess,
  addNewCollectionDetailProductFailed,
} = slice.actions;
