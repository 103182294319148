import { all, call, put, takeLatest } from 'redux-saga/effects';

import { request } from '@app/services';
import * as endpoints from './enpoints';
import {
  getInventoryTransaction,
  getInventoryTransactionFailed,
  getInventoryTransactionSuccess,
} from './slice';

function* getInventoryTransactionSaga(action: ReturnType<typeof getInventoryTransaction>): any {
  const { id, q } = action.payload;
  try {
    const result = yield call(
      request.get,
      `${endpoints.getInventoryTransaction}${id}/transaction`,
      {
        params: {
          q,
        },
      }
    );
    yield put(getInventoryTransactionSuccess(result.data));
  } catch (err: any) {
    yield put(getInventoryTransactionFailed(err));
  }
}
function* inventoryDetailSagas() {
  yield all([takeLatest([getInventoryTransaction], getInventoryTransactionSaga)]);
}
export { inventoryDetailSagas };
