import {
  Divider,
  Stack,
  DialogContent,
  DialogActions,
  Dialog,
  Button,
  MenuItem,
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { LoadingButton } from '@mui/lab';

import { Strings } from '@app/constants';
import { Header } from '@app/sections/header';
import { useRequestState } from '@app/hooks';
import { FormProvider, RHFTextField } from '@app/components';
import { IEditInventoryStock, IInventory } from '../types';
import { editInventoryStockSchema } from '../validators';
import { editInventoryStock } from '../slice';
import { selectEditInventoryStockState } from '../selectors';

interface Props {
  open: boolean;
  onClose: VoidFunction;
  row?: IInventory;
}

const EditInventoryStock = ({ open, onClose, row }: Props) => {
  const dispatch = useDispatch();

  const methods = useForm<IEditInventoryStock>({
    resolver: yupResolver(editInventoryStockSchema) as any,
  });

  const onSubmit = (form: IEditInventoryStock) => {
    const id = row?.id ?? 0;

    dispatch(
      editInventoryStock({
        ...form,
        id,
        operation: form.operation,
        reason: operation === 'add' ? form.reasonAdd : form.reasonRemove,
      })
    );
  };

  const { loading: saveInventory } = useRequestState({
    stateSelector: selectEditInventoryStockState,
    onSuccess: onClose,
    successMessageShown: true,
    errorShown: true,
  });

  const operation = useWatch({ control: methods.control, name: 'operation' });

  return (
    <>
      <FormProvider methods={methods}>
        <Dialog open={open} fullWidth>
          <Header variant="dialog" title={Strings.pageTitle.editStock} onClose={onClose} />
          <Divider />
          <DialogContent>
            <Stack spacing={2} my={2}>
              <Stack flexDirection="row" gap={1}>
                <RHFTextField name="stock" label="Value" variant="outlined" />
                {operation === 'add' && (
                  <RHFTextField
                    name="reasonAdd"
                    required
                    label={Strings.field.selectReason}
                    variant="outlined"
                    select
                  >
                    <MenuItem value={'Inbound against PO'}>
                      {Strings.field.inboudAgainstPo}
                    </MenuItem>
                    <MenuItem value={'Transfer'}>{Strings.field.transfer}</MenuItem>
                    <MenuItem value={'Return'}>{Strings.field.return}</MenuItem>
                  </RHFTextField>
                )}
                {operation === 'remove' && (
                  <RHFTextField
                    name="reasonRemove"
                    required
                    label={Strings.field.selectReason}
                    variant="outlined"
                    select
                  >
                    <MenuItem value={'Damage'}>{Strings.field.damage}</MenuItem>
                    <MenuItem value={'Missing'}>{Strings.field.missing}</MenuItem>
                    <MenuItem value={'Expired'}>{Strings.field.expired}</MenuItem>
                  </RHFTextField>
                )}
                <RHFTextField
                  name="operation"
                  required
                  label={Strings.field.operation}
                  variant="outlined"
                  select
                >
                  <MenuItem value={'add'}>{Strings.field.add}</MenuItem>
                  <MenuItem value={'remove'}>{Strings.field.remove}</MenuItem>
                </RHFTextField>
              </Stack>
            </Stack>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button variant="outlined" color="inherit" onClick={onClose}>
              {Strings.button.cancel}
            </Button>
            <LoadingButton
              loading={saveInventory}
              type="submit"
              variant="contained"
              onClick={methods.handleSubmit(onSubmit)}
            >
              {Strings.button.save}
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </FormProvider>
    </>
  );
};

export default EditInventoryStock;
