import { styled } from '@mui/material';

export const MenuIconStyled = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
  borderBottom: `solid 1px ${theme.palette.grey[400]}`,
  display: 'flex',
}));

export const CloseIconStyled = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
  display: 'flex',
  justifyContent: 'flex-end',
  color: `${theme.palette.grey[400]}`,
  position: 'fixed',
  top: 10,
  right: 10,
}));
