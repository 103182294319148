import { useRef } from 'react';
import { SnackbarProvider as NotistackProvider, type SnackbarKey } from 'notistack';
import { alpha } from '@mui/material/styles';
import { Box, IconButton } from '@mui/material';

import { Iconify, type IconifyProps } from '../iconify';
import StyledNotistack from './styles';

// ----------------------------------------------------------------------

interface Props {
  children: React.ReactNode;
}

const SnackbarProvider = ({ children }: Props) => {
  const notistackRef = useRef<NotistackProvider | null>(null);

  const onClose = (key: SnackbarKey) => () => {
    notistackRef.current?.closeSnackbar(key);
  };

  return (
    <>
      <StyledNotistack />

      <NotistackProvider
        style={{ display: 'flex', flexWrap: 'nowrap' }}
        ref={notistackRef}
        dense
        maxSnack={5}
        preventDuplicate
        autoHideDuration={3000}
        variant="info"
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        iconVariant={{
          info: <SnackbarIcon icon="eva:info-fill" color="info" />,
          success: <SnackbarIcon icon="eva:checkmark-circle-2-fill" color="success" />,
          warning: <SnackbarIcon icon="eva:alert-triangle-fill" color="warning" />,
          error: <SnackbarIcon icon="eva:alert-circle-fill" color="error" />,
        }}
        action={(key) => (
          <IconButton size="small" onClick={onClose(key)} sx={{ p: 0.5 }}>
            <Iconify icon="eva:close-fill" />
          </IconButton>
        )}
      >
        {children}
      </NotistackProvider>
    </>
  );
};

// ----------------------------------------------------------------------

interface SnackbarIconProps {
  icon: IconifyProps;
  color: 'info' | 'success' | 'warning' | 'error';
}

function SnackbarIcon({ icon, color }: SnackbarIconProps) {
  return (
    <Box
      component="span"
      sx={{
        mr: 1.5,
        width: 40,
        height: 40,
        display: 'flex',
        flexWrap: 'no-wrap',
        borderRadius: 1.5,
        alignItems: 'center',
        justifyContent: 'center',
        color: `${color}.main`,
        bgcolor: (theme) => alpha(theme.palette[color].main, 0.16),
      }}
    >
      <Iconify icon={icon} width={24} />
    </Box>
  );
}

export { SnackbarProvider };
