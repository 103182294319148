import { Divider, DialogContent, Dialog } from '@mui/material';

import { Strings } from '@app/constants';
import { Header } from '@app/sections/header';
import { AttributeListPage } from '@app/modules/attribute';

interface Props {
  open: boolean;
  onClose: VoidFunction;
  onSubmit: (selected: any[]) => void;
}

const SelectAttributeDialog = ({ open, onClose, onSubmit }: Props) => {
  return (
    <Dialog open={open} fullWidth>
      <Header variant="dialog" title={Strings.button.selectAttribute} onClose={onClose} />

      <Divider />

      <DialogContent>
        <AttributeListPage onClose={onClose} onSubmit={onSubmit} />
      </DialogContent>

      <Divider />
    </Dialog>
  );
};

export default SelectAttributeDialog;
