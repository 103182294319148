import { Stack, Typography, Card } from '@mui/material';

import { PaginatedTable } from '@app/components';
import { OrderRow } from './order-row';
import { TableProps } from '@app/components/table';
import { IOrderData } from '../types';

interface Props {
  tableProps: TableProps;
  loading: boolean;
  rows: IOrderData[];
}
const TABLE_HEAD = [
  { id: 'id', label: 'Id', align: 'center', sortable: false },
  { id: 'skuid', label: 'SKU-Id', align: 'center', sortable: false },
  { id: 'vendorname', label: 'Vendor Name', align: 'center' },
  { id: 'quantity', label: 'Quantity', align: 'center', sortable: false },
  { id: 'invprice', label: 'Inv.Price', align: 'center', sortable: false },
  { id: 'finalprice', label: 'Final.Price', align: 'center', sortable: false },
];
const InventoryTransaction = ({ rows, tableProps, loading }: Props) => {
  return (
    <Card
      sx={{
        width: '95%',
        marginTop: '2%',
        alignSelf: 'center',
        borderRadius: 0,
      }}
    >
      <Stack sx={{ backgroundColor: '#F4F6F8', padding: '2% 0% 2% 0%' }}>
        <Typography variant="h5" ml={1.5}>
          Inventory details
        </Typography>
      </Stack>
      <PaginatedTable<IOrderData>
        head={TABLE_HEAD}
        data={rows}
        tableProps={tableProps}
        loading={loading}
        isServerSidePaging={false}
        paginationShown={false}
      >
        {(row) => (
          <OrderRow
            row={row}
            selected={tableProps.selected.includes(row.id)}
            onSelectRow={() => {
              ('');
            }}
          />
        )}
      </PaginatedTable>
    </Card>
  );
};
export { InventoryTransaction };
