import {
  Alert,
  Box,
  IconButton,
  Link,
  Stack,
  Typography,
  alpha,
  Hidden,
  Button,
} from '@mui/material';
import { CSVLink } from 'react-csv';
import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { Iconify, SearchBar, SearchBarProps } from '@app/components';
import { StyledRowSpace } from './styles';
import { ConvertTOPdf } from '@app/utils';
interface Props {
  title?: string;
  data?: any;
  csvHead?: any;
  csvFileName?: string;
  helperText?: string;
  onClickHelperText?: VoidFunction;
  actionButtonTitle?: string;
  additionalButtonTitle?: string;
  additionalExportButtonTitle?: string;
  additionalExportPdfButtonTitle?: string;
  additionalMasterButtonTitle?: string;
  actionButtonImport?: string;
  onClickActionButton?: VoidFunction;
  onClickActionButtonImport?: VoidFunction;
  onClickAdditionalButton?: VoidFunction;
  onClickAdditionalMasterButton?: VoidFunction;
  searchBarProps?: SearchBarProps;
  detail?: boolean;
  onClose?: () => void;
  variant?: 'list' | 'dialog';
  bannerDetail?: any;
  backButton?: boolean;
  onClickBack?: VoidFunction;
  filter?: boolean;
  onClickFilter?: VoidFunction;
  orientation?: string;
}

export const Header = ({
  title,
  data,
  csvHead,
  csvFileName,
  helperText,
  orientation,
  onClickHelperText,
  actionButtonTitle,
  additionalButtonTitle,
  additionalExportButtonTitle,
  additionalExportPdfButtonTitle,
  additionalMasterButtonTitle,
  actionButtonImport,
  onClickAdditionalMasterButton,
  onClickActionButton,
  onClickAdditionalButton,
  onClickActionButtonImport,
  searchBarProps,
  detail,
  onClose,
  backButton,
  onClickBack,
  filter,
  onClickFilter,
  bannerDetail,
  variant = 'list',
}: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Stack sx={{ p: 2 }}>
      <StyledRowSpace>
        {Boolean(backButton) && (
          <Stack padding={1} onClick={onClickBack} sx={{ cursor: 'pointer' }}>
            <img src={`/assets/icons/navbar/arrow-back.svg`} alt="back" />
          </Stack>
        )}
        {variant === 'dialog' ? (
          <Typography variant="h6">{title}</Typography>
        ) : (
          <Hidden smDown>
            <Typography variant="h3">{title}</Typography>
          </Hidden>
        )}

        <Stack flexDirection="row" flex={1} justifyContent={'flex-end'}>
          {Boolean(additionalMasterButtonTitle) && (
            <Button variant="contained" onClick={onClickAdditionalMasterButton} sx={{ mr: 2 }}>
              {additionalMasterButtonTitle}
            </Button>
          )}
          {Boolean(additionalButtonTitle) && (
            <Button variant="contained" onClick={onClickAdditionalButton} sx={{ mr: 2 }}>
              {additionalButtonTitle}
            </Button>
          )}

          {Boolean(actionButtonImport) && (
            <Button variant="contained" onClick={onClickActionButtonImport} sx={{ marginRight: 2 }}>
              {actionButtonImport}
            </Button>
          )}
          {Boolean(actionButtonTitle) && (
            <Button variant="contained" onClick={onClickActionButton}>
              {actionButtonTitle}
            </Button>
          )}

          {Boolean(additionalExportButtonTitle) && (
            <Button
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              variant="contained"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
              sx={{ marginLeft: 2 }}
            >
              Export
            </Button>
          )}
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem>
              {Boolean(additionalExportButtonTitle) && data && (
                <CSVLink data={data} headers={csvHead} filename={csvFileName}>
                  <Button className="">{additionalExportButtonTitle}</Button>
                </CSVLink>
              )}
            </MenuItem>
            <MenuItem>
              {Boolean(additionalExportPdfButtonTitle) && (
                <Button
                  onClick={() => {
                    ConvertTOPdf(data, csvHead, orientation);
                  }}
                >
                  {additionalExportPdfButtonTitle}
                </Button>
              )}
            </MenuItem>
          </Menu>
        </Stack>

        {onClose && (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme: any) => theme.palette.grey[500],
            }}
          >
            <Iconify icon={'material-symbols:close'} />
          </IconButton>
        )}
      </StyledRowSpace>

      {Boolean(helperText) && <Box sx={{ mt: 3 }} />}
      {Boolean(helperText) && (
        <Alert
          severity="info"
          sx={{
            bgcolor: (theme) => alpha(theme.palette.info.main, 0.16),
          }}
        >
          <Button variant="text" sx={{ padding: 0 }} onClick={onClickHelperText}>
            <Link variant="subtitle2" underline="always">
              {helperText}
            </Link>
          </Button>
        </Alert>
      )}

      {Boolean(detail) && (
        <>
          <Stack
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'space-around'}
            alignItems={'center'}
          >
            <Stack
              height={'150px'}
              width={'200px'}
              marginX={5}
              marginTop={5}
              style={{ backgroundColor: 'lightgray', borderRadius: '10px' }}
            >
              <img
                height={'200px'}
                width={'200px'}
                style={{ objectFit: 'contain', padding: '10px' }}
                src={bannerDetail.image}
              />
            </Stack>
            <Stack flexWrap={'wrap'} width={'600px'} margin={5}>
              <Typography variant="h3">{bannerDetail.title}</Typography>
              <Typography color={'gray'} variant="subtitle1">
                {bannerDetail.bannerType}
              </Typography>

              <Stack display={'flex'} flexWrap={'wrap'} color={'gray'}>
                <Typography variant="caption">{bannerDetail.description}</Typography>
              </Stack>
            </Stack>
          </Stack>
        </>
      )}

      {Boolean(searchBarProps) && (
        <>
          <Box mt={3} />
          <SearchBar onClickFilter={onClickFilter} filterShown={filter} {...searchBarProps} />
        </>
      )}
    </Stack>
  );
};
