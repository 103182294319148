import { LoadingButton } from '@mui/lab';

import { ConfirmDialog } from '../confirm-dialog';

interface Props {
  visible: boolean;
  title: string;
  caption: string;
  deleteButtonTitle: string;
  loading?: boolean;
  onClickDelete: VoidFunction;
  onClose: VoidFunction;
}

const DeleteDialog = ({
  visible,
  title,
  caption,
  loading,
  onClickDelete,
  onClose,
  deleteButtonTitle,
}: Props) => (
  <ConfirmDialog
    title={title}
    content={caption}
    onClose={onClose}
    open={visible}
    action={
      <LoadingButton
        variant="contained"
        color="error"
        sx={{ bgcolor: 'error.dark' }}
        loading={loading}
        onClick={onClickDelete}
      >
        {deleteButtonTitle}
      </LoadingButton>
    }
  />
);

export default DeleteDialog;
