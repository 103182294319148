import { IconButton, Stack, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';

import { TableCellCustom } from '@app/components/table';
import { ISku } from '../types';
import { CustomAvatar } from '@app/components/custom-avatar';
import { IMAGE_BASE_URL } from '@app/configs';
import { TruncatedTypography } from '@app/components/typography';
import { Iconify } from '@app/components';
import { deleteSkuBanner } from '../slice';
import { selectDeleteBannerState } from '../selectors';
import { useRequestState } from '@app/hooks';

interface Props {
  row: ISku;
  selected: boolean;
  onSelectRow: VoidFunction;
  bannerId: number;
}

const SkuRow = (props: Props) => {
  const { row, onSelectRow } = props;

  const dispatch = useDispatch();

  const handleDeleteSku = () => {
    dispatch(deleteSkuBanner({ skuId: row?.id, id: props.bannerId }));
  };

  useRequestState({
    stateSelector: selectDeleteBannerState,
    successMessageShown: true,
    errorShown: true,
  });

  return (
    <>
      <TableCellCustom onClick={onSelectRow}>
        <Stack style={{ flexDirection: 'row', alignItems: 'center', gap: '9px' }}>
          <CustomAvatar
            name={row?.name}
            color="primary"
            alt="pic"
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            src={IMAGE_BASE_URL + row.productImage}
          />
        </Stack>
      </TableCellCustom>
      <TableCellCustom flex align="center" onClick={onSelectRow}>
        <Typography variant="body2">{row?.skuId}</Typography>
      </TableCellCustom>
      <TableCellCustom flex align="center" onClick={onSelectRow}>
        <TruncatedTypography text={row.products?.name ?? 'NA'} height={20} lines={1} />
      </TableCellCustom>
      <TableCellCustom flex align="center" onClick={onSelectRow}>
        <TruncatedTypography text={row?.name ?? 'NA'} height={20} lines={1} />
      </TableCellCustom>
      <TableCellCustom flex align="center" onClick={onSelectRow}>
        <TruncatedTypography text={row?.products?.category?.name ?? 'NA'} height={20} lines={1} />
      </TableCellCustom>
      <TableCellCustom flex align="center" onClick={onSelectRow}>
        <TruncatedTypography
          text={row?.products?.category?.parentCategory?.name ?? 'NA'}
          height={20}
          lines={1}
        />
      </TableCellCustom>
      <TableCellCustom width={50}>
        <IconButton sx={{ p: 0.25, borderRadius: '5px' }} onClick={handleDeleteSku}>
          <Iconify icon="mdi:minus-box" width={25} color="#F39200" />
        </IconButton>
      </TableCellCustom>
    </>
  );
};

export { SkuRow };
