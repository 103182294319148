import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import {
  changeDeliverySlot,
  changeDeliverySlotFailed,
  changeDeliverySlotSuccess,
  getOrderDetails,
  getOrderDetailsFailed,
  getOrderDetailsSuccess,
  getSlotsByDateType,
  getSlotsByDateTypeFailed,
  getSlotsByDateTypeSuccess,
  updateOrderStatus,
  updateOrderStatusFailed,
  updateOrderStatusSuccess,
} from './slice';
import { request } from '@app/services';
import * as endpoints from './endpoints';
import { RootState } from '@app/redux/root-reducer';

// ----------------------------------------
function* getOrderDetailSaga(action: ReturnType<typeof getOrderDetails>): any {
  const { id } = action.payload;
  try {
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    const result = yield call(request.get, endpoints.getOrderDetails + id, {
      params: {
        limit: 500,
      },
    });
    yield put(getOrderDetailsSuccess(result.data.data));
  } catch (err: any) {
    yield put(getOrderDetailsFailed(err));
  }
}

// ----------------------------------------
function* getSlotsByDateTypeSaga(action: ReturnType<typeof getSlotsByDateType>): any {
  const { slotDate, slotType } = action.payload;
  try {
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    const result = yield call(
      request.get,
      endpoints.getSlotsByDateType + '?slotType=' + slotType + '&slotDate=' + slotDate
    );
    yield put(getSlotsByDateTypeSuccess(result.data.data));
  } catch (err: any) {
    yield put(getSlotsByDateTypeFailed(err));
  }
}
// ----------------------------------------
function* changeDeliverySlotSaga(action: ReturnType<typeof changeDeliverySlot>): any {
  const { slotId, orderId, deliveryDate } = action.payload;
  try {
    const { data } = yield select((store: RootState) => store.orderDetailSlice.getOrderDetails);

    const result = yield call(request.post, endpoints.changeDeliverySlot, {
      slotId,
      orderId,
      deliveryDate,
    });

    const currentIndex = data.orderSlots.findIndex(
      (slot: any) => slot.CurrentDeliverySlot === true
    );

    let newData;
    if (currentIndex !== -1) {
      const updatedSlots = data.orderSlots.map((slot: any, index: any) =>
        index === currentIndex ? { ...slot, CurrentDeliverySlot: false } : slot
      );
      newData = { ...data, orderSlots: updatedSlots };
      newData.orderSlots.push(result.data.data);
    }

    yield put(getOrderDetailsSuccess(newData));
    yield put(changeDeliverySlotSuccess(result.data.data));
  } catch (err: any) {
    yield put(changeDeliverySlotFailed(err));
  }
}
// ----------------------------------------

function* updateOrderStatusSagas(action: ReturnType<typeof updateOrderStatus>): any {
  try {
    const { orderStatus, id } = action.payload;

    const result = yield call(
      request.put,
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      endpoints.updateOrderStatus + '?id=' + id + '&orderStatus=' + orderStatus
    );

    if (result.data.status) {
      yield put(updateOrderStatusSuccess(result.data.message));
    } else {
      yield put(updateOrderStatusFailed(result.data));
    }
  } catch (err: any) {
    yield put(updateOrderStatusFailed(err));
  }
}

// ----------------------------------------
function* orderDetailSagas() {
  yield all([
    takeLatest([getOrderDetails], getOrderDetailSaga),
    takeLatest(updateOrderStatus, updateOrderStatusSagas),
    takeLatest([getSlotsByDateType], getSlotsByDateTypeSaga),
    takeLatest([changeDeliverySlot], changeDeliverySlotSaga),
  ]);
}

export { orderDetailSagas };
