import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { RequestStatus } from '@app/constants';
import { IRequestFailed } from '@app/types';
import { IGetOrder, IOrderState } from './types';

const initialState: IOrderState = {
  order: {},
};

const slice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    getOrder(state, action: PayloadAction<IGetOrder>) {
      state.order.status = RequestStatus.RUNNING;
    },
    getOrderSuccess(state, action: PayloadAction<IOrderState['order']['data']>) {
      state.order.status = RequestStatus.SUCCESS;
      state.order.data = action.payload;
    },
    getOrderFailed(state, action: PayloadAction<IRequestFailed>) {
      state.order.status = RequestStatus.ERROR;
      state.order.error = action.payload;
    },
  },
});
export default slice.reducer;

export const { getOrder, getOrderSuccess, getOrderFailed } = slice.actions;
