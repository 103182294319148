import { useCallback } from 'react';
import { Button, Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocation, useNavigate } from 'react-router';
import { LoadingButton } from '@mui/lab';

import { FormProvider, Iconify, RHFTextField } from '@app/components';
import { Strings } from '@app/constants';
import { PATH_AUTH } from '@app/routes/paths';
import { useRequestState } from '@app/hooks';
import type { IForgotPasswordSchema } from '../types';
import { forgotPasswordSchema } from '../validators';
import { forgotPassword } from '../slice';
import { selectForgotPasswordState } from '../selectors';

// --------------------------------------------

const ForgotPasswordForm = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const email = location.state.email || '';

  const methods = useForm<IForgotPasswordSchema>({
    resolver: yupResolver(forgotPasswordSchema),
    defaultValues: {
      email,
    },
  });

  const onSubmit = (form: IForgotPasswordSchema) => {
    dispatch(forgotPassword(form));
  };

  const navigateBack = useCallback(() => {
    navigate(PATH_AUTH.login);
  }, [navigate]);

  const { loading } = useRequestState({
    stateSelector: selectForgotPasswordState,
    errorShown: true,
    successMessageShown: true,
    onSuccess: navigateBack,
  });

  return (
    <FormProvider methods={methods} onSubmit={methods.handleSubmit(onSubmit)}>
      <Stack spacing={2}>
        <RHFTextField name="email" type="email" label={Strings.field.email} fullWidth />

        <LoadingButton
          size="large"
          variant="contained"
          color="secondary"
          fullWidth
          type="submit"
          loading={loading}
        >
          {Strings.button.resetPassword}
        </LoadingButton>
        <Button
          onClick={navigateBack}
          size="large"
          variant="outlined"
          color="inherit"
          fullWidth
          type="submit"
          sx={{ textTransform: 'none' }}
        >
          <Iconify icon="ic:twotone-arrow-back-ios" marginRight={1} width={15} />
          {Strings.button.returnLogin}
        </Button>
      </Stack>
    </FormProvider>
  );
};

export { ForgotPasswordForm };
