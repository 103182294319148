import { styled, alpha } from '@mui/material/styles';
import {
  Box,
  MenuItem,
  Popover,
  Typography,
  ListItemAvatar,
  ListItemText,
  Button,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { type MouseEvent, useState } from 'react';

import { Iconify } from '@app/components';
import { CustomAvatar } from '@app/components/custom-avatar';
import { Strings } from '@app/constants';
import { logout } from '@app/modules/auth';
import { PATH_DASHBOARD } from '@app/routes/paths';
import { ConfirmDialog } from '@app/components/confirm-dialog';
import { useDialog } from '@app/hooks';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')<{ active: boolean }>(({ theme, active }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: active ? theme.palette.background.paper : alpha(theme.palette.grey[500], 0.24),
  cursor: 'pointer',
}));

// ----------------------------------------------------------------------

export default function NavAccount() {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const [open, setOpen] = useState<null | HTMLElement>(null);

  const handleOpen = (event: MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  const handleMyProfileClick = () => {
    navigation(PATH_DASHBOARD.myProfile);
    handleClose();
  };

  const isMenuShown = Boolean(open);

  const fullName = 'Jatin Soni';

  const confirmingLogout = useDialog();

  return (
    <>
      <StyledRoot onClick={handleOpen} active={isMenuShown}>
        <CustomAvatar alt={fullName} name={fullName} color="primary" />

        <Box sx={{ ml: 2, minWidth: 0 }}>
          <Typography
            variant="subtitle2"
            noWrap
            color={isMenuShown ? 'secondary' : 'secondary.contrastText'}
          >
            {fullName}
          </Typography>
        </Box>
      </StyledRoot>

      <Popover
        open={isMenuShown}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        PaperProps={{
          sx: {
            p: 0,
            ml: 0.75,
            width: 250,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
            boxShadow: 10,
          },
        }}
      >
        <Box color="secondary">
          <MenuItem divider sx={{ pointerEvents: 'none', py: 1.5 }}>
            <ListItemAvatar>
              <CustomAvatar name={fullName} alt={fullName} color="primary" />
            </ListItemAvatar>
            <ListItemText secondary={'Admin'}>
              <Typography variant="subtitle1" color="secondary">
                {fullName}
              </Typography>
            </ListItemText>
          </MenuItem>
          <MenuItem divider sx={{ py: 1.5 }} onClick={handleMyProfileClick}>
            <Iconify icon="material-symbols:person" mr={2} color="secondary.main" />

            <Typography variant="body2" color="secondary">
              {Strings.sidebar.myProfile}
            </Typography>
          </MenuItem>
          <MenuItem onClick={confirmingLogout.show} sx={{ py: 1.5 }}>
            <Iconify icon="material-symbols:logout" mr={2} color="secondary.main" />
            <Typography variant="body2" color="secondary">
              {Strings.sidebar.logOut}
            </Typography>
          </MenuItem>
        </Box>
      </Popover>
      <ConfirmDialog
        title={`${Strings.sidebar.logOut}?`}
        content={Strings.common.confirmLogout}
        open={confirmingLogout.visible}
        onClose={confirmingLogout.hide}
        action={
          <Button variant="contained" color="error" onClick={handleLogout}>
            {Strings.button.confirm}
          </Button>
        }
      />
    </>
  );
}
