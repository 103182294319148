import * as Yup from 'yup';

import { Strings } from '@app/constants';

// ---------------addVendorSchema-------------
export const addUserSchema = Yup.object()
  .shape({
    name: Yup.string().required(Strings.validation.fieldRequired(Strings.field.name)),
    vendorType: Yup.string().required(Strings.validation.selectRequired(Strings.field.vendorType)),
    email: Yup.string().required(Strings.validation.fieldRequired(Strings.field.email)),
    address: Yup.string().required(Strings.validation.fieldRequired(Strings.field.address)),
    city: Yup.string().required(Strings.validation.fieldRequired(Strings.field.city)),
    mobileNumber: Yup.string()
      .required(Strings.validation.fieldRequired(Strings.field.mobile))
      .matches(/^\+971\d{9}$/, Strings.validation.invalidMobileNumber),
    searchAddress: Yup.mixed()
      .notOneOf([''], Strings.validation.fieldRequired(Strings.field.product))
      .required(Strings.validation.fieldRequired(Strings.field.product)),
    state: Yup.string().optional(),
    country: Yup.string().required(Strings.validation.fieldRequired(Strings.field.country)),
    latitude: Yup.string(),
    longitude: Yup.string(),
    startTime: Yup.string().required(Strings.validation.fieldRequired(Strings.field.startTime)),
    endTime: Yup.string().required(Strings.validation.fieldRequired(Strings.field.endTime)),
    coverageArea: Yup.string()
      .required(Strings.validation.fieldRequired(Strings.field.coverageArea))
      .matches(/^[1-9]\d*$/, ({ value }) => {
        if (value === '0') {
          return Strings.validation.notZero(Strings.field.coverageArea);
        }
        return Strings.validation.intNumber(Strings.field.coverageArea);
      }),
  })
  .required();

// ---------------addAdminSchema-------------
export const addAdminSchema = Yup.object()
  .shape({
    name: Yup.string().required(Strings.validation.fieldRequired(Strings.field.name)),
    password: Yup.string().required(Strings.validation.fieldRequired(Strings.field.password)),
    email: Yup.string().required(Strings.validation.fieldRequired(Strings.field.email)),
    mobileNumber: Yup.string()
      .matches(/^(\+971\d{9})?$/, Strings.validation.invalidMobileNumber)
      .notRequired(),
    image: Yup.string(),
  })
  .required();

// ---------------addMasterSchema-------------
export const addMasterSchema = Yup.object()
  .shape({
    name: Yup.string().required(Strings.validation.fieldRequired(Strings.field.name)),
    mobileNumber: Yup.string()
      .required(Strings.validation.fieldRequired(Strings.field.mobile))
      .matches(/^\+971\d{9}$/, Strings.validation.invalidMobileNumber),
    email: Yup.string().required(Strings.validation.fieldRequired(Strings.field.email)),
    startTime: Yup.string().required(Strings.validation.fieldRequired(Strings.field.startTime)),
    endTime: Yup.string().required(Strings.validation.fieldRequired(Strings.field.endTime)),
    country: Yup.string().required(Strings.validation.fieldRequired(Strings.field.country)),
    city: Yup.string().required(Strings.validation.fieldRequired(Strings.field.city)),
    latitude: Yup.string().required(Strings.validation.fieldRequired(Strings.field.latitude)),
    longitude: Yup.string().required(Strings.validation.fieldRequired(Strings.field.longitude)),
    coverageArea: Yup.string()
      .required(Strings.validation.fieldRequired(Strings.field.coverageArea))
      .matches(/^[1-9]\d*$/, ({ value }) => {
        if (value === '0') {
          return Strings.validation.notZero(Strings.field.coverageArea);
        }
        return Strings.validation.intNumber(Strings.field.coverageArea);
      }),
  })
  .required();
