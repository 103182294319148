import { Checkbox, Stack } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useState } from 'react';

import { TableCellCustom } from '@app/components/table';
import { ISku } from '../types';
import { CustomAvatar } from '@app/components/custom-avatar';
import { IMAGE_BASE_URL } from '@app/configs';
import { TruncatedTypography } from '@app/components/typography';
import { updateSkuStatus } from '../slice';
import { useRequestState } from '@app/hooks';
import { selectUpdateSkuStatusState } from '../selectors';

interface Props {
  row: ISku;
  selected: boolean;
  onSelectRow: VoidFunction;
}

const SkuRow = (props: Props) => {
  const { row, onSelectRow } = props;
  const dispatch = useDispatch();
  const [isChecked, setIsChecked] = useState(row.active);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    dispatch(updateSkuStatus({ id: row.id }));
  };

  useRequestState({
    stateSelector: selectUpdateSkuStatusState,
    successMessageShown: true,
  });
  return (
    <>
      <TableCellCustom flex onClick={onSelectRow} fullWidth>
        <TruncatedTypography text={row.id} />
      </TableCellCustom>
      <TableCellCustom onClick={onSelectRow}>
        <Stack style={{ flexDirection: 'row', alignItems: 'center', gap: '9px' }}>
          <CustomAvatar
            name={row?.name}
            color="primary"
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            src={IMAGE_BASE_URL + row?.images[0]}
          />
        </Stack>
      </TableCellCustom>
      <TableCellCustom flex align="center" onClick={onSelectRow} fullWidth>
        <TruncatedTypography text={row.skuId} />
      </TableCellCustom>
      <TableCellCustom flex align="center" onClick={onSelectRow}>
        <TruncatedTypography text={row.name || 'NA'} />
      </TableCellCustom>
      <TableCellCustom flex align="center" onClick={onSelectRow}>
        <TruncatedTypography text={row.products?.name || 'NA'} />
      </TableCellCustom>
      <TableCellCustom flex align="center" onClick={onSelectRow}>
        <TruncatedTypography text={row.products?.category?.parentCategory?.name || 'NA'} />
      </TableCellCustom>
      <TableCellCustom flex align="center" onClick={onSelectRow}>
        <TruncatedTypography text={row.products?.category?.name || 'NA'} />
      </TableCellCustom>
      <TableCellCustom flex align="center">
        <Checkbox key={row.id} checked={isChecked} onChange={handleCheckboxChange} />
      </TableCellCustom>
    </>
  );
};

export { SkuRow };
