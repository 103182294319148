import { styled } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { TimePicker } from '@mui/x-date-pickers';

export const StyledBoxContent = styled('div')(({ theme }) => ({
  borderBottom: `1px solid ${alpha(theme.palette.grey[500], 0.32)}`,
  marginBottom: 20,
  marginTop: 10,
  marginLeft: 0,
  [theme.breakpoints.down('sm')]: {
    marginLeft: 0,
    borderBottom: `1px solid ${alpha(theme.palette.grey[500], 0.32)}`,
    marginBottom: 20,
    marginTop: 10,
  },
}));

export const StyledAvailableBoxContent = styled('div')(({ theme }) => ({
  marginBottom: 20,
  marginLeft: 25,
  [theme.breakpoints.down('sm')]: {
    marginBottom: 20,
    marginLeft: 25,
  },
}));

export const StyledMainBoxContent = styled('div')(({ theme }) => ({
  borderBottom: `1px solid ${alpha(theme.palette.grey[500], 0.32)}`,
  marginBottom: 20,
  marginLeft: 25,
  paddingBottom: 10,
  display: 'flex',
  justifyContent: 'left',
  marginTop: 10,
  [theme.breakpoints.down('sm')]: {
    borderBottom: `1px solid ${alpha(theme.palette.grey[500], 0.32)}`,
    marginBottom: 20,
    paddingBottom: 10,
    display: 'flex',
    justifyContent: 'left',
    marginLeft: 25,
    marginTop: 10,
  },
}));
export const StyledTimePickerContent = styled(TimePicker)(({ theme }) => ({
  flex: 1,
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    flex: 1,
    width: '100%',
  },
}));

export const SlotManagementDetailsPage = styled('div')(({ theme }) => ({
  borderColor: `1px solid ${alpha(theme.palette.grey[500], 0.32)}`,
  marginBottom: 2,
  marginTop: 2,
  border: 1,
  [theme.breakpoints.down('sm')]: {
    borderColor: `1px solid ${alpha(theme.palette.grey[500], 0.32)}`,
    marginBottom: 2,
    marginTop: 2,
    border: 1,
  },
}));
