export const Strings = {
  sidebar: {
    customers: 'Customers',
    slotManagement: 'Slot Management',
    orderManagement: 'Order Management',
    collection: 'Collection',
    banner: 'Banner',
    dashboard: 'Dashboard',
    orders: 'Orders',
    users: 'Users',
    service: 'Service',
    categories: 'Categories',
    product: 'Product',
    slotsManagement: 'Slots Management',
    inventoryBannerManagement: 'Inventory Management',
    logOut: 'Log Out',
    myProfile: 'My Profile',
    sku: 'SKU',
    attributes: 'Attributes',
  },
  field: {
    startTime: 'Start Time',
    operation: 'Operation',
    endTime: 'End Time',
    discountPrice: 'Discount Price',
    title: 'Title',
    buttonTitle: 'Button Title',
    active: 'Active',
    slotType: 'Slot Type',
    category: 'Category',
    product: 'Product',
    location: 'Location',
    description: 'Description',
    price: 'Price',
    ourPrice: 'Our Price',
    wallet: 'wallet',
    phone: 'Phone',
    email: 'Email',
    password: 'Password',
    confirmPassword: 'Confirm Password',
    firstName: 'First Name',
    lastName: 'Last Name',
    mobile: 'Mobile Number',
    selectedAddress: 'Select Address',
    status: 'Status',
    userType: 'User Type',
    name: 'Name',
    skuId: 'SKU Id',
    productImage: 'Product Image',
    subtitle: 'Subtitle',
    image: 'Image',
    video: 'Video',
    serviceName: 'Service Name',
    serviceImage: 'Service Image',
    sku: 'SKU',
    subCategory: 'Sub Category',
    childCategory: 'Child Category',
    parentCategory: 'Parent Category',
    type: 'Type',
    stock: 'Stock',
    vendor: 'Vendor',
    address: 'Address',
    city: 'City',
    state: 'State',
    country: 'Country',
    pinCode: 'Pincode',
    coverageArea: 'Coverage Area',
    latitude: 'Latitude',
    longitude: 'Longitude',
    vendorType: 'Vendor Type',
    searchAddress: 'Search Address',
    selectDeliveryTime: 'Select a delivery time',
    selectReason: 'Select a reason',
    inboudAgainstPo: 'Inbound against PO',
    transfer: 'Transfer',
    return: 'Return',
    damage: 'Damage',
    missing: 'Missing',
    expired: 'Expired',
    add: 'Add',
    remove: 'Remove',
    orderStatus: 'Order Status',
    noCities: 'No Cities',
  },
  button: {
    exportpdf: 'Export to PDF',
    exportCsv: 'Export to CSV',
    addOrder: 'Add Order',
    addSlot: 'Add slot',
    addNew: 'Add New',
    addBanner: 'Add Banner',
    addCollection: 'Add Collection',
    login: 'Login',
    resetPassword: 'Reset Password',
    returnLogin: 'Return to Login',
    setPassword: 'Set Password',
    cancel: 'Cancel',
    edit: 'Edit',
    delete: 'Delete',
    view: 'View',
    confirm: 'Confirm',
    addNewCategory: 'Add New Category',
    addInventory: 'Add Inventory',
    addVendor: 'Add Vendor',
    addAdmin: 'Add Admin',
    addService: 'Add Service',
    save: 'Save',
    addProduct: 'Add Product',
    addSku: 'Add SKU',
    addAttribute: 'Add Attribute',
    selectAttribute: 'Select Attribute',
    addNewBanner: 'Add New Banner',
    addProductInBanner: 'Add Product In Banner',
    import: 'Import',
    addParentCategory: 'Add Parent Category',
    addMaster: 'Add Master',
    downloadTemplate: 'Download Template',
  },
  header: {
    editAdmin: 'Edit Admin',
    editVendor: 'Edit Vendor',
    addAdmin: 'Add Admin',
    addVendor: 'Add Vendor',
    addMaster: 'Add Master',
    editMaster: 'Edit Master',
    customer: 'Customer',
    slot: 'Slot',
    sku: 'Sku',
    banner: 'Banner',
    setPassword: 'Set Password',
    login: 'Login',
    forgotPassword: 'Forgot Password',
    user: 'User',
    userDetails: 'User Details',
    service: 'Service',
    category: 'Category',
    product: 'Product',
    attribute: 'Attributes',
  },
  auth: {
    signIn: 'Sign In',
    signInYour: 'SignIn to your PetVesta account',
    forgotPassword: 'Forgot Password?',
    useYourOfficial: 'Use your official email id to login',
    forgotPasswordHeading: 'Forgot Password',
    enterYourOfficial:
      'Enter your official email address, mobile number, or username to change your account password',
    setYourPassword: 'Set your password',
    enterAPassword: 'Enter a password to set it for your account',
  },
  common: {
    searchNameEmailPhone: 'Search By Name, Email, Phone... ',
    searchNameEmailUserType: 'Search By Name, Email, UserType... ',
    searchNamePhone: 'Search By Name, Phone...',
    copyright: '© 2023, PetVesta Pvt. Ltd. All Rights Reserved.',
    developAutoResearch: '',
    success: 'Success!',
    failed: 'Failed!',
    selected: (prefix: string) => (prefix ? `${prefix} selected` : 'selected'),
    noRecord: 'No Record',
    pageNotFound: 'Page not found!',
    sorryCanNotFoundPage: 'Sorry, we couldn’t find the page you’re looking for.',
    search: 'Search...',
    delete: 'Delete',
    active: 'Active',
    inactive: 'Inactive',
    activeInactiveTitle: (isActive: boolean) => (isActive ? 'Inactive' : 'Active'),
    deleteCaption: (section: string) =>
      `Are you sure you want to delete this ${section}? This action can not be undone.`,
    activeInactiveCaption: (isActive: boolean, section: string) =>
      `Are you sure you want to ${
        isActive ? 'inactive' : 'active'
      } this ${section}? This action cannot be undone.`,
    update: 'Update',
    confirmLogout: 'Are you sure you want to Logout?',
    attributes: 'Attributes',
  },
  validation: {
    chooseImg: 'Please upload at least one image.',
    fieldRequired: (field: string) => `Please enter the ${field}`,
    uploadRequired: (field: string) => `Please upload the ${field}`,
    selectRequired: (field: string) => `Please select the ${field}`,
    intNumber: (field: string) => `${field} must be a integer value.`,
    notZero: (field: string) => `${field} cannot be zero.`,
    invalidEmail: 'Please enter the valid email address',
    passwordNotMatch: 'Password and Confirm Password do not match',
    passwordMustContain: 'Password must contain at least 1 alphabet and 1 number',
    invalidMobileNumber: 'Please enter a valid Mobile Number for Exp:- +971XXXXXXXXX',
    fiveImgError: 'At least 5 images are required',
    oneImgError: 'At least 1 images are required',
  },

  rowActions: {
    edit: 'Edit',
    delete: 'Delete',
    view: 'View',
    addSubCategory: 'Add Sub Category',
  },
  pageTitle: {
    editSlotManagement: 'Edit Slot Management',
    addSlotManagement: 'Add Slot Management',
    customers: 'Customers',
    orderManagement: 'Order Management',
    skuDetail: 'SKU Detail',
    userDetails: 'User Details',
    addSlot: 'Add Slot',
    editAttribute: 'Edit Attribute',
    addAttribute: 'Add Attribute',
    slotManagement: 'Slot Management',
    collectionDetail: 'Collection Detail',
    inventoryDetail: 'Inventory Detail',
    productDetail: 'Product Detail',
    bannerDetail: 'Banner Detail',
    collection: 'Collection',
    banner: 'Banner',
    categories: 'Categories',
    categoryDetail: 'Category Detail',
    attributes: 'Attributes',
    users: 'Users',
    service: 'Service',
    dashboard: 'Dashboard',
    addService: 'Add Service',
    products: 'Products',
    sku: "SKU's",
    welcomeAdmin: 'Welcome Back Admin !',
    inventoryBannerManagement: 'Inventory Management',
    editProduct: 'Edit Product',
    editCategory: 'Edit Category',
    editSku: 'Edit SKU',
    editInventory: 'Edit Inventory',
    selectDeliveryDateTime: 'Select Delivery Date and Time',
    editStock: 'Edit Stock',
  },
  slotManagement: {
    normal: 'normal',
    express: 'express',
    availableDays: 'Available Days',
    capacity: 'Capacity',
    expressDuration: 'Express Duration',
    deliveryStartTime: 'Delivery Start Time',
    deliveryEndTime: 'Delivery End Time',
    duration: 'Duration',
    orderCutOff: 'Order Cut Off',
    deliveryCharge: 'Delivery Charge',
    specificDate: 'Specific Date',
    maximumOrders: 'Maximum orders',
    slotType: 'Slot Type',
    showOnSpecificDatesOnly: 'Show on specific dates only',
  },

  status: {
    publish: 'Publish',
    unPublish: 'Unpublish',
  },
  addUser: {
    mp: 'MP',
    retailer: 'Retailer',
    admin: 'Admin',
    vendor: 'Vendor',
  },
  detailCard: {
    description: 'Description',
    addressType: 'Address Type:',
    city: 'City:',
    paymentMode: 'Payment Mode',
    country: 'Country:',
    changeDeliveryTime: 'Change Delivery Time',
    picked: 'Picked',
    ordered: 'Ordered',
    preparing: 'Preparing',
    delivering: 'Delivering',
    delivered: 'Delivered',
    orderDetails: 'Order Details',
    name: 'Name:',
    phone: 'Phone:',
    email: 'Email:',
    totalOrder: 'Total Order:',
    address: 'Address:',
    price: (products: number) => `Price (${products}`,
    discount: 'Discount',
    buyMore: 'Buy more & save money',
    deliveryCharges: 'Delivery Charges',
    totalAmount: 'Total Amount',
    customerDetails: 'Customer Details:',
    priceDetails: 'Price Details:',
    type: 'Type',
  },
  csv: {
    duplicateVendorAndSkuPairs: 'Duplicate vendorId and skuId pairs are not allowed.',
  },
};
