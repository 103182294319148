import { Header } from '@app/sections/header';
import { Strings } from '@app/constants';
import { useTable } from '@app/components/table';
import { Services } from '@app/mock/services';
import { useDialog } from '@app/hooks';
import ServicesList from './services-list';
import AddService from './add-service';

const ServiceListPage = () => {
  const tableProps = useTable();

  const addServiceDialog = useDialog();

  return (
    <>
      <Header
        title={Strings.pageTitle.service}
        helperText={''}
        actionButtonTitle={Strings.button.addService}
        onClickActionButton={addServiceDialog.show}
        searchBarProps={{
          placeholder: Strings.common.search,
          onChange: () => {
            ('');
          },
        }}
      />

      <ServicesList loading={false} tableProps={tableProps} rows={Services} />

      {addServiceDialog.visible && (
        <AddService open={addServiceDialog.visible} onClose={addServiceDialog.hide} />
      )}
    </>
  );
};

export { ServiceListPage };
