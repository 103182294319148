import { useFormContext, Controller } from 'react-hook-form';
import { TimePicker, renderTimeViewClock } from '@mui/x-date-pickers';
import { TextFieldProps } from '@mui/material';
import { useState } from 'react';

type Props = TextFieldProps & {
  name: string;
  label: string;
};

export function RHFTimePicker({ label, name, helperText, ...other }: Props) {
  const { control } = useFormContext();

  const [time, setTime] = useState<Date | string | null>();

  const handleValueChange = (value: any, field: any) => {
    field.onChange(value);
    setTime(value);
  };
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <>
          <TimePicker
            {...field}
            sx={{
              flex: 1,
              width: '100%',
            }}
            label={label}
            slotProps={{
              textField: {
                error: !!error,
                helperText: error?.message,
              },
            }}
            viewRenderers={{
              hours: renderTimeViewClock,
              minutes: renderTimeViewClock,
              seconds: renderTimeViewClock,
            }}
            value={time}
            defaultValue={field.value}
            onChange={(value) => {
              handleValueChange(value, field);
            }}
          />
        </>
      )}
    />
  );
}
