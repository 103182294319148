import * as Yup from 'yup';

import { Strings } from '@app/constants';

// ---------------addInventorySchema-------------
export const addInventorySchema = Yup.object()
  .shape({
    stock: Yup.string().required(Strings.validation.fieldRequired(Strings.field.stock)),
    price: Yup.string().required(Strings.validation.fieldRequired(Strings.field.price)),
    discountPrice: Yup.string().required(
      Strings.validation.fieldRequired(Strings.field.discountPrice)
    ),
    ourPrice: Yup.string().required(Strings.validation.fieldRequired(Strings.field.ourPrice)),
    vendorId: Yup.mixed()
      .notOneOf([''], Strings.validation.fieldRequired(Strings.field.vendor))
      .required(Strings.validation.fieldRequired(Strings.field.vendor)),
    skuId: Yup.mixed()
      .notOneOf([''], Strings.validation.fieldRequired(Strings.field.sku))
      .required(Strings.validation.fieldRequired(Strings.field.sku)),
  })
  .required();

export const editInventoryStockSchema = Yup.object()
  .shape({
    stock: Yup.string().required(Strings.validation.fieldRequired(Strings.field.stock)),
    operation: Yup.string().required(Strings.validation.selectRequired(Strings.field.operation)),
  })
  .required();
