import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { RequestStatus } from '@app/constants';
import { IRequestFailed, IRequestStatus } from '@app/types';
import { IAddCategorySchema, IAddParentCategory, ICategory, ICategoryState } from './types';

const initialState: ICategoryState = {
  category: {},
  parentCategory: {},
  addCategory: {},
  addParentCategory: {},
  activeTopCategory: {},
  updateCategoryStatus: {},
  categoryDetail: {},
};

const slice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    getCategory(state, action: PayloadAction<{ page: number; q?: any }>) {
      state.category.status = RequestStatus.RUNNING;
    },
    getCategorySuccess(state, action: PayloadAction<ICategoryState['category']['data']>) {
      state.category.status = RequestStatus.SUCCESS;
      state.category.data = action.payload;
    },
    getCategoryFailed(state, action: PayloadAction<IRequestFailed>) {
      state.category.status = RequestStatus.ERROR;
      state.category.error = action.payload;
    },
    //
    getCategoryDetail(state, action: PayloadAction<{ id: number }>) {
      state.categoryDetail.status = RequestStatus.RUNNING;
    },
    getCategoryDetailSuccess(
      state,
      action: PayloadAction<ICategoryState['categoryDetail']['data']>
    ) {
      state.categoryDetail.status = RequestStatus.SUCCESS;
      state.categoryDetail.data = action.payload;
    },
    getCategoryDetailFailed(state, action: PayloadAction<IRequestFailed>) {
      state.categoryDetail.status = RequestStatus.ERROR;
      state.categoryDetail.error = action.payload;
    },
    //
    getParentCategory(state, action: PayloadAction<{ page: number; q: any }>) {
      state.parentCategory.status = RequestStatus.RUNNING;
    },
    getParentCategorySuccess(state, action: PayloadAction<ICategoryState['category']['data']>) {
      state.parentCategory.status = RequestStatus.SUCCESS;
      state.parentCategory.data = action.payload;
    },
    getParentCategoryFailed(state, action: PayloadAction<IRequestFailed>) {
      state.parentCategory.status = RequestStatus.ERROR;
      state.parentCategory.error = action.payload;
    },
    //

    addCategory(state, action: PayloadAction<IAddCategorySchema>) {
      state.addCategory.status = RequestStatus.RUNNING;
    },
    addCategorySuccess(state, action: PayloadAction<ICategory>) {
      state.addCategory.status = RequestStatus.SUCCESS;
      state.addCategory.data = action.payload;
    },
    addCategoryFailed(state, action: PayloadAction<IRequestFailed>) {
      state.addCategory.status = RequestStatus.ERROR;
      state.addCategory.error = action.payload;
    },
    //

    addParentCategory(state, action: PayloadAction<IAddParentCategory>) {
      state.addParentCategory.status = RequestStatus.RUNNING;
    },
    addParentCategorySuccess(state, action: PayloadAction<ICategory>) {
      state.addParentCategory.status = RequestStatus.SUCCESS;
      state.addParentCategory.data = action.payload;
    },
    addParentCategoryFailed(state, action: PayloadAction<IRequestFailed>) {
      state.addParentCategory.status = RequestStatus.ERROR;
      state.addParentCategory.error = action.payload;
    },

    //
    updateTopCategory(state, action: PayloadAction<{ id: number }>) {
      state.activeTopCategory.status = RequestStatus.RUNNING;
    },
    updateTopCategorySuccess(state, action: PayloadAction<ICategory>) {
      state.activeTopCategory.status = RequestStatus.SUCCESS;
      state.activeTopCategory.data = action.payload;
    },
    updateTopCategoryFailed(state, action: PayloadAction<IRequestFailed>) {
      state.activeTopCategory.status = RequestStatus.ERROR;
      state.activeTopCategory.error = action.payload;
    },

    //
    updateCategoryStatus(state, action: PayloadAction<{ id: number }>) {
      state.updateCategoryStatus.status = RequestStatus.RUNNING;
    },
    updateCategoryStatusSuccess(state, action: PayloadAction<IRequestStatus>) {
      state.updateCategoryStatus.status = RequestStatus.SUCCESS;
      state.updateCategoryStatus.data = action.payload;
    },
    updateCategoryStatusFailed(state, action: PayloadAction<IRequestFailed>) {
      state.updateCategoryStatus.status = RequestStatus.ERROR;
      state.updateCategoryStatus.error = action.payload;
    },
  },
});
export default slice.reducer;

export const {
  getCategory,
  getCategorySuccess,
  getCategoryFailed,
  addCategory,
  addCategorySuccess,
  addCategoryFailed,
  addParentCategory,
  addParentCategorySuccess,
  addParentCategoryFailed,
  updateTopCategory,
  updateTopCategorySuccess,
  updateTopCategoryFailed,
  getParentCategory,
  getParentCategoryFailed,
  getParentCategorySuccess,
  updateCategoryStatus,
  updateCategoryStatusFailed,
  updateCategoryStatusSuccess,
  getCategoryDetail,
  getCategoryDetailFailed,
  getCategoryDetailSuccess,
} = slice.actions;
