import * as Yup from 'yup';

import { Strings } from '@app/constants';

export const addServiceSchema = Yup.object()
  .shape({
    serviceName: Yup.string()
      .trim()
      .required(Strings.validation.fieldRequired(Strings.field.serviceName)),
    sku: Yup.string().trim().required(Strings.validation.fieldRequired(Strings.field.sku)),
    price: Yup.string().trim().required(Strings.validation.fieldRequired(Strings.field.price)),
    status: Yup.string().trim().required(Strings.validation.selectRequired(Strings.field.status)),
    category: Yup.string()
      .trim()
      .required(Strings.validation.selectRequired(Strings.field.category)),
    serviceImage: Yup.string().required(
      Strings.validation.uploadRequired(Strings.field.serviceImage)
    ),
  })
  .required();
