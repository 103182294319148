import * as Yup from 'yup';

import { Strings } from '@app/constants';

// ---------------LoginSchema-------------
export const loginSchema = Yup.object()
  .shape({
    email: Yup.string()
      .required(Strings.validation.fieldRequired(Strings.field.email))
      .email(Strings.validation.invalidEmail),
    password: Yup.string().required(Strings.validation.fieldRequired(Strings.field.password)),
  })
  .required();

// ------------ForgetPassword-Schema-----------
export const forgotPasswordSchema = Yup.object()
  .shape({
    email: Yup.string()
      .required(Strings.validation.fieldRequired(Strings.field.email))
      .email(Strings.validation.invalidEmail),
  })
  .required();

// ---------------SetPasswordSchema-------------
export const setPasswordSchema = Yup.object()
  .shape({
    password: Yup.string()
      .trim()
      .required(Strings.validation.fieldRequired(Strings.field.password))
      .min(8)
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&^_-]{8,}$/,
        Strings.validation.passwordMustContain
      ),
    confirmPassword: Yup.string()
      .required(Strings.validation.fieldRequired(Strings.field.confirmPassword))
      .min(8)
      .trim()
      .oneOf([Yup.ref('password'), 'null'], Strings.validation.passwordNotMatch),
  })
  .required();
