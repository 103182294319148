import { Stack, Typography } from '@mui/material';

import { TruncatedTypography } from '@app/components/typography';
import { CustomAvatar } from '@app/components/custom-avatar';
import { TableCellCustom } from '@app/components/table';

interface Props {
  row?: any;
  selected: boolean;
  onSelectRow: VoidFunction;
}

const OrderDetailRow = (props: Props) => {
  const { row, onSelectRow } = props;

  return (
    <>
      <TableCellCustom onClick={onSelectRow} sx={{ width: '30%', padding: '2% 0% 2% 4%' }}>
        <Stack flexDirection="row" gap={2} alignItems="center">
          <CustomAvatar name={row?.sku?.name} color="primary" src={row?.sku?.images[0]} />
          <Stack>
            <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
              <TruncatedTypography text={row?.sku?.name ?? 'NA'} />
            </Typography>
            <Typography variant="body2" sx={{ color: '#F39200' }}>
              {row?.sku.skuId}
            </Typography>
          </Stack>
        </Stack>
      </TableCellCustom>
      <TableCellCustom flex align="center" fullWidth>
        <TruncatedTypography text={row?.sku?.desc || 'NA'} />
      </TableCellCustom>
      <TableCellCustom flex align="center">
        <Typography variant="body2">{row?.quantity ?? 'NA'}</Typography>
      </TableCellCustom>
      <TableCellCustom flex align="center" onClick={onSelectRow}>
        <Typography variant="body2">{row?.ourPrice ?? 'NA'}</Typography>
      </TableCellCustom>
      <TableCellCustom flex align="center" onClick={onSelectRow}>
        <Typography variant="body2">{row?.ourPrice - row?.discountPrice ?? 'NA'}</Typography>
      </TableCellCustom>
      <TableCellCustom flex align="center" onClick={onSelectRow}>
        <Typography variant="body2">{row?.discountPrice ?? 'NA'}</Typography>
      </TableCellCustom>
      <TableCellCustom flex align="center" onClick={onSelectRow}>
        <Typography variant="body2">{row?.quantity * row?.discountPrice ?? 'NA'}</Typography>
      </TableCellCustom>
    </>
  );
};

export { OrderDetailRow };
