import { useForm } from 'react-hook-form';
import { useCallback, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Divider,
  Stack,
  DialogActions,
  Dialog,
  Button,
  DialogContent,
  MenuItem,
} from '@mui/material';
import { get } from 'lodash';

import { Strings } from '@app/constants';
import { Header } from '@app/sections/header';
import { FormProvider, RHFTextField, RHFUploadAvatar } from '@app/components';
import { addSlotManagementSchema } from '../validators';
import { IAddSlotManagement, ISlotManagement } from '../types';

interface Props {
  open: boolean;
  onClose: VoidFunction;
  row?: ISlotManagement;
}

const AddSlotManagement = ({ open, onClose, row }: Props) => {
  const [isImageUploaded, setIsImageUploaded] = useState(false);

  const methods = useForm<IAddSlotManagement>({
    resolver: yupResolver(addSlotManagementSchema),
    defaultValues: {
      serviceName: get(row, 'name', ''),
      status: get(row, 'status', ''),
      serviceImage: get(row, 'image', ''),
      sku: get(row, 'sku', ''),
      price: get(row, 'price', ''),
      category: get(row, 'category', ''),
    },
  });

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        methods.setValue('serviceImage', newFile, { shouldValidate: true });
        setIsImageUploaded(true);
      }
    },
    [methods]
  );

  const onSubmit = (form: IAddSlotManagement) => {
    onClose();
  };

  return (
    <FormProvider methods={methods}>
      <Dialog open={open} fullWidth>
        <Header variant="dialog" title={Strings.pageTitle.addSlot} onClose={onClose} />
        <Divider />
        99999
        <DialogContent>
          <Stack spacing={2} my={2}>
            <RHFTextField
              name="serviceName"
              label={`${Strings.field.serviceName}*`}
              variant="outlined"
            />
            <RHFTextField name="sku" label={`${Strings.field.sku}*`} variant="outlined" />
            <RHFTextField name="price" label={`${Strings.field.price}*`} variant="outlined" />

            <RHFTextField
              name="category"
              required
              label={Strings.field.category}
              variant="outlined"
              select
            >
              <MenuItem value={'Washing'}>Washing</MenuItem>
              <MenuItem value={'Grooming'}>Grooming</MenuItem>
            </RHFTextField>
            <RHFTextField
              name="status"
              required
              label={Strings.field.status}
              variant="outlined"
              select
            >
              <MenuItem value={'Active'}>Active</MenuItem>
              <MenuItem value={'InActive'}>InActive</MenuItem>
            </RHFTextField>

            <RHFUploadAvatar
              name={'serviceImage'}
              onDrop={handleDrop}
              sx={{
                borderRadius: '10px',
                width: '100%',
                height: 115,
                border: 'none',
              }}
              placeholderSx={{
                borderRadius: '10px',
                width: '100%',
                height: '100%',
                border: isImageUploaded || row?.image ? 'none' : '5px dashed rgba(0, 0, 0, 0.08)',
              }}
            />
          </Stack>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button variant="outlined" color="inherit" onClick={onClose}>
            {Strings.button.cancel}
          </Button>
          <Button variant="contained" onClick={methods.handleSubmit(onSubmit)}>
            {Strings.button.save}
          </Button>
        </DialogActions>
      </Dialog>
    </FormProvider>
  );
};

export default AddSlotManagement;
