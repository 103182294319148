import { Stack } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { DetailCard } from '@app/components/detail-card';
import { Header } from '@app/sections/header';
import { Strings } from '@app/constants';
import { useDialog, useRequestState } from '@app/hooks';
import AddSku from '@app/modules/sku/view/add-sku';
import { selectSkuDetailState } from '@app/modules/sku/selectors';
import { getSkuDetail } from '@app/modules/sku/slice';

const SkuDetailPage = () => {
  const editDialog = useDialog();
  const dispatch = useDispatch();

  const { state } = useLocation();

  const { data } = useRequestState({
    stateSelector: selectSkuDetailState,
    errorShown: true,
  });

  useEffect(() => {
    dispatch(getSkuDetail({ id: state.id }));
  }, [dispatch, state.id]);

  const handleBack = () => {
    window.history.back();
  };

  return (
    <>
      <Stack>
        <Header
          backButton
          title={Strings.pageTitle.skuDetail}
          onClickBack={() => {
            handleBack();
          }}
          actionButtonTitle={Strings.button.edit}
          onClickActionButton={editDialog.show}
        />
        <DetailCard detailPage={'sku'} details={data} />
      </Stack>
      <AddSku open={editDialog.visible} onClose={editDialog.hide} row={data} />
    </>
  );
};

export { SkuDetailPage };
