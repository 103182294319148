import { Stack, Typography } from '@mui/material';

import { Strings } from '@app/constants';
import { AuthLayout } from '@app/layouts';
import { ForgotPasswordForm } from './forgot-password-form';

// ------------------------------------------------

const ForgotPasswordPage = () => {
  return (
    <AuthLayout title={Strings.header.forgotPassword}>
      <Stack spacing={2}>
        <Typography variant="h3">{Strings.auth.forgotPasswordHeading}</Typography>
        <Typography variant="body1" color="text.secondary">
          {Strings.auth.enterYourOfficial}
        </Typography>
        <ForgotPasswordForm />
      </Stack>
    </AuthLayout>
  );
};

export { ForgotPasswordPage };
