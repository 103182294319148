import * as Yup from 'yup';

import { Strings } from '@app/constants';

// ---------------addProductSchema-------------
export const addProductSchema = Yup.object()
  .shape({
    name: Yup.string().required(Strings.validation.fieldRequired(Strings.field.name)),
    desc: Yup.string().required(Strings.validation.fieldRequired(Strings.field.description)),
    categoryId: Yup.mixed()
      .notOneOf([''], Strings.validation.fieldRequired(Strings.field.category))
      .required(Strings.validation.fieldRequired(Strings.field.category)),
    image: Yup.string().required(Strings.validation.uploadRequired(Strings.field.image)),
  })
  .required();
