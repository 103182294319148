import { all, call, put, takeLatest } from 'redux-saga/effects';

import { request } from '@app/services';
import {
  addAttribute,
  addAttributeFailed,
  addAttributeSuccess,
  getAttributes,
  getAttributesFailed,
  getAttributesSuccess,
} from './slice';
import * as endpoints from './endpoints';
import { setPageCount } from '../common';

// ----------------------------------------
function* getAttributesSaga(action: ReturnType<typeof getAttributes>): any {
  const { page, q } = action.payload;
  try {
    const result = yield call(request.get, endpoints.getAttribute, {
      params: {
        limit: 100,
        page: q ? 1 : page,
        q,
      },
    });
    yield put(getAttributesSuccess(result.data.attribute));
    yield put(setPageCount({ id: action.type, count: result.data.total }));
  } catch (err: any) {
    yield put(getAttributesFailed(err));
  }
}

// ----------------------------------------

function* addAttributeSaga(action: ReturnType<typeof addAttribute>): any {
  try {
    const { id, name } = action.payload;

    let result;
    if (id) {
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      result = yield call(request.put, endpoints.updateAttribute + id, {
        id,
        name,
      });
    } else {
      result = yield call(request.post, endpoints.addAttribute, {
        name,
      });
    }
    yield put(addAttributeSuccess(result.data));
  } catch (err: any) {
    yield put(addAttributeFailed(err));
  }
}

// ----------------------------------------

function* attributeSagas() {
  yield all([
    takeLatest([getAttributes, addAttributeSuccess], getAttributesSaga),
    takeLatest(addAttribute, addAttributeSaga),
  ]);
}

export { attributeSagas };
