import { ReactElement } from 'react';
import { Table, TableRow, TableBody, TableContainer, LinearProgress, SxProps } from '@mui/material';
import { Theme } from '@mui/material/styles';
import _ from 'lodash';

import {
  TableHeadCustom,
  TablePaginationCustom,
  TableProps,
  TableNoData,
  TableCellCustom,
} from '@app/components/table';

// ----------------------------------------------------------------------

type RowDataType<TRowDataType> = TRowDataType & {
  id: any;
};

// ----------------------------------------------------------------------
interface Props<TRowDataType> {
  children: (row: RowDataType<TRowDataType>) => ReactElement;
  data?: Array<RowDataType<TRowDataType>>;
  head: Array<{
    id: string;
    label?: string;
    align?: string;
  }>;
  onToggleSelectAll?: (checked: boolean, newSelecteds: string[]) => void;
  tableProps: TableProps;
  loading?: boolean;
  totalCount?: number;
  isServerSidePaging?: boolean;
  paginationShown?: boolean;
  sx?: SxProps<Theme>;
}

export default function PaginatedTable<TRowDataType>({
  data: records = [],
  head,
  children,
  onToggleSelectAll,
  tableProps,
  loading,
  totalCount,
  isServerSidePaging = true,
  paginationShown = true,
  sx,
}: Props<TRowDataType>) {
  const {
    selected,
    page: tablePage,
    rowsPerPage,
    orderBy,
    order,
    onChangeRowsPerPage,
    onChangePage,
    onSort,
  } = tableProps;

  const page = tablePage - 1;

  let data = records;
  if (!isServerSidePaging) {
    data = _.orderBy(records, [(item: any) => item[orderBy]?.toLowerCase()], [order]);
  }

  return (
    <>
      <TableContainer sx={{ position: 'relative', maxHeight: '100%' }}>
        <Table stickyHeader size="small" sx={{ minWidth: 300, ...sx }}>
          <TableHeadCustom
            order={order}
            orderBy={orderBy}
            headLabel={head}
            rowCount={data.length}
            numSelected={selected.length}
            onSort={onSort}
            onSelectAllRows={
              onToggleSelectAll
                ? (checked) => {
                    onToggleSelectAll(
                      checked,
                      data.map((row) => row.id)
                    );
                  }
                : undefined
            }
          />

          <TableBody>
            {loading && (
              <TableRow>
                <TableCellCustom
                  sx={{
                    position: 'sticky',
                    top: 39,
                  }}
                  colSpan={head.length + 1}
                  padding="none"
                >
                  <LinearProgress />
                </TableCellCustom>
              </TableRow>
            )}
            {!isServerSidePaging &&
              data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                <TableRow hover key={`${row.id as number}`}>
                  {children(row)}
                </TableRow>
              ))}

            {isServerSidePaging &&
              data.map((row) => (
                <TableRow hover key={`${row.id as number}`}>
                  {children(row)}
                </TableRow>
              ))}

            {!loading && data.length === 0 && <TableNoData />}
          </TableBody>
        </Table>
      </TableContainer>

      {paginationShown && (
        <TablePaginationCustom
          count={totalCount ?? data.length}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
          //
        />
      )}
    </>
  );
}

// ----------------------------------------------------------------------
