import { Checkbox, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';

import { TableCellCustom } from '@app/components/table';
import { RowActions } from './row-actions';
import { IAttribute } from '../types';

interface Props {
  row: IAttribute;
  selected: boolean;
  onSelectRow: (id: number) => void;
}
const Row = (props: Props) => {
  const { row, selected, onSelectRow } = props;

  const location = useLocation();
  const currentPathname = location.pathname;

  return (
    <>
      <TableCellCustom>
        {currentPathname === '/dashboard/attribute' ? (
          <Typography variant="body2">{row.id}</Typography>
        ) : (
          <Checkbox
            checked={selected}
            onChange={() => {
              onSelectRow(row.id);
            }}
          />
        )}
      </TableCellCustom>

      <TableCellCustom flex>
        <Typography variant="body2">{row.name}</Typography>
      </TableCellCustom>

      <TableCellCustom align="right" width={50}>
        <RowActions row={row} />
      </TableCellCustom>
    </>
  );
};

export { Row };
