import {
  Dialog,
  Button,
  DialogTitle,
  DialogActions,
  DialogContent,
  Stack,
  IconButton,
} from '@mui/material';

import { Strings } from '@app/constants';
import { Iconify } from '../iconify';
import { ConfirmDialogProps } from './types';

// ----------------------------------------------------------------------

export default function ConfirmDialog({
  title,
  content,
  action,
  open,
  onClose,
  ...other
}: ConfirmDialogProps) {
  return (
    <Dialog open={open} onClose={onClose} {...other}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <DialogTitle variant="h6" sx={{ m: 0, p: 2 }}>
          {title}
        </DialogTitle>

        {onClose ? (
          <IconButton sx={{ height: 'fit-content', p: 2 }} onClick={onClose}>
            <Iconify icon="material-symbols:close" />
          </IconButton>
        ) : null}
      </Stack>

      {content && (
        <DialogContent dividers sx={{ typography: 'body1', p: 2, maxWidth: 550 }}>
          {content}
        </DialogContent>
      )}

      <DialogActions>
        <Button variant="outlined" color="inherit" onClick={onClose}>
          {Strings.button.cancel}
        </Button>
        {action}
      </DialogActions>
    </Dialog>
  );
}
