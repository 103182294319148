import { type ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { bootstrapApp } from '../slice';
import { selectIsAppReady } from '../selectors';

interface Props {
  children: ReactElement;
}

const BootstrapGate = ({ children }: Props) => {
  const dispatch = useDispatch();

  const isAppReady = useSelector(selectIsAppReady);

  useEffect(() => {
    dispatch(bootstrapApp());
  }, [dispatch]);

  if (!isAppReady) {
    return null;
  }

  return children;
};

export { BootstrapGate };
