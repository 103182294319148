import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Stack, InputAdornment, IconButton } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router';

import { PATH_AUTH } from '@app/routes/paths';
import { FormProvider, Iconify, RHFTextField } from '@app/components';
import { useRequestState } from '@app/hooks';
import { Strings } from '@app/constants';
import type { ISetPasswordSchema } from '../types';
import { setPasswordSchema } from '../validators';
import { setPassword } from '../slice';
import { selectSetPasswordState } from '../selectors';

// --------------------------------------------

const SetPasswordForm = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const onClickEyeIcon = () => {
    setShowPassword(!showPassword);
  };

  const onClickEyeIcon2 = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const methods = useForm<ISetPasswordSchema>({
    resolver: yupResolver(setPasswordSchema),
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  });

  const onSubmit = (form: ISetPasswordSchema) => {
    dispatch(setPassword({ ...form, token: searchParams.get('token') ?? '' }));
  };

  const navigateToLogin = useCallback(() => {
    navigate(PATH_AUTH.login);
  }, [navigate]);

  const { loading } = useRequestState({
    stateSelector: selectSetPasswordState,
    errorShown: true,
    successMessageShown: true,
    onSuccess: navigateToLogin,
  });

  return (
    <FormProvider methods={methods} onSubmit={methods.handleSubmit(onSubmit)}>
      <Stack spacing={2}>
        <RHFTextField
          name="password"
          label={Strings.field.password}
          fullWidth
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={onClickEyeIcon} tabIndex={-1}>
                  {showPassword ? <Iconify icon="mdi:eye" /> : <Iconify icon="mdi:eye-off" />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <RHFTextField
          name="confirmPassword"
          label={Strings.field.confirmPassword}
          fullWidth
          type={showConfirmPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={onClickEyeIcon2} tabIndex={-1}>
                  {showConfirmPassword ? (
                    <Iconify icon="mdi:eye" />
                  ) : (
                    <Iconify icon="mdi:eye-off" />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <LoadingButton
          size="large"
          variant="contained"
          color="secondary"
          fullWidth
          type="submit"
          loading={loading}
        >
          {Strings.button.setPassword}
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
};

export { SetPasswordForm };
