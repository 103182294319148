import React from 'react';
import { Typography, TypographyProps } from '@mui/material';

interface TruncatedTypographyProps extends TypographyProps {
  text: string | number;
  lines?: number;
  height?: string | number;
}

const TruncatedTypography: React.FC<TruncatedTypographyProps> = ({
  text,
  variant = 'body2',
  lines = 1,
  height = 20,
  ...rest
}) => {
  return (
    <Typography
      variant={variant}
      {...rest}
      sx={{
        height,
        overflow: 'hidden',
        display: '-webkit-box',
        WebkitLineClamp: lines,
        WebkitBoxOrient: 'vertical',
        textOverflow: 'ellipsis',
      }}
    >
      {text}
    </Typography>
  );
};

export { TruncatedTypography };
