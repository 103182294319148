import { RootState } from '@app/redux/root-reducer';

export const selectCategoryState = (store: RootState) => store.categoryReducer.category;
export const selectCategoryDetailState = (store: RootState) => store.categoryReducer.categoryDetail;
export const selectParentCategory = (store: RootState) => store.categoryReducer.parentCategory;
export const selectAddCategoryState = (store: RootState) => store.categoryReducer.addCategory;
export const selectAddParentCategoryState = (store: RootState) =>
  store.categoryReducer.addParentCategory;
export const selectUpdateTopCategoryState = (store: RootState) =>
  store.categoryReducer.activeTopCategory;
export const selectUpdateCategoryStatusState = (store: RootState) =>
  store.categoryReducer.updateCategoryStatus;
