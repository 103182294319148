import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { RequestStatus } from '@app/constants';
import { IRequestFailed } from '@app/types';
import { IAddProductSchema, IProduct, IProductState } from './types';

const initialState: IProductState = {
  products: {},
  addProduct: {},
  productDetail: {},
};

const slice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    getProducts(state, action: PayloadAction<{ page: number; q?: any }>) {
      state.products.status = RequestStatus.RUNNING;
    },
    getProductsSuccess(state, action: PayloadAction<IProductState['products']['data']>) {
      state.products.status = RequestStatus.SUCCESS;
      state.products.data = action.payload;
    },
    getProductsFailed(state, action: PayloadAction<IRequestFailed>) {
      state.products.status = RequestStatus.ERROR;
      state.products.error = action.payload;
    },
    //
    getProductDetail(state, action: PayloadAction<{ id: number }>) {
      state.productDetail.status = RequestStatus.RUNNING;
    },
    getProductDetailSuccess(state, action: PayloadAction<IProductState['products']['data']>) {
      state.productDetail.status = RequestStatus.SUCCESS;
      state.productDetail.data = action.payload;
    },
    getProductDetailFailed(state, action: PayloadAction<IRequestFailed>) {
      state.productDetail.status = RequestStatus.ERROR;
      state.productDetail.error = action.payload;
    },
    //

    addProduct(state, action: PayloadAction<IAddProductSchema>) {
      state.addProduct.status = RequestStatus.RUNNING;
    },
    addProductSuccess(state, action: PayloadAction<IProduct>) {
      state.addProduct.status = RequestStatus.SUCCESS;
      state.addProduct.data = action.payload;
    },
    addProductFailed(state, action: PayloadAction<IRequestFailed>) {
      state.addProduct.status = RequestStatus.ERROR;
      state.addProduct.error = action.payload;
    },
  },
});
export default slice.reducer;

export const {
  getProducts,
  getProductsSuccess,
  getProductsFailed,
  addProduct,
  addProductSuccess,
  addProductFailed,
  getProductDetail,
  getProductDetailFailed,
  getProductDetailSuccess,
} = slice.actions;
