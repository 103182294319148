import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { RequestStatus } from '@app/constants';
import { IRequestFailed } from '@app/types';
import { ICustomerState } from './types';

const initialState: ICustomerState = {
  customers: {},
};

const slice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    getCustomer(state, action: PayloadAction<{ page: number; q?: any }>) {
      state.customers.status = RequestStatus.RUNNING;
    },
    getCustomerSuccess(state, action: PayloadAction<ICustomerState['customers']['data']>) {
      state.customers.status = RequestStatus.SUCCESS;
      state.customers.data = action.payload;
    },
    getCustomerFailed(state, action: PayloadAction<IRequestFailed>) {
      state.customers.status = RequestStatus.ERROR;
      state.customers.error = action.payload;
    },
  },
});
export default slice.reducer;

export const { getCustomer, getCustomerSuccess, getCustomerFailed } = slice.actions;
