import { Typography } from '@mui/material';

import { Strings } from '@app/constants';
import { Logo } from '../logo';
import { StyledBackground } from './styles';

const SideIllustration = () => {
  return (
    <StyledBackground>
      <Logo disabledLink width={240} />
      <Typography
        textAlign="center"
        variant="h3"
        px={5}
        sx={{ color: (theme) => theme.palette.primary.contrastText }}
      >
        {Strings.common.developAutoResearch}
      </Typography>
    </StyledBackground>
  );
};

export { SideIllustration };
