import { Stack, Typography } from '@mui/material';

import { Strings } from '@app/constants';
import { AuthLayout } from '@app/layouts';
import { SetPasswordForm } from './set-password-form';

// ------------------------------------------------

const SetPasswordPage = () => {
  return (
    <AuthLayout title={Strings.header.setPassword}>
      <Stack spacing={2}>
        <Typography variant="h3">{Strings.auth.setYourPassword}</Typography>
        <Typography variant="body1" color="text.secondary">
          {Strings.auth.enterAPassword}
        </Typography>
        <SetPasswordForm />
      </Stack>
    </AuthLayout>
  );
};

export { SetPasswordPage };
