import { Stack } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { DetailCard } from '@app/components/detail-card';
import { Header } from '@app/sections/header';
import { Strings } from '@app/constants';
import { useDialog, useRequestState, useSearch } from '@app/hooks';
import { useTable } from '@app/components/table';
import { PATH_DASHBOARD } from '@app/routes/paths';
import { selectCollectionDetailState } from '../selectors';
import CollectionDetailList from './collection-detail-list';
import AddProductCollectionDetail from './add-product-collection-detail';
import AddCollectionInImport from './add-collection-in-import';
import { getCollectionDetail } from '../slice';

const CollectionDetailPage = () => {
  const navigation = useNavigate();
  let newState: any;
  const addDialog = useDialog();
  const addImport = useDialog();
  const tableProps = useTable();
  const dispatch = useDispatch();
  const { state } = useLocation();

  const handleBack = () => {
    navigation(PATH_DASHBOARD.collection);
  };

  const { data, loading } = useRequestState({
    stateSelector: selectCollectionDetailState,
    errorShown: true,
  });

  useEffect(() => {
    dispatch(getCollectionDetail(state.id));
  }, [dispatch, state.id]);

  if (data) {
    newState = { ...state, skuIds: data.map((item) => item.id).join(',') };
  } else {
    newState = { ...state };
  }

  const searchProps = useSearch({ data, fields: ['name'] });

  return (
    <>
      <Stack>
        <Header
          backButton
          title={Strings.pageTitle.collectionDetail}
          onClickBack={() => {
            handleBack();
          }}
          actionButtonTitle={Strings.button.addNew}
          actionButtonImport={Strings.button.import}
          onClickActionButton={addDialog.show}
          onClickActionButtonImport={addImport.show}
        />
        <DetailCard detailPage={'collection'} details={newState} />
        <Header
          searchBarProps={{
            placeholder: Strings.common.search,
            onChange: searchProps.handleSearchChange,
          }}
        />
        <CollectionDetailList
          tableProps={tableProps}
          loading={loading}
          collectionDetailId={state.id}
          rows={searchProps.filteredRows}
        />
      </Stack>
      {addDialog.visible && (
        <AddProductCollectionDetail
          open={addDialog.visible}
          onClose={addDialog.hide}
          collectionId={state.id}
        />
      )}
      {addImport.visible && (
        <AddCollectionInImport open onClose={addImport.hide} collectionId={state?.id} />
      )}
    </>
  );
};

export { CollectionDetailPage };
