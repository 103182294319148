import { MenuItem, Select, Stack, Typography } from '@mui/material';
import { useState } from 'react';

import { CustomAvatar } from '@app/components/custom-avatar';
import { TableCellCustom } from '@app/components/table';
import { IService } from '../types';
import { RowActions } from './row-actions';

interface Props {
  row: IService;
  selected: boolean;
  onSelectRow: VoidFunction;
}

const ServiceRow = (props: Props) => {
  const { row } = props;

  const [selectedValue, setSelectedValue] = useState('Active');

  const handleChange = (event: any) => {
    setSelectedValue(event.target.value);
  };

  return (
    <>
      <TableCellCustom>
        <Stack style={{ flexDirection: 'row', alignItems: 'center', gap: '9px' }}>
          <CustomAvatar name={row.name} color="primary" alt="pic" src={row.image} />
          <Typography variant="body2">{row.name}</Typography>
        </Stack>
      </TableCellCustom>

      <TableCellCustom>
        <Typography variant="body2">{row.sku}</Typography>
      </TableCellCustom>
      <TableCellCustom>
        <Typography variant="body2">{row.price}</Typography>
      </TableCellCustom>
      <TableCellCustom>
        <Typography variant="body2">{row.category}</Typography>
      </TableCellCustom>
      <TableCellCustom>
        <Select
          value={selectedValue}
          onChange={handleChange}
          sx={{ height: 25, width: 100, fontSize: 13 }}
        >
          <MenuItem value={'Active'}>Active</MenuItem>
          <MenuItem value={'Inactive'}>InActive</MenuItem>
        </Select>
      </TableCellCustom>
      <TableCellCustom align="right" width={50}>
        <RowActions row={row} />
      </TableCellCustom>
    </>
  );
};

export { ServiceRow };
