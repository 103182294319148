import * as Yup from 'yup';

import { Strings } from '@app/constants';

// ---------------addCollectionSchema-------------
export const addCollectionSchema = Yup.object()
  .shape({
    name: Yup.string().required(Strings.validation.fieldRequired(Strings.field.name)),
    description: Yup.string().required(Strings.validation.fieldRequired(Strings.field.description)),
    active: Yup.string().required(Strings.validation.selectRequired(Strings.field.type)),
  })
  .required();
