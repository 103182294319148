import { all, call, put, takeLatest } from 'redux-saga/effects';

import { request } from '@app/services';
import * as endpoints from './endpoints';
import {
  deleteCollectionDetailFailed,
  getCollectionDetail,
  getCollectionDetailFailed,
  getCollectionDetailSuccess,
  deleteCollectionDetail,
  getNewCollectionDetail,
  getNewCollectionDetailSuccess,
  getNewCollectionDetailFailed,
  addNewCollectionDetailProduct,
  addNewCollectionDetailProductFailed,
  addNewCollectionDetailProductSuccess,
  deleteCollectionDetailSuccess,
} from './slice';

// ----------------------------------------
function* getCollectionDetailSaga(action: ReturnType<typeof getCollectionDetail>): any {
  try {
    const { data } = yield call(request.get, endpoints.getCollectionDetail, {
      params: { collectionId: action.payload },
    });
    yield put(getCollectionDetailSuccess(data.data));
  } catch (err: any) {
    yield put(getCollectionDetailFailed(err));
  }
}

// ----------------------------------------

function* deleteCollectionDetailSaga(action: ReturnType<typeof deleteCollectionDetail>): any {
  try {
    const { id, skuId } = action.payload;

    const result = yield call(
      request.put,
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      `${endpoints.deleteCollectionDetail}${id}?skuId=${skuId}`
    );

    yield put(deleteCollectionDetailSuccess(result.data.message));
    yield put(getCollectionDetail(action.payload.id));
  } catch (err: any) {
    yield put(deleteCollectionDetailFailed(err));
  }
}
// ----------------------------------------

function* getNewCollectionDetailSaga(action: ReturnType<typeof getNewCollectionDetail>): any {
  const { page, q } = action.payload;
  try {
    const result = yield call(request.get, endpoints.getNewCollectionDetail, {
      params: {
        page: q ? 1 : page,
        limit: 100,
        q,
      },
    });

    yield put(getNewCollectionDetailSuccess(result?.data?.sku));
  } catch (err: any) {
    yield put(getNewCollectionDetailFailed(err));
  }
}

// ----------------------------------

function* addNewCollectionDetailProductSaga(
  action: ReturnType<typeof addNewCollectionDetailProduct>
): any {
  const { skuId, id } = action.payload;

  const skuIds = (skuId as unknown as string[]).map((id: string) => Number(id));

  try {
    const result = yield call(request.post, endpoints.addNewCollectionDetailProduct, {
      collectionId: id,
      skuIds,
    });
    if (result.data.status) {
      yield put(getCollectionDetail(id));
      yield put(addNewCollectionDetailProductSuccess(result.data.message));
    } else {
      yield put(addNewCollectionDetailProductFailed(result.data));
    }
  } catch (err: any) {
    yield put(addNewCollectionDetailProductFailed(err));
  }
}

// ----------------------------------------

function* collectionDetailSagas() {
  yield all([takeLatest([getCollectionDetail], getCollectionDetailSaga)]);
  yield all([takeLatest([deleteCollectionDetail], deleteCollectionDetailSaga)]);
  yield all([takeLatest([getNewCollectionDetail], getNewCollectionDetailSaga)]);
  yield all([takeLatest([addNewCollectionDetailProduct], addNewCollectionDetailProductSaga)]);
}

export { collectionDetailSagas };
