import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
const serializeCOloumn = (coloumns: any) => {
  const pdfColoumn = coloumns.map((elem: any, index: any) => {
    return { dataKey: elem.key, header: elem.label };
  });
  return pdfColoumn;
};
export const ConvertTOPdf = (data: any, columns: any, orientation?: string) => {
  // eslint-disable-next-line new-cap
  const doc = new jsPDF(orientation === 'landscape' ? 'l' : 'p');
  autoTable(doc, { html: '#my-table' });
  autoTable(doc, {
    columnStyles: {
      europe: { halign: 'center' },
      id: { columnWidth: 10 },
      skuId: { columnWidth: 20 },
      name: { columnWidth: 30 },
      products: { columnWidth: 100 },
      productsName: { columnWidth: 20 },
      desc: { columnWidth: 80 },
      skuName: { columnWidth: 27 },
    } as any,
    body: data,
    columns: serializeCOloumn(columns),
  });
  doc.save();
};
