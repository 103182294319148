import { IconButton, Stack, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';

import { TableCellCustom } from '@app/components/table';
import { CustomAvatar } from '@app/components/custom-avatar';
import { IMAGE_BASE_URL } from '@app/configs';
import { Iconify } from '@app/components';
import { ICollectionDetail } from '../types';
import { deleteCollectionDetail } from '../slice';
import { TruncatedTypography } from '@app/components/typography';
import { useRequestState } from '@app/hooks';
import { selectDeleteCollectionDetailState } from '../selectors';

interface Props {
  row: ICollectionDetail;
  selected: boolean;
  onSelectRow: VoidFunction;
  collectionDetailId: number;
}

const CollectionDetailRow = (props: Props) => {
  const { row, onSelectRow } = props;
  const dispatch = useDispatch();

  const handleDeleteRow = () => {
    dispatch(deleteCollectionDetail({ skuId: row?.id, id: props.collectionDetailId }));
  };

  useRequestState({
    stateSelector: selectDeleteCollectionDetailState,
    successMessageShown: true,
    errorShown: true,
  });

  return (
    <>
      <TableCellCustom onClick={onSelectRow}>
        <Stack style={{ flexDirection: 'row', alignItems: 'center', gap: '9px' }}>
          <CustomAvatar
            name={row?.name}
            color="primary"
            alt="pic"
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            src={IMAGE_BASE_URL + row.productImage || row.images}
          />
        </Stack>
      </TableCellCustom>
      <TableCellCustom flex align="center" onClick={onSelectRow}>
        <Typography variant="body2">{row?.skuId}</Typography>
      </TableCellCustom>
      <TableCellCustom flex align="center" onClick={onSelectRow}>
        <TruncatedTypography text={row?.desc || 'NA'} height={20} lines={1} />
      </TableCellCustom>

      <TableCellCustom flex align="center" onClick={onSelectRow}>
        {row?.skuAttributes?.length
          ? row.skuAttributes?.map((skuAtt) => (
              <Stack key={skuAtt.id} flexDirection={'row'} justifyContent={'center'}>
                <Stack flex={1}>
                  <TruncatedTypography
                    text={`${skuAtt.attribute.name}:`}
                    height={20}
                    lines={1}
                    variant="subtitle2"
                  />
                </Stack>
                <Stack justifySelf={'start'} flex={1}>
                  <TruncatedTypography
                    text={`${skuAtt.attributeValue}`}
                    height={20}
                    lines={1}
                    variant="body2"
                  />
                </Stack>
              </Stack>
            ))
          : 'NA'}
      </TableCellCustom>

      <TableCellCustom align="center" width={50}>
        <IconButton sx={{ p: 0.25, borderRadius: '5px' }} onClick={handleDeleteRow}>
          <Iconify icon="mdi:minus-box" width={25} color="#F39200" />
        </IconButton>
      </TableCellCustom>
    </>
  );
};

export { CollectionDetailRow };
