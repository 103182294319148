import { RootState } from '@app/redux/root-reducer';

export const selectCollectionDetailState = (store: RootState) =>
  store.collectionDetailReducer.collectionDetail;
export const selectDeleteCollectionDetailState = (store: RootState) =>
  store.collectionDetailReducer.deleteCollectionDetail;
export const selectNewCollectionDetailState = (store: RootState) =>
  store.collectionDetailReducer.getNewCollectionDetail;
export const selectAddNewCollectionProductState = (store: RootState) =>
  store.collectionDetailReducer.addNewCollection;
