import { IProduct } from '@app/modules/order-detail';

export const isActiveSlot = (items: any) => {
  const activeSlot = items?.find(
    (slot: { CurrentDeliverySlot: boolean }) => slot.CurrentDeliverySlot
  );
  return activeSlot;
};

export const calculateTotalPrice = (items: IProduct[]) => {
  // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
  return items?.reduce((total, item) => total + item?.ourPrice * item?.quantity, 0);
};

export const calculateTotalDiscount = (items: IProduct[]) => {
  const discountPerItem = items?.reduce(
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    (total, item) => total + (item?.ourPrice - item?.discountPrice) * item?.quantity,
    0
  );
  return discountPerItem;
};
