import { Stack, Typography } from '@mui/material';

import { Strings } from '@app/constants';
import { AuthLayout } from '@app/layouts';
import { LoginForm } from './login-form';

// ------------------------------------------------

const LoginPage = () => {
  return (
    <AuthLayout title={Strings.header.login}>
      <Stack spacing={2}>
        <Typography variant="h3">{Strings.auth.signIn}</Typography>
        <Typography variant="body1" color="text.secondary">
          {Strings.auth.signInYour}
        </Typography>
        <LoginForm />
      </Stack>
    </AuthLayout>
  );
};

export { LoginPage };
