import TableCell, { TableCellProps } from '@mui/material/TableCell';

interface props extends TableCellProps {
  width?: string | number;
  flex?: boolean;
  fullWidth?: boolean;
}

const TableCellCustom = ({ width, fullWidth, flex, ...rest }: props) => {
  return (
    <TableCell
      style={{
        width: flex ? 'auto' : width,
        flex: flex ? 1 : 'unset',
        maxWidth: fullWidth ? '' : '120px',
        overflow: 'hidden',
      }}
      {...rest}
      height={20}
    />
  );
};

export default TableCellCustom;
