import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Header } from '@app/sections/header';
import { Strings } from '@app/constants';
import { useTable } from '@app/components/table';
import ProductList from './product-list';
import { useDialog, useRequestState } from '@app/hooks';
import AddProduct from './add-product';
import { selectProductsState } from '../selectors';
import { getProducts } from '../slice';
import { usePageCount } from '@app/hooks/use-page-count';

const TABLE_HEAD_CSV = [
  { key: 'id', label: 'id', align: 'left' },
  { key: 'name', label: 'Name', align: 'left' },
  { key: 'category', label: 'Category ID', align: 'left' },
  { key: 'categoryName', label: 'Category Name', align: 'left' },
  { key: 'description', label: 'Description', align: 'left', sortable: false },
  { key: 'active', label: 'Status', align: 'left', sortable: false },
];

const ProductListPage = () => {
  const dispatch = useDispatch();
  const tableProps = useTable();
  const productDialog = useDialog();
  const [query, setQuery] = useState('');
  const { count } = usePageCount(getProducts);
  const page = tableProps.page;

  useEffect(() => {
    if (query.length !== 0) {
      dispatch(getProducts({ page, q: query }));
    } else {
      dispatch(getProducts({ page }));
    }
  }, [dispatch, page, query]);

  const handleSearchChange = (event: any) => {
    setQuery(event.target.value);
  };

  const { data, loading } = useRequestState({
    stateSelector: selectProductsState,
    errorShown: true,
  });

  const flattenAry = (data: any) => {
    const value = data?.map((elem: any) => {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      return `${elem?.name}:${elem?.desc}`;
    });
    return value.join('; ');
  };

  const flattenData = (data: any) => {
    const serializeData = data?.map((elem: any) => {
      return {
        ...elem,
        skus: flattenAry(elem.skus),
        category: elem?.categoryId,
        categoryName: elem.category?.name,
      };
    });
    return serializeData;
  };
  return (
    <>
      <Header
        title={Strings.pageTitle.products}
        helperText={''}
        actionButtonTitle={Strings.button.addProduct}
        onClickActionButton={productDialog.show}
        searchBarProps={{
          placeholder: Strings.common.search,
          onChange: handleSearchChange,
        }}
        data={flattenData(data)}
        csvHead={TABLE_HEAD_CSV}
        csvFileName={'product-list.csv'}
        additionalExportButtonTitle={Strings.button.exportCsv}
        additionalExportPdfButtonTitle={Strings.button.exportpdf}
      />
      <ProductList loading={loading} tableProps={tableProps} rows={data} count={count} />
      {productDialog.visible && <AddProduct open onClose={productDialog.hide} />}
    </>
  );
};

export { ProductListPage };
