import { Checkbox, Stack } from '@mui/material';

import { TableCellCustom } from '@app/components/table';
import { IProduct } from '../types';
import { CustomAvatar } from '@app/components/custom-avatar';
import { IMAGE_BASE_URL } from '@app/configs';
import { TruncatedTypography } from '@app/components/typography';

interface Props {
  row: IProduct;
  selected: boolean;
  onSelectRow: VoidFunction;
}

const ProductRow = (props: Props) => {
  const { row, onSelectRow } = props;

  return (
    <>
      <TableCellCustom flex onClick={onSelectRow}>
        <TruncatedTypography text={row.id ?? 'NA'} height={20} lines={1} />
      </TableCellCustom>
      <TableCellCustom onClick={onSelectRow}>
        <Stack
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            gap: '9px',
          }}
        >
          <CustomAvatar
            name={row.name}
            color="primary"
            alt="pic"
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            src={IMAGE_BASE_URL + row.images}
          />
          {/* <Typography variant="body2">{row?.name}</Typography> */}
          <TruncatedTypography text={row?.name ?? 'NA'} height={20} lines={1} />
        </Stack>
      </TableCellCustom>
      <TableCellCustom flex onClick={onSelectRow} align="center">
        <TruncatedTypography text={row.categoryId ?? 'NA'} height={20} lines={1} />
      </TableCellCustom>
      <TableCellCustom flex onClick={onSelectRow} align="center">
        <TruncatedTypography text={row.category?.name ?? 'NA'} height={20} lines={1} />
      </TableCellCustom>
      <TableCellCustom flex onClick={onSelectRow} align="center">
        <TruncatedTypography text={row.description ?? 'NA'} height={20} lines={1} />
      </TableCellCustom>
      <TableCellCustom flex align="center">
        <Checkbox checked readOnly disabled={!row.active} />
      </TableCellCustom>
    </>
  );
};

export { ProductRow };
