import * as Yup from 'yup';

import { Strings } from '@app/constants';

// ---------------addskuSchema-------------
export const addSkuSchema = Yup.object()
  .shape({
    productId: Yup.mixed()
      .notOneOf([''], Strings.validation.fieldRequired(Strings.field.product))
      .required(Strings.validation.fieldRequired(Strings.field.product)),
    desc: Yup.string().required(Strings.validation.fieldRequired(Strings.field.description)),
    name: Yup.string(),
  })
  .required();
