import { Stack, IconButton, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers';

import InventoryEditList from './inventory-edit-list';
import { Header } from '@app/sections/header';
import { useTable } from '@app/components/table';
import { getInventoryTransaction } from '../slice';
import { selectInventoryTransactionState } from '../selectors';
import { useRequestState } from '@app/hooks';
import { Strings } from '@app/constants';
import { Iconify } from '@app/components';

interface InventoryProps {
  id: number;
}

const InventoryEditListPage = ({ id }: InventoryProps) => {
  const dispatch = useDispatch();
  const tableProps = useTable();

  const TABLE_HEAD_CSV = [
    { key: 'id', label: 'Transaction Id', align: 'left' },
    { key: 'operation', label: 'Operation', align: 'left' },
    { key: 'reason', label: 'Reason', align: 'left' },
    { key: 'lastStock', label: 'Stock', align: 'left' },
    { key: 'qtyIn', label: 'Qty In', align: 'left' },
    { key: 'qtyOut', label: 'Qty Out', align: 'left' },
    { key: 'stockUpdated', label: 'Updated Stock', align: 'left' },
    { key: 'timeStamp', label: 'Time Stamp', align: 'left' },
  ];

  const page = tableProps.page;
  const theme = useTheme();

  const [query, setQuery] = useState('');
  const [showFilter, setShowFilter] = useState(false);
  const [dateValue, setDateValue] = useState<Date | undefined | null>(undefined);

  const { data, loading } = useRequestState({
    stateSelector: selectInventoryTransactionState,
    errorShown: true,
  });

  const filteredData = data?.filter((item: any) => {
    return dateValue
      ? dayjs(item.time).format('YYYY-MM-DD') === dayjs(dateValue).format('YYYY-MM-DD')
      : '';
  });

  const handleClearFilter = () => {
    setDateValue(null);
  };

  const handleSearchChange = (event: any) => {
    setQuery(event.target.value);
  };
  const handleFilter = () => {
    setShowFilter(!showFilter);
  };

  useEffect(() => {
    if (query.length !== 0) {
      dispatch(getInventoryTransaction({ id, q: query }));
    } else {
      dispatch(getInventoryTransaction({ id }));
    }
  }, [dispatch, id, page, query]);

  useEffect(() => {
    dispatch(getInventoryTransaction({ id }));
  }, [dispatch, id]);

  const flattenData = (data: any) => {
    const serializeData = data?.map((elem: any) => {
      return {
        ...elem,
        lastStock: elem.lastStock ? elem.lastStock : 'N/A',
        qtyIn: elem?.operation === 'add' ? elem?.stockUpdated : 'NA',
        qtyOut: elem?.operation === 'remove' ? elem?.stockUpdated : 'NA',
        timeStamp: dayjs(elem?.time).format('YYYY-MM-DD HH:mm:ss'),
      };
    });
    return serializeData;
  };

  return (
    <Stack>
      <Header
        filter
        onClickFilter={() => {
          handleFilter();
        }}
        csvHead={TABLE_HEAD_CSV}
        csvFileName={'inventory-transactions.csv'}
        data={flattenData(data)}
        searchBarProps={{
          placeholder: Strings.common.search,
          onChange: handleSearchChange,
        }}
        additionalExportButtonTitle={Strings.button.exportCsv}
        additionalExportPdfButtonTitle={Strings.button.exportpdf}
      />
      {showFilter && (
        <Stack sx={{ flexDirection: 'row', p: 2, gap: 1 }}>
          <DatePicker
            label="Filter By Date"
            value={dateValue}
            onChange={(newValue: any) => {
              setDateValue(newValue);
            }}
          />
          {dateValue != null && (
            <IconButton sx={{ p: 0.25, borderRadius: '5px' }} onClick={handleClearFilter}>
              <Iconify
                width={25}
                icon="material-symbols:filter-alt-off"
                color={theme.palette.primary.main}
              />
            </IconButton>
          )}
        </Stack>
      )}
      <InventoryEditList
        rows={dateValue ? filteredData : data ? [...data].reverse() : data}
        loading={loading}
      />
    </Stack>
  );
};

export { InventoryEditListPage };
