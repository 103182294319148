import { Label } from '../label';

interface Props {
  name: string;
  active: boolean;
}

const StatusTag = ({ active, name }: Props) => {
  return <Label color={(active && 'success') || 'error'}>{name}</Label>;
};
export { StatusTag };
