/* eslint-disable @typescript-eslint/no-explicit-any */
/*
 * Root Reducer
 * combine all reducers to create root reducer
 */

import { combineReducers, type Action } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import Storage from 'redux-persist/lib/storage';

import { bootstrapReducer } from '@app/modules/bootstrap';
import { authReducer, logout } from '@app/modules/auth';
import { commonReducer } from '@app/modules/common';
import { categoryReducer } from '@app/modules/categories';
import { bannerReducer } from '@app/modules/banner';
import { productSlice } from '@app/modules/products';
import { attributeSlice } from '@app/modules/attribute';
import { skuSlice } from '@app/modules/sku';
import { skuBannerSlice } from '@app/modules/banner-detail';
import { userSlice } from '@app/modules/users';
import { inventorySlice } from '@app/modules/inventory-banner-management';
import { collectionReducer } from '@app/modules/collection';
import { collectionDetailReducer } from '@app/modules/collection-detail';
import { customerSlice } from '@app/modules/customers';
import { addSlotSlice } from '@app/modules/slot-management-detail';
import { OrderSlice } from '@app/modules/order-management';
import { orderDetailSlice } from '@app/modules/order-detail';
import { inventoryDetailSlice } from '@app/modules/inventory-detail';
/* -----[ persist configurations ]------ */
const rootPersistConfig = {
  key: 'root',
  storage: Storage,
  whitelist: ['version', 'commonReducer'],
};

// Preserver below slice even after logout
const preserveAlways = ['bootstrapReducer'];
const stateVersion = 1; // Update it whenever change state structure of whitelist slice

const appReducer = combineReducers({
  bootstrapReducer,
  authReducer,
  commonReducer,
  categoryReducer,
  bannerReducer,
  productSlice,
  attributeSlice,
  skuSlice,
  userSlice,
  inventorySlice,
  collectionReducer,
  skuBannerSlice,
  collectionDetailReducer,
  customerSlice,
  addSlotSlice,
  OrderSlice,
  orderDetailSlice,
  inventoryDetailSlice,

  version: (state = stateVersion) => state,
});

const rootReducer = (state: ReturnType<typeof appReducer>, action: Action<any>) => {
  if (action.type === logout.type) {
    const newState: any = Object.keys(state).reduce((acc, key) => {
      if (preserveAlways.includes(key)) {
        (acc as any)[key] = (state as any)[key];
      } else {
        (acc as any)[key] = undefined;
      }
      return acc;
    }, {});
    return appReducer(newState, action);
  }

  return appReducer(state, action);
};

const persistRootReducer = persistReducer(rootPersistConfig, (state, action) => {
  const migratedState = migrateState(state);
  return rootReducer(migratedState, action);
});

const migrateState = (state: any) => {
  if (state !== undefined && state.version !== stateVersion) {
    return {
      ...state,
      version: stateVersion,
    };
  }

  return state;
};

export type RootState = ReturnType<typeof rootReducer>;

export { persistRootReducer };
