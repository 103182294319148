import { useState, useMemo } from 'react';

interface Props {
  data: any[] | undefined;
  fields: string[];
}

const useSearch = ({ data, fields }: Props) => {
  const [searchQuery, setSearchQuery] = useState('');

  const filteredRows = useMemo(() => {
    if (searchQuery) {
      let searchValue = '';

      if (searchQuery.toLowerCase() === 'vendor') {
        searchValue = 'vu';
      } else if (searchQuery.toLowerCase() === 'admin') {
        searchValue = 'aa';
      } else {
        searchValue = searchQuery.trim().toLowerCase();
      }

      const searchWords = searchValue.split(' ');

      return data?.filter((row) =>
        searchWords.every((word) =>
          fields.some((field) => row[field]?.toLowerCase().includes(word))
        )
      );
    }
    return data;
  }, [data, searchQuery, fields]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  return { handleSearchChange, filteredRows };
};

export { useSearch };
