import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { RequestStatus } from '@app/constants';
import { IRequestFailed, IRequestStatus } from '@app/types';
import { IAddSkuSchema, ISku, ISkuState } from './types';

const initialState: ISkuState = {
  sku: {},
  addSku: {},
  updateSkuStatus: {},
  skuDetail: {},
};

const slice = createSlice({
  name: 'sku',
  initialState,
  reducers: {
    getSku(state, action: PayloadAction<{ page: number; q?: any }>) {
      state.sku.status = RequestStatus.RUNNING;
    },
    getSkuSuccess(state, action: PayloadAction<ISkuState['sku']['data']>) {
      state.sku.status = RequestStatus.SUCCESS;
      state.sku.data = action.payload;
    },
    getSkuFailed(state, action: PayloadAction<IRequestFailed>) {
      state.sku.status = RequestStatus.ERROR;
      state.sku.error = action.payload;
    },
    //
    getSkuDetail(state, action: PayloadAction<{ id: number }>) {
      state.skuDetail.status = RequestStatus.RUNNING;
    },
    getSkuDetailSuccess(state, action: PayloadAction<ISkuState['skuDetail']['data']>) {
      state.skuDetail.status = RequestStatus.SUCCESS;
      state.skuDetail.data = action.payload;
    },
    getSkuDetailFailed(state, action: PayloadAction<IRequestFailed>) {
      state.skuDetail.status = RequestStatus.ERROR;
      state.skuDetail.error = action.payload;
    },
    //

    addSku(state, action: PayloadAction<IAddSkuSchema>) {
      state.addSku.status = RequestStatus.RUNNING;
    },
    addSkuSuccess(state, action: PayloadAction<ISku>) {
      state.addSku.status = RequestStatus.SUCCESS;
      state.addSku.data = action.payload;
    },
    addSkuFailed(state, action: PayloadAction<IRequestFailed>) {
      state.addSku.status = RequestStatus.ERROR;
      state.addSku.error = action.payload;
    },

    //
    updateSkuStatus(state, action: PayloadAction<{ id: number }>) {
      state.updateSkuStatus.status = RequestStatus.RUNNING;
    },
    updateSkuStatusSuccess(state, action: PayloadAction<IRequestStatus>) {
      state.updateSkuStatus.status = RequestStatus.SUCCESS;
      state.updateSkuStatus.data = action.payload;
    },
    updateSkuStatusFailed(state, action: PayloadAction<IRequestFailed>) {
      state.updateSkuStatus.status = RequestStatus.ERROR;
      state.updateSkuStatus.error = action.payload;
    },
  },
});
export default slice.reducer;

export const {
  getSku,
  getSkuSuccess,
  getSkuFailed,
  addSku,
  addSkuSuccess,
  addSkuFailed,
  updateSkuStatus,
  updateSkuStatusFailed,
  updateSkuStatusSuccess,
  getSkuDetail,
  getSkuDetailSuccess,
  getSkuDetailFailed,
} = slice.actions;
