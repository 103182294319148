import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { IPageCount, IState } from './types';

// --------------------------------------------

export const initialState: IState = {
  token: '',
  paginationModule: {},
};

const slice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setToken(state, action: PayloadAction<string>) {
      state.token = action.payload;
    },
    setPageCount(state, action: PayloadAction<IPageCount>) {
      if (!state.paginationModule) {
        state.paginationModule = {};
      }
      state.paginationModule[action.payload.id] = action.payload.count;
    },
  },
});

export default slice.reducer;

export const { setToken, setPageCount } = slice.actions;
