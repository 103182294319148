import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { Header } from '@app/sections/header';
import { Strings } from '@app/constants';
import { useTable } from '@app/components/table';
import SkuList from './sku-list';
import { useDialog, useRequestState, useSearch } from '@app/hooks';
import AddProductInBanner from './add-product-in-banner';
import AddProductInImport from './add-product-in-import';
import { selectSkuBannerState } from '../selectors';
import { getSkuByBannerId } from '../slice';
import { PATH_DASHBOARD } from '@app/routes/paths';

const BannerDetailPage = () => {
  const dispatch = useDispatch();

  const { state } = useLocation();

  const tableProps = useTable();

  const addSkuDialog = useDialog();
  const addImport = useDialog();

  const navigation = useNavigate();

  const handleBack = () => {
    navigation(PATH_DASHBOARD.banner);
  };

  useEffect(() => {
    dispatch(getSkuByBannerId(state.data?.id));
  }, [dispatch]);

  const { data, loading } = useRequestState({
    stateSelector: selectSkuBannerState,
    errorShown: true,
  });

  const searchProps = useSearch({ data, fields: ['name'] });

  return (
    <>
      <Header
        backButton
        onClickBack={() => {
          handleBack();
        }}
        bannerDetail={state.data}
        detail
        title={Strings.pageTitle.bannerDetail}
        helperText={''}
        actionButtonTitle={Strings.button.addProductInBanner}
        actionButtonImport={Strings.button.import}
        onClickActionButton={addSkuDialog.show}
        onClickActionButtonImport={addImport.show}
        searchBarProps={{
          placeholder: Strings.common.search,
          onChange: searchProps.handleSearchChange,
        }}
      />

      <SkuList
        loading={loading}
        tableProps={tableProps}
        rows={searchProps.filteredRows}
        bannerId={state.data?.id}
      />
      {addSkuDialog.visible && (
        <AddProductInBanner open onClose={addSkuDialog.hide} bannerId={state.data?.id} />
      )}
      {addImport.visible && (
        <AddProductInImport open onClose={addImport.hide} bannerId={state.data?.id} />
      )}
    </>
  );
};

export { BannerDetailPage };
