/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { Divider, Dialog, useTheme } from '@mui/material';
import { useDispatch } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CSVReader from 'react-csv-reader';
import { CSVLink } from 'react-csv';

import { useRequestState } from '@app/hooks';
import { Header } from '@app/sections/header';
import { Strings } from '@app/constants';
import { addNewSkuProduct } from '../slice';
import { selectAddNewSkuProductState } from '../selectors';

interface Props {
  open: boolean;
  onClose: VoidFunction;
  bannerId: number;
}
const AddProductInImport = ({ open, onClose, bannerId }: Props) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const header = ['Sku ID'];

  const [data, setData] = useState([]);

  const { loading } = useRequestState({
    stateSelector: selectAddNewSkuProductState,
    onSuccess: onClose,
    successMessageShown: true,
    errorShown: true,
  });

  const onSubmit = (skuId: any) => {
    dispatch(addNewSkuProduct({ skuId: data, id: bannerId }));
  };

  return (
    <>
      <Dialog open={open} fullWidth>
        <Header variant="dialog" title={Strings.button.import} onClose={onClose} />
        <Divider />
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={1} style={{ marginTop: 0, marginBottom: 5, padding: 30 }}>
            <Grid item xs={6}>
              <CSVReader
                onFileLoaded={(data, fileInfo, originalFile) => {
                  console.log(data, fileInfo, originalFile);
                  const arr: any = [];
                  // eslint-disable-next-line array-callback-return
                  data.map((row, i) => {
                    if (i !== 0 && row[0].trim() !== '') {
                      arr.push(row[0]);
                    }
                  });

                  console.log(arr);
                  setData(arr);
                }}
              />
            </Grid>
            <Grid item xs={6} style={{ paddingTop: 3 }}>
              <LoadingButton
                type="submit"
                variant="contained"
                onClick={(e: any) => {
                  onSubmit(e);
                }}
                loading={loading}
              >
                {Strings.button.save}
              </LoadingButton>
            </Grid>
            <Grid container direction="column" alignItems="end" mt={1.5}>
              <CSVLink
                data=""
                headers={header}
                filename={'template.csv'}
                style={{ fontSize: '15px', color: theme.palette.success.main }}
              >
                {Strings.button.downloadTemplate}
              </CSVLink>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    </>
  );
};

export default AddProductInImport;
