import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Header } from '@app/sections/header';
import { Strings } from '@app/constants';
import { useDialog, useRequestState } from '@app/hooks';
import { useTable } from '@app/components/table';
import InventoryList from './inventory-list';
import { selectInventoryState } from '../selectors';
import { getInventory } from '../slice';
import AddInventory from './add-inventory-form';
import { usePageCount } from '@app/hooks/use-page-count';
import AddInventoryInImport from './add-inventory-in-imports';

const TABLE_HEAD_CSV = [
  { key: 'id', label: 'id', align: 'left' },
  { key: 'vendorId', label: 'Vendor Id', align: 'left' },
  { key: 'vendor', label: 'Vendor', align: 'left' },
  { key: 'skuId', label: 'SKU Id', align: 'left', sortable: false },
  { key: 'skuName', label: 'SKU Name', align: 'left', sortable: false },
  { key: 'sku', label: 'SKU', align: 'left', sortable: false },
  { key: 'price', label: 'Price', align: 'left', sortable: false },
  { key: 'ourPrice', label: 'Our Price', align: 'left', sortable: false },
  { key: 'discountPrice', label: 'Discount Price', align: 'left', sortable: false },
  { key: 'ordered', label: 'Ordered', align: 'left', sortable: false },
  { key: 'stock', label: 'Stock', align: 'left', sortable: false },
  { key: 'status', label: 'Status', align: 'left', sortable: false },
];

const InventoryListPage = () => {
  const tableProps = useTable();
  const addDialog = useDialog();
  const addImport = useDialog();
  const dispatch = useDispatch();
  const page = tableProps.page;
  const { count } = usePageCount(getInventory);

  useEffect(() => {
    dispatch(getInventory({ page }));
  }, [dispatch, page]);

  const { data, loading } = useRequestState({
    stateSelector: selectInventoryState,
    errorShown: true,
  });

  const [query, setQuery] = useState('');

  useEffect(() => {
    if (query.length !== 0) {
      dispatch(getInventory({ page, q: query }));
    } else {
      dispatch(getInventory({ page }));
    }
  }, [dispatch, page, query]);

  const handleSearchChange = (event: any) => {
    setQuery(event.target.value);
  };

  const flattenData = (data: any) => {
    const serializeData = data?.map((elem: any) => {
      return {
        ...elem,
        status: elem.active === true ? 'Active' : 'InActive',
        sku: elem.sku.skuId,
        vendor: elem.vendor.firstName,
        skuName: elem.sku.name,
        vendorId: elem.vendorId,
        skuId: elem.skuId,
      };
    });
    return serializeData;
  };
  return (
    <>
      <Header
        title={Strings.pageTitle.inventoryBannerManagement}
        helperText={''}
        actionButtonTitle={Strings.button.addInventory}
        onClickActionButton={addDialog.show}
        data={flattenData(data)}
        csvHead={TABLE_HEAD_CSV}
        csvFileName={'inventory-list.csv'}
        additionalExportButtonTitle={Strings.button.exportCsv}
        additionalExportPdfButtonTitle={Strings.button.exportpdf}
        actionButtonImport={Strings.button.import}
        onClickActionButtonImport={addImport.show}
        orientation="landscape"
        searchBarProps={{
          placeholder: Strings.common.search,
          onChange: handleSearchChange,
        }}
      />

      <InventoryList loading={loading} tableProps={tableProps} rows={data} count={count} />

      {addDialog.visible && <AddInventory open onClose={addDialog.hide} />}
      {addImport.visible && <AddInventoryInImport open onClose={addImport.hide} />}
    </>
  );
};

export { InventoryListPage };
