import { PaginatedTable } from '@app/components/paginated-table';
import { TableProps } from '@app/components/table';
import { IService } from '../types';
import { ServiceRow } from './service-row';

const TABLE_HEAD = [
  { id: 'name', label: 'Name', align: 'left' },
  { id: 'sku', label: 'SKU', align: 'left' },
  { id: 'price', label: 'Price', align: 'left' },
  { id: 'category', label: 'Category', align: 'left' },
  { id: 'status', label: 'Status', align: 'left', sortable: false },
  { id: 'action', label: 'Action', align: 'left', sortable: false },
];

interface Props {
  tableProps: TableProps;
  rows?: IService[];
  loading: boolean;
}

const ServicesList = ({ tableProps, rows, loading }: Props) => {
  return (
    <PaginatedTable<IService>
      head={TABLE_HEAD}
      tableProps={tableProps}
      data={rows}
      loading={loading}
      isServerSidePaging={false}
    >
      {(row) => (
        <ServiceRow
          row={row}
          selected={tableProps.selected.includes(row.id)}
          onSelectRow={() => {
            ('');
          }}
        />
      )}
    </PaginatedTable>
  );
};
export default ServicesList;
