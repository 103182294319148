import { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';

import { Iconify } from '@app/components';
import useResponsive from '@app/hooks/use-responsive';
import Main from './Main';
import NavVertical from './nav/NavVertical';
import { MenuIconStyled } from './style';
import navConfig from './nav/config-navigation';

// ----------------------------------------------------------------------
interface NavItem {
  title: string;
  path: string;
  icon?: JSX.Element;
  children?: NavItem[];
}
export default function DashboardLayout() {
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!isDrawerOpen);
  };

  const renderNavVertical = <NavVertical openNav={isDrawerOpen} onCloseNav={toggleDrawer} />;

  const { pathname } = useLocation();

  const findItemByPath = (navItems: NavItem[], path: string): NavItem | null => {
    for (const navConfig of navItems) {
      if (navConfig.path === path) {
        return navConfig;
      }
      if (navConfig.children) {
        const childItem = findItemByPath(navConfig.children, path);
        if (childItem) {
          return childItem;
        }
      }
    }
    return null;
  };

  const currentItem = findItemByPath(navConfig, pathname);

  const isMobile = useResponsive('down', 'sm');

  useEffect(() => {
    setDrawerOpen(false);
  }, [setDrawerOpen, pathname]);

  return (
    <>
      <Stack>
        <MenuIconStyled>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={toggleDrawer}
            sx={{ m: 1 }}
          >
            <Iconify icon={'oi:menu'} />
          </IconButton>
          {currentItem && (
            <Typography variant="h5" sx={{ mt: 1.75 }}>
              {currentItem.title}
            </Typography>
          )}
        </MenuIconStyled>
      </Stack>
      <Box
        sx={{
          display: 'flex',
          height: isMobile ? '90vh' : 'auto',
        }}
      >
        {renderNavVertical}

        <Main>
          <Helmet>
            <title>{currentItem ? currentItem.title : 'PetVesta'}</title>
          </Helmet>
          <Outlet />
        </Main>
      </Box>
    </>
  );
}
