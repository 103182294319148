import { Box, Stack, Drawer, IconButton } from '@mui/material';

import { NAV } from '@app/configs';
import { Iconify, LogoSidebar } from '@app/components';
import Scrollbar from '@app/components/scrollbar/Scrollbar';
import NavSectionVertical from '@app/components/nav-section/vertical/NavSectionVertical';
import useResponsive from '@app/hooks/use-responsive';
import { CloseIconStyled } from '../style';
import navConfig from './config-navigation';
import NavAccount from './NavAccount';

// ----------------------------------------------------------------------

interface Props {
  openNav: boolean;
  onCloseNav: VoidFunction;
}

export default function NavVertical({ openNav, onCloseNav }: Props) {
  const isMobile = useResponsive('down', 'sm');

  const filteredNavConfig = navConfig.filter((item) => !(item as any).hidden);

  const renderContent = (
    <Stack
      sx={{
        background: `url(/assets/background/background.png) bottom center/cover`,
      }}
    >
      <Scrollbar
        sx={{
          height: '100vh',
          '& .simplebar-content': {
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
          },
        }}
      >
        <CloseIconStyled>
          <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={onCloseNav}>
            <Iconify icon="fluent-emoji-high-contrast:cross-mark" />
          </IconButton>
        </CloseIconStyled>
        <Stack>
          <LogoSidebar disabledLink sx={{ height: isMobile ? 70 : 102, width: 125 }} />
        </Stack>

        <Stack sx={{ mx: 2, my: 1.75, flexGrow: 1 }}>
          <NavSectionVertical sx={{ flexGrow: 1 }} data={filteredNavConfig} />
        </Stack>

        <Stack sx={{ mx: 2.5, pb: 3 }}>
          <NavAccount />
        </Stack>
      </Scrollbar>
    </Stack>
  );
  return (
    <Box
      component="nav"
      sx={{
        width: { lg: NAV.W_DASHBOARD },
      }}
    >
      <Drawer
        anchor="left"
        open={openNav}
        onClose={onCloseNav}
        variant={isMobile ? 'temporary' : 'permanent'}
        PaperProps={{
          sx: {
            zIndex: 0,
            width: NAV.W_DASHBOARD,
            position: 'relative',
            display: openNav || !isMobile ? 'visible' : 'none',
          },
        }}
      >
        {renderContent}
      </Drawer>
    </Box>
  );
}
