import { useNavigate } from 'react-router-dom';

import { PaginatedTable } from '@app/components/paginated-table';
import { TableProps } from '@app/components/table';
import { IUser } from '../types';
import { UserRow } from './user-row';
import { PATH_DASHBOARD } from '@app/routes/paths';

const TABLE_HEAD = [
  { id: 'id', label: 'Id', align: 'left', sortable: false },
  { id: 'name', label: 'Name', align: 'left', sortable: false },
  { id: 'userType', label: 'User Type', align: 'center', sortable: false },
  { id: 'vendorType', label: 'Vendor Type', align: 'center', sortable: false },
  { id: 'email', label: 'Email', align: 'center', sortable: false },
  { id: 'phone', label: 'Phone', align: 'center', sortable: false },
  { id: 'shift', label: 'Shift', align: 'center', sortable: false },
  { id: 'status', label: 'Status', align: 'center', sortable: false },
];

interface Props {
  tableProps: TableProps;
  rows?: IUser[];
  loading: boolean;
  count: number;
  page: number;
}

const UsersList = ({ tableProps, rows, loading, count, page }: Props) => {
  const navigation = useNavigate();

  const handleSelect = (data: any) => {
    const id = data.id;
    navigation(PATH_DASHBOARD.user, { state: { id, page } });
  };

  return (
    <PaginatedTable<IUser>
      head={TABLE_HEAD}
      tableProps={tableProps}
      data={rows}
      loading={loading}
      isServerSidePaging={true}
      totalCount={count}
    >
      {(row) => (
        <UserRow
          row={row}
          selected={tableProps.selected.includes(row?.id)}
          onSelectRow={() => {
            handleSelect(row);
          }}
        />
      )}
    </PaginatedTable>
  );
};
export default UsersList;
