// ----------------------------------------------------------------------

function path(root: string, subLink: string) {
  return `${root}${subLink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  login: path(ROOTS_AUTH, '/login'),
  forgotPassword: path(ROOTS_AUTH, '/forgot-password'),
  setPassword: path(ROOTS_AUTH, '/set-password'),
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  app: path(ROOTS_DASHBOARD, '/app'),
  orders: path(ROOTS_DASHBOARD, '/orders'),
  users: path(ROOTS_DASHBOARD, '/users'),
  user: path(ROOTS_DASHBOARD, '/user/:id'),
  service: path(ROOTS_DASHBOARD, '/service'),
  categories: path(ROOTS_DASHBOARD, '/categories'),
  product: path(ROOTS_DASHBOARD, '/product'),
  sku: path(ROOTS_DASHBOARD, '/sku'),
  order: path(ROOTS_DASHBOARD, '/order'),
  attribute: path(ROOTS_DASHBOARD, '/attribute'),
  inventoryBannerManagement: path(ROOTS_DASHBOARD, '/inventoryBannerManagement'),
  banner: path(ROOTS_DASHBOARD, '/banner'),
  collection: path(ROOTS_DASHBOARD, '/collection'),
  bannerDetail: path(ROOTS_DASHBOARD, '/bannerDetail'),
  myProfile: path(ROOTS_DASHBOARD, '/my-profile'),
  productDetail: path(ROOTS_DASHBOARD, '/productDetail'),
  categoryDetail: path(ROOTS_DASHBOARD, '/categoryDetail'),
  orderDetail: path(ROOTS_DASHBOARD, '/orderDetail'),
  inventoryDetail: path(ROOTS_DASHBOARD, '/inventoryDetail'),
  collectionDetail: path(ROOTS_DASHBOARD, '/collectionDetail'),
  slotManagement: path(ROOTS_DASHBOARD, '/slotManagement'),
  slotManagementDetail: path(ROOTS_DASHBOARD, '/slotManagementDetail'),
  skuDetail: path(ROOTS_DASHBOARD, '/skuDetail'),
  customers: path(ROOTS_DASHBOARD, '/customers'),
};
