/* eslint-disable @typescript-eslint/no-empty-function */
import { PaginatedTable } from '@app/components';
import { useTable } from '@app/components/table';
import { InventoryEditRow } from './inventory-edit-row';
import { ITransaction } from '../types';

const TABLE_HEAD = [
  { id: 'transactionId', label: 'Transaction Id', align: 'left', sortable: false },
  { id: 'operation', label: 'Operation', align: 'left', sortable: false },
  { id: 'reason', label: 'Reason', align: 'center', sortable: false },
  { id: 'stock', label: 'Stock', align: 'center', sortable: false },
  { id: 'qtyIn', label: 'Qty In', align: 'center', sortable: false },
  { id: 'qtyOut', label: 'Qty Out', align: 'center', sortable: false },
  { id: 'updtedStock', label: 'Updated Stock', align: 'center', sortable: false },
  { id: 'timeStamp', label: 'Time Stamp', align: 'center', sortable: false },
];

interface Props {
  rows?: ITransaction[];
  loading: boolean;
}

const InventoryEditList = ({ rows, loading }: Props) => {
  const tableProps = useTable();
  return (
    <PaginatedTable<ITransaction>
      head={TABLE_HEAD}
      tableProps={tableProps}
      loading={loading}
      data={rows}
      isServerSidePaging={true}
    >
      {(row) => (
        <InventoryEditRow
          row={row}
          selected={tableProps.selected.includes(row.id)}
          onSelectRow={() => {}}
        />
      )}
    </PaginatedTable>
  );
};
export default InventoryEditList;
