import { MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { Strings } from '@app/constants';
import { IUser } from '@app/modules/users/types';
interface Props {
  actions?: Array<{ label: string; callback?: () => void; row?: IUser }>;
  onClickAction: VoidFunction;
}

export default function RowActions({ actions, onClickAction }: Props) {
  const navigate = useNavigate();
  return (
    <>
      {actions?.map((action) => (
        <MenuItem
          key={action.label}
          onClick={() => {
            onClickAction();
            if (action.label === Strings.rowActions.view) {
              // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
              navigate('/dashboard/user/' + action.row?.id);
            } else {
              action.callback?.();
            }
          }}
        >
          {action.label}
        </MenuItem>
      ))}
    </>
  );
}
