import { Divider, Stack, Table, TableRow, Typography } from '@mui/material';
import { ReactNode } from 'react';
import dayjs from 'dayjs';
import { ToWords } from 'to-words';

import { TableCellCustom } from '../table';
import { Logo } from '../logo';
import { ACCOUNT_DETAILS } from '@app/configs';
import { calculateTotalDiscount, calculateTotalPrice, isActiveSlot } from '@app/utils';

interface TableCellProps {
  children: ReactNode;
  borderRight?: boolean;
  borderTop?: boolean;
  borderBottom?: boolean;
  borderLeft?: boolean;
  colspan?: number;
}
const InvoiceOrderDetails = ({ details }: any) => {
  const TableCell = ({
    children,
    borderBottom,
    borderLeft,
    borderRight,
    borderTop,
    colspan,
    ...rest
  }: TableCellProps) => {
    return (
      <TableCellCustom
        align="center"
        sx={{
          borderRight: borderRight ? '1px solid #e0e0e0' : null,
          borderLeft: borderLeft ? '1px solid #e0e0e0' : null,
          borderTop: borderTop ? '1px solid #e0e0e0' : null,
          borderBottom: borderBottom ? '1px solid #e0e0e0' : null,
        }}
        colSpan={colspan ?? undefined}
        {...rest}
      >
        {children}
      </TableCellCustom>
    );
  };

  function generateInvoice() {
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    const invoiceNumber = 'AE' + Math.floor(Math.random() * 100000000);
    const currentDate = dayjs();
    const formattedDate = currentDate.format('DD/MM/YYYY hh:mm A');

    return {
      invoiceNumber,
      invoiceDate: formattedDate,
    };
  }

  const subTotal = calculateTotalPrice(details?.orderProducts);
  const totalDiscount = calculateTotalDiscount(details?.orderProducts);
  const activeSlot = isActiveSlot(details?.orderSlots);
  const { invoiceNumber, invoiceDate } = generateInvoice();

  const Total = subTotal - totalDiscount;
  const netTaxableAmount =
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    Total + (activeSlot?.deliveryCharge ? activeSlot?.deliveryCharge : 0);

  const grandTotal = netTaxableAmount;

  const toWords = new ToWords();
  const amountInWords = toWords.convert(grandTotal || 0);

  return (
    <Stack padding={5}>
      <Stack boxShadow={2} sx={{ border: '1px solid #e0e0e0' }}>
        <Stack>
          <Logo
            justifyContent={'center'}
            sx={{ height: '200px', width: '200px', alignSelf: 'center' }}
          />
        </Stack>
        <Stack spacing={1} mb={3} p={2}>
          <Typography variant="subtitle2">BEST POINT PAVILLION PORTAL LLC</Typography>
          <Typography variant="body1">TRN - 100450345200003</Typography>
          <Typography variant="body1">Dubai</Typography>
          <Typography variant="body1">United Arab Emirates</Typography>
        </Stack>
        <Stack p={2}>
          <Typography align="right" color="text.secondary">
            Tax Invoice
          </Typography>
        </Stack>
        <Divider />
        <Stack flexDirection={'row'} justifyContent={'center'} flex={2} p={2}>
          <Stack spacing={2} flex={1}>
            <Typography>Invoice#</Typography>
            <Typography>Invoice Date</Typography>
            <Typography>Order ID</Typography>
            <Typography>Payment Mode</Typography>
          </Stack>
          <Stack spacing={2} flex={1}>
            <Typography variant="subtitle2">{invoiceNumber}</Typography>
            <Typography variant="subtitle2">{invoiceDate}</Typography>
            <Typography variant="subtitle2">{details?.orderId}</Typography>
            <Typography variant="subtitle2">{details?.paymentMode}</Typography>
          </Stack>
        </Stack>
        <Divider />
        <Stack flexDirection={'row'} justifyContent={'center'} flex={2} px={2}>
          <Stack flex={1} py={2}>
            <Typography variant="subtitle2" mb={3}>
              Bill To
            </Typography>
            <Typography>{details?.customerAddress?.name}</Typography>
            <Typography>{details?.customerAddress?.id}</Typography>
            <Typography>{details?.customerAddress?.city}</Typography>
            <Typography>{details?.customerAddress?.country}</Typography>
          </Stack>
          <Divider orientation="vertical" flexItem />
          <Stack flex={1} />
        </Stack>
        <Divider />
        <Stack>
          <Table>
            <TableRow>
              <TableCell borderRight>#</TableCell>
              <TableCell borderRight>Item & Description</TableCell>
              <TableCell borderRight>Qty</TableCell>
              <TableCell borderRight>Rate</TableCell>
              <TableCell>Taxable Amount</TableCell>
            </TableRow>
            {details?.orderProducts?.map((item: any, index: number) => (
              <TableRow key={index}>
                <TableCell borderRight>{index + 1}</TableCell>
                <TableCell borderRight>{item?.sku?.name}</TableCell>
                <TableCell borderRight>{item?.quantity}</TableCell>
                <TableCell borderRight>{item?.ourPrice}</TableCell>
                <TableCell>{item?.ourPrice * item?.quantity}</TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell borderRight colspan={4}>
                <Typography variant="subtitle2" textAlign={'right'}>
                  Subtotal
                </Typography>
              </TableCell>
              <TableCell>{subTotal} AED</TableCell>
            </TableRow>
            <TableRow>
              <TableCell borderRight colspan={4}>
                <Typography variant="subtitle2" textAlign={'right'}>
                  Delivery Charges
                </Typography>
              </TableCell>
              <TableCell>{activeSlot?.deliveryCharge} AED</TableCell>
            </TableRow>
            <TableRow>
              <TableCell borderRight colspan={4}>
                <Typography variant="subtitle2" textAlign={'right'}>
                  Total Discount
                </Typography>
              </TableCell>
              <TableCell>{totalDiscount} AED</TableCell>
            </TableRow>
            <TableRow>
              <TableCell borderRight colspan={4}>
                <Typography variant="subtitle2" textAlign={'right'}>
                  Grand Total
                </Typography>
              </TableCell>
              <TableCell>{grandTotal} AED</TableCell>
            </TableRow>
          </Table>
        </Stack>
        <Divider />
        <Stack flexDirection={'column'} p={2}>
          <Typography>Total in words </Typography>
          <Typography variant="subtitle2" fontStyle={'italic'} fontSize={18}>
            {amountInWords} AED
          </Typography>
          <Typography pb={2}>Thank you for the payment.</Typography>
          <Typography variant="body1" fontSize={18}>
            {ACCOUNT_DETAILS.ACC_HOLDER}
          </Typography>
          <Typography variant="body1" fontSize={18}>
            {ACCOUNT_DETAILS.BANK_NAME}
          </Typography>
          <Typography variant="body1" fontSize={18}>
            {ACCOUNT_DETAILS.ACC_NO}
          </Typography>
          <Typography variant="body1" fontSize={18}>
            {ACCOUNT_DETAILS.I_BAN}
          </Typography>
        </Stack>
        <Stack p={2}>
          <Typography variant="subtitle2">Financial Department Signature ______________</Typography>
        </Stack>
        <Stack alignItems={'end'} p={2}>
          <Typography variant="subtitle2">Receiver Signature ______________</Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
export { InvoiceOrderDetails };
