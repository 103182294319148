import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { RequestStatus } from '@app/constants';
import { IRequestFailed, IRequestStatus } from '@app/types';
import {
  IAddBulkInventorySchema,
  IAddInventorySchema,
  IEditInventoryStock,
  IInventory,
  IInventoryState,
} from './types';

const initialState: IInventoryState = {
  inventories: {},
  addInventory: {},
  inventoryDetail: {},
  addBulkInventory: {},
  editInventoryStock: {},
  editInventory: {},
};

const slice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    getInventory(state, action: PayloadAction<{ page: number; q?: any }>) {
      state.inventories.status = RequestStatus.RUNNING;
    },
    getInventorySuccess(state, action: PayloadAction<IInventoryState['inventories']['data']>) {
      state.inventories.status = RequestStatus.SUCCESS;
      state.inventories.data = action.payload;
    },
    getInventoryFailed(state, action: PayloadAction<IRequestFailed>) {
      state.inventories.status = RequestStatus.ERROR;
      state.inventories.error = action.payload;
    },
    //
    getInventoryDetail(state, action: PayloadAction<{ id: number }>) {
      state.inventoryDetail.status = RequestStatus.RUNNING;
    },
    getInventoryDetailSuccess(
      state,
      action: PayloadAction<IInventoryState['inventoryDetail']['data']>
    ) {
      state.inventoryDetail.status = RequestStatus.SUCCESS;
      state.inventoryDetail.data = action.payload;
    },
    getInventoryDetailFailed(state, action: PayloadAction<IRequestFailed>) {
      state.inventoryDetail.status = RequestStatus.ERROR;
      state.inventoryDetail.error = action.payload;
    },
    //

    addInventory(state, action: PayloadAction<IAddInventorySchema>) {
      state.addInventory.status = RequestStatus.RUNNING;
    },
    addInventorySuccess(state, action: PayloadAction<IInventory>) {
      state.addInventory.status = RequestStatus.SUCCESS;
      state.addInventory.data = action.payload;
    },
    addInventoryFailed(state, action: PayloadAction<IRequestFailed>) {
      state.addInventory.status = RequestStatus.ERROR;
      state.addInventory.error = action.payload;
    },
    //

    editInventoryStock(state, action: PayloadAction<IEditInventoryStock>) {
      state.editInventoryStock.status = RequestStatus.RUNNING;
    },
    editInventoryStockSuccess(state, action: PayloadAction<IInventory>) {
      state.editInventoryStock.status = RequestStatus.SUCCESS;
      state.editInventoryStock.data = action.payload;
    },
    editInventoryStockFailed(state, action: PayloadAction<IRequestFailed>) {
      state.editInventoryStock.status = RequestStatus.ERROR;
      state.editInventoryStock.error = action.payload;
    },
    //

    editInventory(state, action: PayloadAction<IAddInventorySchema>) {
      state.editInventory.status = RequestStatus.RUNNING;
    },
    editInventorySuccess(state, action: PayloadAction<IInventory>) {
      state.editInventory.status = RequestStatus.SUCCESS;
      state.editInventory.data = action.payload;
    },
    editInventoryFailed(state, action: PayloadAction<IRequestFailed>) {
      state.editInventory.status = RequestStatus.ERROR;
      state.editInventory.error = action.payload;
    },
    //
    addBulkInventory(state, action: PayloadAction<IAddBulkInventorySchema>) {
      state.addBulkInventory.status = RequestStatus.RUNNING;
    },
    addBulkInventorySuccess(state, action: PayloadAction<IRequestStatus>) {
      state.addBulkInventory.status = RequestStatus.SUCCESS;
      state.addBulkInventory.data = action.payload;
    },
    addBulkInventoryFailed(state, action: PayloadAction<IRequestFailed>) {
      state.addBulkInventory.status = RequestStatus.ERROR;
      state.addBulkInventory.error = action.payload;
      state.addBulkInventory.data = action.payload;
    },
  },
});
export default slice.reducer;

export const {
  getInventory,
  getInventorySuccess,
  getInventoryFailed,
  addInventory,
  addInventorySuccess,
  addInventoryFailed,
  getInventoryDetail,
  getInventoryDetailFailed,
  getInventoryDetailSuccess,
  addBulkInventory,
  addBulkInventorySuccess,
  addBulkInventoryFailed,
  editInventoryStock,
  editInventoryStockFailed,
  editInventoryStockSuccess,
  editInventory,
  editInventorySuccess,
  editInventoryFailed,
} = slice.actions;
