import { useNavigate } from 'react-router-dom';

import { PaginatedTable } from '@app/components/paginated-table';
import { TableProps } from '@app/components/table';
import { IInventory } from '../types';
import { InventoryRow } from './inventory-row';
import { PATH_DASHBOARD } from '@app/routes/paths';

const TABLE_HEAD = [
  { id: 'id', label: 'Id', align: 'left', sortable: false },
  { id: 'vendorId', label: 'Vendor', align: 'left', sortable: false },
  { id: 'skuName', label: 'SKU Name', align: 'center', sortable: false },
  { id: 'skuId', label: 'SKU Id', align: 'center', sortable: false },
  { id: 'price', label: 'Price', align: 'center', sortable: false },
  { id: 'ourPrice', label: 'Our Price', align: 'center', sortable: false },
  { id: 'discountPrice', label: 'Discount Price', align: 'center', sortable: false },
  { id: 'ordered', label: 'Ordered', align: 'center', sortable: false },
  { id: 'stock', label: 'Available Stock', align: 'center', sortable: false },
  { id: 'editInventory', label: 'Edit Inventory', align: 'left', sortable: false },
];

interface Props {
  tableProps: TableProps;
  rows?: IInventory[];
  loading: boolean;
  count?: number;
}

const InventoryList = ({ tableProps, rows, loading, count }: Props) => {
  const navigation = useNavigate();

  const handleSelect = (data: any) => {
    const id = data.id;
    navigation(PATH_DASHBOARD.inventoryDetail, { state: { id } });
  };
  return (
    <PaginatedTable<IInventory>
      head={TABLE_HEAD}
      tableProps={tableProps}
      data={rows}
      loading={loading}
      isServerSidePaging={true}
      totalCount={count}
    >
      {(row) => (
        <InventoryRow
          row={row}
          selected={tableProps.selected.includes(row.id)}
          onSelectRow={() => {
            handleSelect(row);
          }}
        />
      )}
    </PaginatedTable>
  );
};
export default InventoryList;
