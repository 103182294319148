import { Action, createSelector } from '@reduxjs/toolkit';

import { RootState } from '@app/redux/root-reducer';

const common = (state: RootState) => state.commonReducer;

export const selectIsAuthenticated = (store: RootState) => Boolean(store.commonReducer.token);

export const selectAuthToken = (store: RootState) => store.commonReducer.token;

const selectModuleId = (_: any, action: Action) => action.type;
export const selectModuleByAction = createSelector(
  [common, selectModuleId],
  (data, itemId) => data?.paginationModule?.[itemId]
);
