import { useFormContext, Controller } from 'react-hook-form';
import { TextField, type TextFieldProps } from '@mui/material';

// ----------------------------------------------------------------------

type Props = TextFieldProps & {
  name: string;
};

export function RHFTextField({ name, helperText, ...other }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          fullWidth
          error={!!error}
          helperText={error ? error?.message : helperText}
          value={typeof field.value === 'number' && field.value === 0 ? '0' : field.value}
          onChange={(e) => {
            const { value } = e.target;
            // console.log(e, 'CHANGES');
            field.onChange(value === '0' ? 0 : value);
          }}
          {...other}
        />
      )}
    />
  );
}
