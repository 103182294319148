import { all, call, put, takeLatest } from 'redux-saga/effects';

import { request } from '@app/services';
import {
  getSkuByBannerId,
  getSkuByBannerIdFailed,
  getSkuByBannerIdSuccess,
  addNewSkuProduct,
  addNewSkuProductSuccess,
  addNewSkuProductFailed,
  deleteSkuBanner,
  deleteSkuBannerSuccess,
  deleteSkuBannerFailed,
} from './slice';
import * as endpoints from './endpoints';

// ----------------------------------------
function* getSkuByBannerIdSaga(action: ReturnType<typeof getSkuByBannerId>): any {
  try {
    const result = yield call(request.get, endpoints.getSku, {
      params: { bannerId: action.payload },
    });
    yield put(getSkuByBannerIdSuccess(result.data.data));
  } catch (err: any) {
    yield put(getSkuByBannerIdFailed(err));
  }
}

// ----------------------------------
function* addNewSkuProductSaga(action: ReturnType<typeof addNewSkuProduct>): any {
  const { skuId, id } = action.payload;

  const skuIds = (skuId as unknown as string[]).map((id: string) => Number(id));

  try {
    const { data } = yield call(request.post, endpoints.addNewSkuProduct, {
      bannerId: id,
      skuIds,
    });
    if (data.status) {
      yield put(getSkuByBannerId(id));
      yield put(addNewSkuProductSuccess(data.message));
    } else {
      yield put(addNewSkuProductFailed(data));
    }
  } catch (err: any) {
    yield put(addNewSkuProductFailed(err));
  }
}

// ----------------------------------------

function* deleteSkuByBannerSaga(action: ReturnType<typeof deleteSkuBanner>): any {
  try {
    const { id, skuId } = action.payload;

    const result = yield call(
      request.put,
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      `${endpoints.deleteProductById}${id}?skuId=${skuId}`
    );

    yield put(deleteSkuBannerSuccess(result.data.message));
    yield put(getSkuByBannerId(action.payload.id));
  } catch (err: any) {
    yield put(deleteSkuBannerFailed(err));
  }
}

// ----------------------------------------

function* skuDetailsSagas() {
  yield all([
    takeLatest([getSkuByBannerId], getSkuByBannerIdSaga),
    takeLatest(deleteSkuBanner, deleteSkuByBannerSaga),
    takeLatest(addNewSkuProduct, addNewSkuProductSaga),
  ]);
}

export { skuDetailsSagas };
