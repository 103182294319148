import 'react-lazy-load-image-component/src/effects/blur.css';
import { HashRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import relativeTime from 'dayjs/plugin/relativeTime';
import dayjsLocaleData from 'dayjs/plugin/localeData';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { BootstrapGate } from '@app/modules/bootstrap';
import { SnackbarProvider } from '@app/components';
import { configureStore } from '@app/redux';
import { Router } from '@app/routes';
import ThemeProvider from '@app/theme';

dayjs.extend(relativeTime);
dayjs.extend(dayjsLocaleData);
dayjs.extend(utc);

const { persistor, store } = configureStore();

export default function App() {
  return (
    <HelmetProvider>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <BootstrapGate>
            <HashRouter>
              <ThemeProvider>
                <SnackbarProvider>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Router />
                  </LocalizationProvider>
                </SnackbarProvider>
              </ThemeProvider>
            </HashRouter>
          </BootstrapGate>
        </PersistGate>
      </Provider>
    </HelmetProvider>
  );
}
