import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { RequestStatus } from '@app/constants';
import { IRequestFailed, IRequestStatus } from '@app/types';
import { ISkuState } from './types';

const initialState: ISkuState = {
  skuBanner: {},
  deleteSkuBanner: {},
  addNewProduct: {},
  addNewSkuProduct: {},
};

const slice = createSlice({
  name: 'skuBanner',
  initialState,
  reducers: {
    getSkuByBannerId(state, action: PayloadAction<number>) {
      state.skuBanner.status = RequestStatus.RUNNING;
    },
    getSkuByBannerIdSuccess(state, action: PayloadAction<ISkuState['skuBanner']['data']>) {
      state.skuBanner.status = RequestStatus.SUCCESS;
      state.skuBanner.data = action.payload;
    },
    getSkuByBannerIdFailed(state, action: PayloadAction<IRequestFailed>) {
      state.skuBanner.status = RequestStatus.ERROR;
      state.skuBanner.error = action.payload;
    },
    //

    addNewSkuProduct(state, action: PayloadAction<{ id: number; skuId: number[] }>) {
      state.addNewSkuProduct.status = RequestStatus.RUNNING;
    },
    addNewSkuProductSuccess(state, action: PayloadAction<{ id: number; skuId: number[] }>) {
      state.addNewSkuProduct.status = RequestStatus.SUCCESS;
      state.addNewSkuProduct.data = action.payload;
    },
    addNewSkuProductFailed(state, action: PayloadAction<IRequestFailed>) {
      state.addNewSkuProduct.status = RequestStatus.ERROR;
      state.addNewSkuProduct.error = action.payload;
    },

    //

    deleteSkuBanner(state, action: PayloadAction<{ id: number; skuId: number }>) {
      state.deleteSkuBanner.status = RequestStatus.RUNNING;
    },
    deleteSkuBannerSuccess(state, action: PayloadAction<IRequestStatus>) {
      state.deleteSkuBanner.status = RequestStatus.SUCCESS;
      state.deleteSkuBanner.data = action.payload;
    },
    deleteSkuBannerFailed(state, action: PayloadAction<IRequestFailed>) {
      state.deleteSkuBanner.status = RequestStatus.ERROR;
      state.deleteSkuBanner.error = action.payload;
    },
  },
});
export default slice.reducer;

export const {
  getSkuByBannerId,
  getSkuByBannerIdSuccess,
  getSkuByBannerIdFailed,
  deleteSkuBanner,
  deleteSkuBannerSuccess,
  deleteSkuBannerFailed,
  addNewSkuProduct,
  addNewSkuProductSuccess,
  addNewSkuProductFailed,
} = slice.actions;
