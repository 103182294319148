import { all, call, put, takeLatest } from 'redux-saga/effects';

import { request } from '@app/services';
import { setToken } from '@app/modules/common';
import { prepareUserSessionSaga } from '../bootstrap';
import * as endpoints from './endpoints';
import {
  login,
  loginSuccess,
  loginFailed,
  forgotPassword,
  forgotPasswordSuccess,
  forgotPasswordFailed,
  setPassword,
  setPasswordSuccess,
  setPasswordFailed,
} from './slice';

// ------------------------------------------------------

function* loginSaga(action: ReturnType<typeof login>): any {
  try {
    const { email, password } = action.payload;
    const { data } = yield call(request.post, endpoints.login, {
      email: email,
      password,
    });
    yield put(loginSuccess(data));
    yield put(setToken(data.token));
    yield call(prepareUserSessionSaga);
  } catch (error: any) {
    yield put(loginFailed(error));
  }
}

function* forgotPasswordSaga(action: ReturnType<typeof forgotPassword>): any {
  try {
    const { email } = action.payload;
    const { data } = yield call(request.post, endpoints.forgotPassword, {
      email,
    });

    yield put(forgotPasswordSuccess(data));
  } catch (error: any) {
    yield put(forgotPasswordFailed(error));
  }
}
function* setPasswordSaga(action: ReturnType<typeof setPassword>): any {
  try {
    const { password, token } = action.payload;

    const { data } = yield call(request.post, endpoints.setPassword, {
      token,
      password,
    });
    yield put(setPasswordSuccess(data));
  } catch (error: any) {
    yield put(setPasswordFailed(error));
  }
}

function* authSagas() {
  yield all([
    takeLatest(login, loginSaga),
    takeLatest(forgotPassword, forgotPasswordSaga),
    takeLatest(setPassword, setPasswordSaga),
  ]);
}

export { authSagas };
