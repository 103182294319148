import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';

import { Header } from '@app/sections/header';
import { Strings } from '@app/constants';
import { useTable } from '@app/components/table';
import CustomerList from './customer-list';
import { useDialog, useRequestState } from '@app/hooks';
import { getCustomer } from '../slice';
import { selectCustomerState } from '../selectors';
import { usePageCount } from '@app/hooks/use-page-count';

const TABLE_HEAD_CSV = [
  { key: 'id', label: 'Id', align: 'left' },
  { key: 'name', label: 'Name', align: 'left' },
  { key: 'phone', label: 'Phone', align: 'left' },
  { key: 'registeredDate', label: 'Registered Date', align: 'left' },
  { key: 'address', label: 'Address', align: 'left' },
];

const CustomerListPage = () => {
  const tableProps = useTable();
  const dispatch = useDispatch();
  const addDialog = useDialog();
  const { count } = usePageCount(getCustomer);

  const page = tableProps.page;

  const [query, setQuery] = useState('');
  useEffect(() => {
    if (query.length !== 0) {
      dispatch(getCustomer({ page, q: query }));
    } else {
      dispatch(getCustomer({ page }));
    }
  }, [dispatch, page, query]);

  const handleSearchChange = (event: any) => {
    setQuery(event.target.value);
  };

  const { data, loading } = useRequestState({
    stateSelector: selectCustomerState,
    errorShown: true,
  });

  const flattenData = (data: any) => {
    const serializeData = data?.map((elem: any) => {
      const fullAddress = `${String(elem.addresses[0]?.city)}` ?? 'NA';
      const firstName = elem?.firstName ?? '';
      const lastName = elem?.lastName ?? '';
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      const fullname = `${firstName !== '' ? ` ${firstName}` : ''}${
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        lastName !== '' ? ` ${lastName}` : ''
      }`;
      return {
        ...elem,
        name: fullname,
        phone: elem.phone,
        registeredDate: elem.createdAt ? dayjs(elem.createdAt).format('YYYY-MM-DD') : 'NA',
        address: fullAddress,
      };
    });
    return serializeData;
  };
  return (
    <>
      <Header
        title={Strings.pageTitle.customers}
        helperText={''}
        onClickActionButton={addDialog.show}
        searchBarProps={{
          placeholder: Strings.common.searchNamePhone,
          onChange: handleSearchChange,
        }}
        data={flattenData(data)}
        csvHead={TABLE_HEAD_CSV}
        csvFileName={'customer-list.csv'}
        additionalExportButtonTitle={Strings.button.exportCsv}
        additionalExportPdfButtonTitle={Strings.button.exportpdf}
      />

      <CustomerList loading={loading} tableProps={tableProps} rows={data} count={count} />
    </>
  );
};

export { CustomerListPage };
