import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';

import { Header } from '@app/sections/header';
import { Strings } from '@app/constants';
import { useTable } from '@app/components/table';
import CategoryList from './category-list';
import { useDialog, useRequestState } from '@app/hooks';
import AddCategory from './add-category-form';
import { getCategory } from '../slice';
import { selectCategoryState } from '../selectors';
import { usePageCount } from '@app/hooks/use-page-count';

const TABLE_HEAD_CSV = [
  { key: 'id', label: 'id', align: 'left' },
  { key: 'name', label: 'Name', align: 'left' },
  { key: 'category', label: 'Category', align: 'left' },
  { key: 'parentCategory', label: 'Parent Category', align: 'left' },
  { key: 'type', label: 'type', align: 'left' },
  { key: 'topCategory', label: 'Top Category', align: 'left' },
  { key: 'products', label: 'Products', align: 'left', sortable: false },
  { key: 'active', label: 'Status', align: 'left', sortable: false },
];
const CategoryListPage = () => {
  const tableProps = useTable();
  const categoryDialog = useDialog();
  const parentCategoryDialog = useDialog();
  const dispatch = useDispatch();
  const page = tableProps.page;
  const { count } = usePageCount(getCategory);
  const [query, setQuery] = useState('');

  useEffect(() => {
    if (query.length !== 0) {
      dispatch(getCategory({ page, q: query }));
    } else {
      dispatch(getCategory({ page }));
    }
  }, [dispatch, page, query]);

  useEffect(() => {
    if (query.length !== 0) {
      dispatch(getCategory({ page, q: query }));
    } else {
      dispatch(getCategory({ page }));
    }
  }, [dispatch, page, query]);

  const { data, loading } = useRequestState({
    stateSelector: selectCategoryState,
    errorShown: true,
  });

  const handleSearchChange = (event: any) => {
    setQuery(event.target.value);
  };

  const flattenAry = (data: any) => {
    const value = data?.map((elem: any) => {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      return `${elem?.name}:${elem?.description}`;
    });
    return value.join('; ');
  };

  const flattenData = (data: any) => {
    const serializeData = data?.map((elem: any) => {
      return {
        ...elem,
        products: flattenAry(elem.products),
        category: elem.name,
        parentCategory: elem.parentCategoryName,
        type: elem.type,
        topCategory: elem.topCategory === true ? 'Active' : 'InActive',
      };
    });
    return serializeData;
  };
  return (
    <>
      <Header
        title={Strings.pageTitle.categories}
        helperText={''}
        actionButtonTitle={Strings.button.addNewCategory}
        searchBarProps={{
          placeholder: Strings.common.search,
          onChange: handleSearchChange,
        }}
        onClickActionButton={categoryDialog.show}
        additionalButtonTitle={Strings.button.addParentCategory}
        onClickAdditionalButton={parentCategoryDialog.show}
        data={flattenData(data)}
        csvHead={TABLE_HEAD_CSV}
        csvFileName={'category-list.csv'}
        additionalExportButtonTitle={Strings.button.exportCsv}
        additionalExportPdfButtonTitle={Strings.button.exportpdf}
        orientation="landscape"
      />
      <CategoryList
        loading={loading}
        tableProps={tableProps}
        rows={data}
        count={count}
        page={page}
      />
      {categoryDialog.visible && <AddCategory open onClose={categoryDialog.hide} />}
      {parentCategoryDialog.visible && (
        <AddCategory
          open
          onClose={parentCategoryDialog.hide}
          title={Strings.button.addParentCategory}
          isParentCategory
        />
      )}
    </>
  );
};

export { CategoryListPage };
