import { useNavigate } from 'react-router-dom';

import { PaginatedTable } from '@app/components/paginated-table';
import { ICollectionDetail } from '../types';
import { CollectionDetailRow } from './collection-detail-row';
import { TableProps } from '@app/components/table';
import { PATH_DASHBOARD } from '@app/routes/paths';

const TABLE_HEAD = [
  { id: 'productImage', label: 'Image', align: 'left', sortable: false },
  { id: 'skuId', label: 'SKU Id', align: 'center' },
  { id: 'desc', label: 'Description', align: 'center', sortable: false },
  { id: 'att', label: 'Attributes', align: 'center', sortable: false },
  { id: '', label: 'Delete', align: 'left', sortable: false },
];

interface Props {
  tableProps: TableProps;
  rows?: any;
  loading: boolean;
  collectionDetailId: number;
}

const CollectionDetailList = ({ tableProps, rows, loading, collectionDetailId }: Props) => {
  const navigation = useNavigate();

  const handleSelect = (data: any) => {
    const id = data?.id;
    navigation(PATH_DASHBOARD.skuDetail, { state: { id } });
  };

  return (
    <PaginatedTable<ICollectionDetail>
      head={TABLE_HEAD}
      tableProps={tableProps}
      data={rows}
      loading={loading}
      isServerSidePaging={false}
    >
      {(row) => (
        <CollectionDetailRow
          row={row}
          collectionDetailId={collectionDetailId}
          selected={tableProps.selected.includes(row?.id)}
          onSelectRow={() => {
            handleSelect(row);
          }}
        />
      )}
    </PaginatedTable>
  );
};
export default CollectionDetailList;
