import { useSelector } from 'react-redux';
import { Action } from '@reduxjs/toolkit';

import { selectModuleByAction } from '@app/modules/common';

interface ReturnProps {
  count: number;
}

const usePageCount = (action: Action): ReturnProps => {
  const count = useSelector((state) => selectModuleByAction(state, action));

  return {
    count: count ?? 0,
  };
};

export { usePageCount };
