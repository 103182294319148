import { m } from 'framer-motion';
import { Helmet } from 'react-helmet-async';
import { Typography } from '@mui/material';

import { PageNotFoundIllustration } from '@app/assets/illustrations';
import { MotionContainer, varBounce } from '@app/components';
import { Strings } from '@app/constants';

// ----------------------------------------------------------------------

export default function Page404() {
  return (
    <>
      <Helmet>
        <title> 404 Page Not Found | TeamNexus</title>
      </Helmet>

      <MotionContainer
        sx={{
          display: 'flex',
          height: '100%',
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <m.div variants={varBounce().in}>
          <PageNotFoundIllustration
            sx={{
              height: 260,
              my: { xs: 3, sm: 10, md: 5 },
            }}
          />
        </m.div>
        <m.div variants={varBounce().in}>
          <Typography variant="h3" paragraph>
            {Strings.common.pageNotFound}
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <Typography sx={{ color: 'text.secondary' }}>
            {Strings.common.sorryCanNotFoundPage}
          </Typography>
        </m.div>
      </MotionContainer>
    </>
  );
}
