import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { RequestStatus } from '@app/constants';
import { IRequestFailed } from '@app/types';
import { IInventoryDetailState } from './types';

const initialState: IInventoryDetailState = {
  inventoryTransaction: {},
};

const slice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    getInventoryTransaction(state, action: PayloadAction<{ id: number; q?: any }>) {
      state.inventoryTransaction.status = RequestStatus.RUNNING;
    },
    getInventoryTransactionSuccess(
      state,
      action: PayloadAction<IInventoryDetailState['inventoryTransaction']['data']>
    ) {
      state.inventoryTransaction.status = RequestStatus.SUCCESS;
      state.inventoryTransaction.data = action.payload;
    },
    getInventoryTransactionFailed(state, action: PayloadAction<IRequestFailed>) {
      state.inventoryTransaction.status = RequestStatus.ERROR;
      state.inventoryTransaction.error = action.payload;
    },
  },
});
export default slice.reducer;

export const {
  getInventoryTransaction,
  getInventoryTransactionSuccess,
  getInventoryTransactionFailed,
} = slice.actions;
