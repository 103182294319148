import { PaginatedTable } from '@app/components/paginated-table';
import { TableProps } from '@app/components/table';
import { ICustomer } from '../types';
import { CustomerRow } from './customer-row';

const TABLE_HEAD = [
  { id: 'id', label: 'Id', align: 'left', sortable: false },
  { id: 'name', label: 'Name', align: 'left', sortable: false },
  { id: 'phone', label: 'Phone', align: 'center', sortable: false },
  { id: 'registeredDate', label: 'Registered Date ', align: 'center', sortable: false },
  { id: 'address', label: 'Address', align: 'center', sortable: false },
];

interface Props {
  tableProps: TableProps;
  rows?: ICustomer[];
  loading: boolean;
  count?: number;
}

const CustomerList = ({ tableProps, rows, loading, count }: Props) => {
  return (
    <PaginatedTable<ICustomer>
      head={TABLE_HEAD}
      tableProps={tableProps}
      data={rows}
      loading={loading}
      isServerSidePaging={true}
      totalCount={count}
    >
      {(row) => (
        <CustomerRow
          row={row}
          selected={tableProps.selected.includes(row.id)}
          onSelectRow={() => {
            ('');
          }}
        />
      )}
    </PaginatedTable>
  );
};
export default CustomerList;
