import { styled } from '@mui/material/styles';
import { ListItemIcon, ListItemButton } from '@mui/material';

import { NAV } from '@app/configs';
import { type NavItemProps } from '../types';

// ----------------------------------------------------------------------

type StyledItemProps = Omit<NavItemProps, 'item'> & {
  caption?: boolean;
  disabled?: boolean;
};

export const StyledItem = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== 'active' && prop !== 'caption',
})<StyledItemProps>(({ active, disabled, depth, caption, theme }) => {
  const isLight = theme.palette.mode === 'light';

  const subItem = depth !== 1;

  const activeStyle = {
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.background.default,
    ...(!isLight && {
      color: theme.palette.secondary.main,
    }),
  };

  return {
    position: 'relative',
    textTransform: 'capitalize',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1.5),
    marginBottom: theme.spacing(0.5),
    color: theme.palette.grey[200],
    borderRadius: theme.shape.borderRadius,
    height: NAV.H_DASHBOARD_ITEM,
    // Sub item
    ...(subItem && {
      height: NAV.H_DASHBOARD_ITEM_SUB,
      ...(caption && {
        height: NAV.H_DASHBOARD_ITEM,
      }),
    }),
    // Active item
    ...(active && {
      ...activeStyle,
      '&:hover': {
        ...activeStyle,
      },
    }),
    // Active sub item
    ...(subItem &&
      active && {
        ...activeStyle,
        '&:hover': {
          ...activeStyle,
        },
      }),
    // Disabled
    ...(disabled && {
      '&.Mui-disabled': {
        opacity: 0.64,
      },
    }),
  };
});

// ----------------------------------------------------------------------

export const StyledIcon = styled(ListItemIcon)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

// ----------------------------------------------------------------------

interface StyledSubSectionProps {
  depth: number;
}

export const StyledSubSection = styled('div')<StyledSubSectionProps>(({ theme, depth }) => ({
  marginLeft: theme.spacing(depth * 3.5),
  paddingLeft: theme.spacing(depth * 3.5),
  borderLeft: `solid 1px ${theme.palette.grey[400]}`,
}));
