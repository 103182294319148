import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Header } from '@app/sections/header';
import { Strings } from '@app/constants';
import { useTable } from '@app/components/table';
import UsersList from './users-list';
import { getUsers } from '../slice';
import { useDialog, useRequestState } from '@app/hooks';
import { selectUsersState } from '../selectors';
import AddVendor from './add-vendor';
import AddAdmin from './add-admin';
import { usePageCount } from '@app/hooks/use-page-count';
import AddMaster from './add-master';

const TABLE_HEAD_CSV = [
  { key: 'id', label: 'id', align: 'left' },
  { key: 'firstName', label: 'Name', align: 'left' },
  { key: 'userType', label: 'User Type', align: 'left', sortable: false },
  { key: 'email', label: 'E-mail', align: 'left', sortable: false },
  { key: 'phone', label: 'Phone', align: 'left', sortable: false },
  { key: 'startTime', label: 'Start time', align: 'left', sortable: false },
  { key: 'endTime', label: 'End time', align: 'left', sortable: false },
  { key: 'status', label: 'Status', align: 'left', sortable: false },
];

const UserListPage = () => {
  const dispatch = useDispatch();

  const tableProps = useTable();

  const addVendor = useDialog();
  const addAdmin = useDialog();
  const addMaster = useDialog();
  const page = tableProps.page;
  const [query, setQuery] = useState('');
  useEffect(() => {
    if (query.length !== 0) {
      dispatch(getUsers({ page, q: query }));
    } else {
      dispatch(getUsers({ page }));
    }
  }, [dispatch, page, query]);

  const { data, loading } = useRequestState({ stateSelector: selectUsersState, errorShown: true });
  const { count } = usePageCount(getUsers);

  const handleSearchChange = (event: any) => {
    setQuery(event.target.value);
  };

  const flattenData = (data: any) => {
    const serializeData = data?.map((elem: any) => {
      return {
        ...elem,
        userType:
          (elem.userType === 'VU' && 'Vendor') ||
          (elem.userType === 'AA' && 'Admin') ||
          (elem.userType === 'NU' && 'Normal') ||
          (elem.userType === 'SA' && 'Super Admin'),
        phone: elem.addresses[0]?.mobileNumber | elem.phone,
        status: elem.active === true ? 'Active' : 'InActive',
      };
    });
    return serializeData;
  };
  return (
    <>
      <Header
        title={Strings.pageTitle.users}
        helperText={''}
        additionalMasterButtonTitle={Strings.button.addMaster}
        actionButtonTitle={Strings.button.addVendor}
        additionalButtonTitle={Strings.button.addAdmin}
        onClickAdditionalButton={addAdmin.show}
        onClickActionButton={addVendor.show}
        onClickAdditionalMasterButton={addMaster.show}
        searchBarProps={{
          placeholder: Strings.common.searchNameEmailUserType,
          onChange: handleSearchChange,
        }}
        data={flattenData(data)}
        csvHead={TABLE_HEAD_CSV}
        csvFileName={'user-list.csv'}
        additionalExportButtonTitle={Strings.button.exportCsv}
        additionalExportPdfButtonTitle={Strings.button.exportpdf}
      />

      <UsersList count={count} loading={loading} tableProps={tableProps} rows={data} page={page} />
      {addVendor.visible && <AddVendor open onClose={addVendor.hide} />}
      {addAdmin.visible && <AddAdmin open onClose={addAdmin.hide} />}
      {addMaster.visible && <AddMaster open onClose={addMaster.hide} />}
    </>
  );
};

export { UserListPage };
