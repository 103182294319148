import { useMemo } from 'react';
import {
  Badge,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  TextFieldProps,
  debounce,
} from '@mui/material';

import { Iconify } from '../iconify';

export type SearchBarProps = TextFieldProps & {
  filterShown?: boolean;
  isFilterApplied?: boolean;
  onClickFilter?: () => void;
  activeDebounce?: boolean;
};

const SearchBar = ({
  sx,
  filterShown,
  onClickFilter,
  activeDebounce = true,
  onChange,
  isFilterApplied = false,
  ...rest
}: SearchBarProps) => {
  const searchDelayed = useMemo(
    () => (activeDebounce && onChange ? debounce(onChange, 300) : onChange),
    [onChange, activeDebounce]
  );

  return (
    <Stack direction="row" sx={sx} spacing={1}>
      <TextField
        fullWidth
        onChange={searchDelayed}
        {...rest}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IconButton>
                <Iconify icon="eva:search-fill" />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      {filterShown && (
        <IconButton onClick={onClickFilter} sx={{ p: 2 }}>
          <Badge variant="dot" color="primary" invisible={!isFilterApplied}>
            <Stack height={25} width={25}>
              <img src={`/assets/icons/navbar/filter.svg`} alt="back" />
            </Stack>
          </Badge>
        </IconButton>
      )}
    </Stack>
  );
};

export default SearchBar;
