//
import { SxProps } from '@mui/material';
import { Theme } from '@mui/material/styles';

import { Image } from '../../image';
//
import { CustomFile } from '../types';

// ----------------------------------------------------------------------

interface Props {
  file: CustomFile | string | null;
  placeholderSx?: SxProps<Theme>;
}

export default function AvatarPreview({ file, placeholderSx }: Props) {
  if (!file) {
    return null;
  }

  const imgUrl = typeof file === 'string' ? file : file.preview;

  return (
    <Image
      alt="avatar"
      src={imgUrl}
      sx={{
        zIndex: 8,
        overflow: 'hidden',
        borderRadius: '50%',
        position: 'absolute',
        width: `calc(100% - 16px)`,
        height: `calc(100% - 16px)`,
        ...placeholderSx,
      }}
    />
  );
}
