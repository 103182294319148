import { Stack } from '@mui/material';

import { type NavSectionProps } from '../types';
import NavList from './NavList';

// ----------------------------------------------------------------------

export default function NavSectionVertical({ data, item, ...other }: NavSectionProps) {
  return (
    <Stack>
      {data.map((item) => (
        <NavList key={item.title} data={item} depth={1} hasChild={!!item.children} />
      ))}
    </Stack>
  );
}
