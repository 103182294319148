import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { Divider, IconButton, MenuItem, Stack, Typography, useTheme } from '@mui/material';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

import { selectOrderState } from '../selectors';
import { Header } from '@app/sections/header';
import { useRequestState } from '@app/hooks';
import { Strings } from '@app/constants';
import { useTable } from '@app/components/table';
import OrderList from './order-list';
import { getOrder } from '../slice';
import { usePageCount } from '@app/hooks/use-page-count';
import { calculateTotalDiscount, calculateTotalPrice, isActiveSlot } from '@app/utils';
import { Iconify, RHFSelect } from '@app/components';
import { IDeliverySlot, IGetOrder } from '../types';

const TABLE_HEAD_CSV = [
  { key: 'orderId', label: 'Order Id', align: 'left' },
  { key: 'customer', label: 'Customer', align: 'center', sortable: false },
  { key: 'address', label: 'Address', align: 'left', sortable: false },
  { key: 'skuId', label: 'Sku Id', align: 'left', sortable: false },
  { key: 'skuName', label: 'Sku Name', align: 'left', sortable: false },
  { key: 'vendorName', label: 'Vendor Name', align: 'left', sortable: false },
  { key: 'qtyOrdered', label: 'Qty Ordered', align: 'left', sortable: false },
  { key: 'paymentMode', label: 'Payment Mode', align: 'left', sortable: false },
  { key: 'ourPrice', label: 'Our Price', align: 'left', sortable: false },
  { key: 'discount', label: 'Discount', align: 'left', sortable: false },
  { key: 'discountAmount', label: 'Discount Amount', align: 'left', sortable: false },
  { key: 'discountedPrice', label: 'Discounted Price', align: 'left', sortable: false },
  { key: 'deliverySlot', label: 'Delivery Slot', align: 'left', sortable: false },
  { key: 'deliveryCharge', label: 'Delivery Charge', align: 'center', sortable: false },
  { key: 'deliveryDate', label: 'Delivery Date', align: 'left', sortable: false },
];

const OrderListPage = () => {
  const dispatch = useDispatch();
  const tableProps = useTable();
  const methods = useForm();
  const { count } = usePageCount(getOrder);
  const page = tableProps.page;
  const theme = useTheme();

  const [showFilter, setShowFilter] = useState(false);
  const [startDate, setStartDate] = useState<Date | undefined | null>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined | null>(undefined);
  const [query, setQuery] = useState('');

  const orderStatusData = useWatch({ control: methods.control, name: 'orderStatus' });

  useEffect(() => {
    const payload: IGetOrder = { page };

    if (startDate && endDate && orderStatusData) {
      payload.startDate = dayjs(startDate).format('YYYY-MM-DD');
      payload.endDate = dayjs(endDate).format('YYYY-MM-DD');
      payload.status = orderStatusData;
      dispatch(getOrder(payload));
    } else if (startDate && endDate) {
      payload.startDate = dayjs(startDate).format('YYYY-MM-DD');
      payload.endDate = dayjs(endDate).format('YYYY-MM-DD');
      dispatch(getOrder(payload));
    } else if (orderStatusData && !startDate && !endDate && query.length === 0) {
      payload.status = orderStatusData;
      dispatch(getOrder(payload));
    } else if (query.length !== 0) {
      payload.q = query;
      dispatch(getOrder(payload));
    } else if (startDate == null && endDate == null && query.length === 0) {
      dispatch(getOrder(payload));
    }
  }, [dispatch, endDate, orderStatusData, page, query, startDate]);

  const handleSearchChange = (event: any) => {
    setQuery(event.target.value);
  };
  const handleFilter = () => {
    setShowFilter(!showFilter);
  };

  const handleClearFilter = () => {
    methods.setValue('orderStatus', '');
    methods.reset();
    setStartDate(null);
    setEndDate(null);
  };

  const { data, loading } = useRequestState({
    stateSelector: selectOrderState,
    errorShown: true,
  });
  const flattenData = (data: any) => {
    const serializeData = data?.map((elem: any) => {
      const totalPrice = calculateTotalPrice(elem?.orderProducts);
      const totalDiscount = calculateTotalDiscount(elem?.orderProducts);
      const activeSlot: IDeliverySlot = isActiveSlot(elem?.orderSlots);
      const Total = totalPrice - totalDiscount;
      const discountPercents = (totalDiscount / totalPrice) * 100;

      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      const customerAddress = `${elem?.customerAddress?.city}, ${elem?.customerAddress?.country}`;

      const deliveryDate = activeSlot?.deliveryDate
        ? dayjs(activeSlot.deliveryDate).format('YYYY-MM-DD')
        : 'NA';
      const deliverySlot =
        activeSlot?.slotType === 'normal'
          ? `${dayjs(`2022-01-01 ${activeSlot?.deliveryStartTime}`).format('hh:mm A')} - ${dayjs(
              `2022-01-01 ${activeSlot?.deliveryEndTime}`
            ).format('hh:mm A')}`
          : `Express (${activeSlot?.duration} min)`;

      return {
        ...elem,
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        customer: `${elem?.customerAddress?.name} ${elem?.customerAddress?.mobileNumber}`,
        //  customerNumber: elem?.customerAddress?.mobileNumber,
        address: customerAddress,
        skuId: elem?.orderProducts[0]?.sku?.id,
        skuName: elem?.orderProducts[0]?.sku?.name,
        vendorName: elem?.orderProducts[0]?.vendorAddress?.name,
        paymentMode: elem?.paymentMode,
        qtyOrdered: elem?.orderProducts?.length,
        ourPrice: totalPrice,
        discount: discountPercents.toFixed(2),
        discountAmount: totalDiscount,
        discountedPrice: Total,
        deliverySlot,
        deliveryCharge: activeSlot?.deliveryCharge,
        deliveryDate,
      };
    });
    return serializeData;
  };

  const orderStatus = ['Confirmed', 'Picked', 'Preparing', 'Delivering', 'Delivered', 'Canceled'];

  return (
    <>
      <FormProvider {...methods}>
        <Header
          filter
          onClickFilter={() => {
            handleFilter();
          }}
          title={Strings.pageTitle.orderManagement}
          helperText={''}
          searchBarProps={{
            placeholder: Strings.common.search,
            onChange: handleSearchChange,
          }}
          data={flattenData(data)}
          csvHead={TABLE_HEAD_CSV}
          csvFileName={'order-list.csv'}
          additionalExportButtonTitle={Strings.button.exportCsv}
          additionalExportPdfButtonTitle={Strings.button.exportpdf}
          orientation={'landscape'}
        />
        {showFilter && (
          <Stack sx={{ p: 2 }}>
            <Stack direction={'row'} alignItems={'center'} gap={1} pb={3}>
              <Typography variant="h5">Filter</Typography>
              <Stack height={19} width={19}>
                <img src={`/assets/icons/navbar/filter.svg`} alt="back" />
              </Stack>
            </Stack>
            <Divider />
            <Stack direction={'row'} pt={3} pb={1} width={'60%'} gap={3}>
              <RHFSelect
                fullWidth
                name="orderStatus"
                label={Strings.field.orderStatus}
                sx={{ width: '50%' }}
                variant="outlined"
              >
                {orderStatus.map((status) => (
                  <MenuItem key={status} value={status}>
                    {status}
                  </MenuItem>
                ))}
              </RHFSelect>
              <DatePicker
                value={startDate}
                onChange={(newValue: any) => {
                  setStartDate(newValue);
                }}
                name="startDate"
                label="Start Date"
                sx={{ width: '50%' }}
                slotProps={{ popper: { placement: 'right' } }}
              />
              <Typography sx={{ textAlign: 'center', alignItems: 'center', display: 'flex' }}>
                -
              </Typography>
              <DatePicker
                value={endDate}
                onChange={(newValue: any) => {
                  setEndDate(newValue);
                }}
                name="endDate"
                label="End Date"
                sx={{ width: '50%' }}
                slotProps={{ popper: { placement: 'right' } }}
                shouldDisableDate={(date) => dayjs(date).isBefore(dayjs(startDate).startOf('day'))}
              />
              {(startDate != null || endDate != null || typeof orderStatusData === 'string') && (
                <IconButton sx={{ p: 0.25, borderRadius: '5px' }} onClick={handleClearFilter}>
                  <Iconify
                    width={25}
                    icon="material-symbols:filter-alt-off"
                    color={theme.palette.primary.main}
                  />
                </IconButton>
              )}
            </Stack>
          </Stack>
        )}
        <OrderList loading={loading} tableProps={tableProps} rows={data} count={count} />
      </FormProvider>
    </>
  );
};
export { OrderListPage };
