import { type ReactNode } from 'react';
import { Stack, Hidden, Typography, Box, type Breakpoint } from '@mui/material';

import { SideIllustration, Logo } from '@app/components';
import { Strings } from '@app/constants';
import { StyledContent, StyledCopyright } from './styles';

// --------------------------------------------------------------

interface Props {
  children: ReactNode;
  title: string;
}

const hideIllustrationBreakpoints: Breakpoint[] = ['xs', 'sm'];
const showLogoBreakpoints: Breakpoint[] = ['lg', 'md', 'xl'];

const AuthLayout = ({ children }: Props) => {
  return (
    <>
      <Stack direction="row" flex={1}>
        <Hidden only={hideIllustrationBreakpoints}>
          <Box width="40%" height={1}>
            <SideIllustration />
          </Box>
        </Hidden>

        <StyledContent>
          <Hidden only={showLogoBreakpoints}>
            <Stack alignItems="center" mb={5}>
              <Logo disabledLink />
            </Stack>
          </Hidden>

          {children}

          <StyledCopyright>
            <Hidden only={hideIllustrationBreakpoints}>
              <Box width="40%" />
            </Hidden>
            <Typography variant="body2" flex={1} textAlign="center">
              {Strings.common.copyright}
            </Typography>
          </StyledCopyright>
        </StyledContent>
      </Stack>
    </>
  );
};
export { AuthLayout };
