import { useEffect, useState } from 'react';
import {
  Divider,
  Stack,
  DialogContent,
  DialogActions,
  Dialog,
  Button,
  Checkbox,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';

import { Strings } from '@app/constants';
import { Header } from '@app/sections/header';
import { useTable } from '@app/components/table';
import { ICollectionDetail } from '../types';
import { addNewCollectionDetailProduct } from '../slice';
import { TruncatedTypography } from '@app/components/typography';
import { SearchBar } from '@app/components';
import { getSku } from '@app/modules/sku';
import { selectSkuState } from '@app/modules/sku/selectors';
import { useRequestState } from '@app/hooks';
import { selectAddNewCollectionProductState } from '../selectors';

interface Props {
  open: boolean;
  onClose: VoidFunction;
  row?: ICollectionDetail;
  collectionId: number;
}

const AddProductCollectionDetail = ({ open, onClose, row, collectionId }: Props) => {
  const dispatch = useDispatch();
  const tableProps = useTable();
  const page = tableProps.page;

  const handleSearchChange = (event: any) => {
    setQuery(event.target.value);
  };

  const [query, setQuery] = useState('');

  useEffect(() => {
    if (query.length !== 0) {
      dispatch(getSku({ page, q: query }));
    } else {
      dispatch(getSku({ page }));
    }
  }, [dispatch, page, query]);

  const SkuDataState = useSelector(selectSkuState);

  const [selectedItemIds, setSelectedItemIds] = useState<number[]>([]);

  const handleCheckboxChange = (itemId: number) => {
    setSelectedItemIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(itemId)) {
        return prevSelectedIds.filter((id) => id !== itemId);
      } else {
        return [...prevSelectedIds, itemId];
      }
    });
  };
  const { loading } = useRequestState({
    stateSelector: selectAddNewCollectionProductState,
    onSuccess: onClose,
    successMessageShown: true,
    errorShown: true,
  });

  const handleClick = () => {
    dispatch(addNewCollectionDetailProduct({ skuId: selectedItemIds, id: collectionId }));
  };

  return (
    <>
      <Dialog open={open} fullWidth>
        <Header variant="dialog" title={Strings.button.addNew} onClose={onClose} />

        <Divider />
        <SearchBar
          sx={{ padding: '2% 7% 0% 2%' }}
          onChange={handleSearchChange}
          placeholder={Strings.common.search}
        />

        <DialogContent>
          <Stack spacing={2} my={2}>
            {SkuDataState?.data?.map((item: any, index: number) => {
              return (
                <>
                  <Stack display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                    <Stack
                      sx={{ width: '170px' }}
                      key={index}
                      onClick={() => {
                        handleCheckboxChange(item.id);
                      }}
                    >
                      <TruncatedTypography text={item.skuId} lines={1} />
                    </Stack>
                    <Stack
                      sx={{
                        width: '300px',
                        display: 'flex',
                        flexWrap: 'wrap',
                      }}
                      key={index}
                      onClick={() => {
                        handleCheckboxChange(item.id);
                      }}
                    >
                      <TruncatedTypography text={item?.name ? item.name : 'NA'} lines={1} />
                    </Stack>
                    <Checkbox
                      checked={selectedItemIds.includes(item.id)}
                      onChange={() => {
                        handleCheckboxChange(item.id);
                      }}
                    />
                  </Stack>
                </>
              );
            })}
          </Stack>
        </DialogContent>

        <Divider />

        <DialogActions>
          <Button variant="outlined" color="inherit" onClick={onClose}>
            {Strings.button.cancel}
          </Button>

          <LoadingButton loading={loading} type="submit" variant="contained" onClick={handleClick}>
            {Strings.button.save}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddProductCollectionDetail;
