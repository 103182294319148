import { useFormContext, Controller } from 'react-hook-form';
import { Autocomplete, type AutocompleteProps, TextField, CircularProgress } from '@mui/material';
import { useRef, useState } from 'react';

// ----------------------------------------------------------------------

interface Props<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> extends AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> {
  name: string;
  label?: string;
  helperText?: React.ReactNode;
  callApi?: () => Promise<void>;
  hasMore?: boolean;
  loading?: boolean;
}

export function RHFAutocomplete<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
>({
  name,
  label,
  helperText,
  callApi,
  loading,
  hasMore,
  ...other
}: Omit<Props<T, Multiple, DisableClearable, FreeSolo>, 'renderInput'>) {
  const { control, setValue } = useFormContext();

  const [prevScrollTop, setPrevScrollTop] = useState(0);

  const autocompleteRef = useRef<HTMLUListElement | null>(null);

  const handleScroll = async (event: React.UIEvent<HTMLUListElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
    const bottomThreshold = scrollHeight - clientHeight - 10;

    if (scrollTop > bottomThreshold && scrollTop > prevScrollTop && hasMore && callApi) {
      await callApi();
    }
    setPrevScrollTop(scrollTop);
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <>
          <Autocomplete
            {...field}
            ref={autocompleteRef}
            onChange={(event, newValue) => {
              setValue(name, newValue, { shouldValidate: true, shouldDirty: true });
            }}
            ListboxProps={{
              onScroll: handleScroll,
            }}
            loading={loading}
            renderInput={(params) => (
              <TextField
                label={label}
                error={!!error}
                helperText={error ? error?.message : helperText}
                {...params}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            {...other}
          />
        </>
      )}
    />
  );
}
