import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

import { RequestStatus } from '@app/constants';
import { IRequestFailed } from '@app/types';
import { IAuthState, type ILoginSchema, IForgotPasswordSchema, ISetPasswordSchema } from './types';

// ------------------------------------------------------

const initialState: IAuthState = {
  login: {},
  forgotPassword: {},
  setPassword: {},
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login(state, action: PayloadAction<ILoginSchema>) {
      state.login.status = RequestStatus.RUNNING;
    },
    loginSuccess(state, action) {
      state.login.status = RequestStatus.SUCCESS;
      state.login.data = action.payload;
    },
    loginFailed(state, action: PayloadAction<IRequestFailed>) {
      state.login.status = RequestStatus.ERROR;
      state.login.error = action.payload;
    },
    forgotPassword(state, action: PayloadAction<IForgotPasswordSchema>) {
      state.forgotPassword.status = RequestStatus.RUNNING;
    },
    forgotPasswordSuccess(state, action) {
      state.forgotPassword.status = RequestStatus.SUCCESS;
      state.forgotPassword.data = action.payload;
    },
    forgotPasswordFailed(state, action: PayloadAction<IRequestFailed>) {
      state.forgotPassword.status = RequestStatus.ERROR;
      state.forgotPassword.error = action.payload;
    },
    setPassword(state, action: PayloadAction<ISetPasswordSchema>) {
      state.setPassword.status = RequestStatus.RUNNING;
    },
    setPasswordSuccess(state, action) {
      state.setPassword.status = RequestStatus.SUCCESS;
      state.setPassword.data = action.payload;
    },
    setPasswordFailed(state, action: PayloadAction<IRequestFailed>) {
      state.setPassword.status = RequestStatus.ERROR;
      state.setPassword.error = action.payload;
    },
    // Logout
    logout() {
      //
    },
  },
});

export default slice.reducer;

export const {
  login,
  loginSuccess,
  loginFailed,
  forgotPassword,
  forgotPasswordSuccess,
  forgotPasswordFailed,
  setPassword,
  setPasswordSuccess,
  setPasswordFailed,
  logout,
} = slice.actions;
