import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { RequestStatus } from '@app/constants';
import { IRequestFailed, IRequestStatus } from '@app/types';
import { IChangeDeliverySlot, IOrderDetailState, ISlot } from './types';

const initialState: IOrderDetailState = {
  getOrderDetails: {},
  updateOrderStatus: {},
  slots: {},
  changedDeliverySlot: {},
};

const slice = createSlice({
  name: 'orderDetails',
  initialState,
  reducers: {
    getOrderDetails(state, action: PayloadAction<{ id: number }>) {
      state.getOrderDetails.status = RequestStatus.RUNNING;
    },
    getOrderDetailsSuccess(
      state,
      action: PayloadAction<IOrderDetailState['getOrderDetails']['data']>
    ) {
      state.getOrderDetails.status = RequestStatus.SUCCESS;
      state.getOrderDetails.data = action.payload;
    },
    getOrderDetailsFailed(state, action: PayloadAction<IRequestFailed>) {
      state.getOrderDetails.status = RequestStatus.ERROR;
      state.getOrderDetails.error = action.payload;
    },
    //
    getSlotsByDateType(state, action: PayloadAction<ISlot>) {
      state.slots.status = RequestStatus.RUNNING;
    },
    getSlotsByDateTypeSuccess(state, action: PayloadAction<IOrderDetailState['slots']['data']>) {
      state.slots.status = RequestStatus.SUCCESS;
      state.slots.data = action.payload;
    },
    getSlotsByDateTypeFailed(state, action: PayloadAction<IRequestFailed>) {
      state.slots.status = RequestStatus.ERROR;
      state.slots.error = action.payload;
    },
    //
    changeDeliverySlot(state, action: PayloadAction<IChangeDeliverySlot>) {
      state.changedDeliverySlot.status = RequestStatus.RUNNING;
    },
    changeDeliverySlotSuccess(
      state,
      action: PayloadAction<IOrderDetailState['changedDeliverySlot']['data']>
    ) {
      state.changedDeliverySlot.status = RequestStatus.SUCCESS;
      state.changedDeliverySlot.data = action.payload;
    },
    changeDeliverySlotFailed(state, action: PayloadAction<IRequestFailed>) {
      state.changedDeliverySlot.status = RequestStatus.ERROR;
      state.changedDeliverySlot.error = action.payload;
    },
    //
    updateOrderStatus(state, action: PayloadAction<{ id: number; orderStatus: string }>) {
      state.updateOrderStatus.status = RequestStatus.RUNNING;
    },
    updateOrderStatusSuccess(state, action: PayloadAction<IRequestStatus>) {
      state.updateOrderStatus.status = RequestStatus.SUCCESS;
      state.updateOrderStatus.data = action.payload;
    },
    updateOrderStatusFailed(state, action: PayloadAction<IRequestFailed>) {
      state.updateOrderStatus.status = RequestStatus.ERROR;
      state.updateOrderStatus.error = action.payload;
      state.updateOrderStatus.data = action.payload;
    },
  },
});
export default slice.reducer;

export const {
  getOrderDetails,
  getOrderDetailsFailed,
  getOrderDetailsSuccess,
  updateOrderStatus,
  updateOrderStatusFailed,
  updateOrderStatusSuccess,
  getSlotsByDateType,
  getSlotsByDateTypeFailed,
  getSlotsByDateTypeSuccess,
  changeDeliverySlot,
  changeDeliverySlotFailed,
  changeDeliverySlotSuccess,
} = slice.actions;
