import { styled } from '@mui/material';

export const StyledBackground = styled('div')(({ theme }) => ({
  background: theme.palette.secondary.main,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
}));
