import { Divider, Dialog, useTheme } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Box from '@mui/material/Box';
import { useCallback, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import CSVReader from 'react-csv-reader';
import { useDispatch } from 'react-redux';
import { CSVLink } from 'react-csv';
import Papa from 'papaparse';

import { Header } from '@app/sections/header';
import { Strings } from '@app/constants';
import { useRequestState } from '@app/hooks';
import { selectAddBulkInventoryState } from '../selectors';
import { addBulkInventory } from '../slice';
import { IMergedData } from '../types';

interface Props {
  open: boolean;
  onClose: VoidFunction;
}
const AddInventoryInImport = ({ open, onClose }: Props) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const [fileData, setFileData] = useState([]);
  const [csvFileDownload, setCsvFileDownload] = useState(false);
  const [headersCsv, setHeadersCsv] = useState();
  const [modifiedFileData, setModifiedFileData] = useState([]);

  const header = [
    'operation',
    'stock',
    'price',
    'ourPrice',
    'vendorId',
    'skuId',
    'discountPrice',
    'reason',
  ];

  const csvDownload = useCallback(() => {
    setCsvFileDownload(true);
  }, []);

  const { data, loading } = useRequestState({
    stateSelector: selectAddBulkInventoryState,
    successMessageShown: true,
    onSuccess: onClose,
    errorShown: true,
    onFailed: csvDownload,
  });

  const onSubmit = () => {
    dispatch(addBulkInventory({ fileData }));
  };

  function findDataByVendorId(data: any, fileData: any) {
    const ids = data?.map((v: any) => v.id) || [];
    return fileData?.filter((item: any) => {
      for (let i = 0; i < ids.length; i++) {
        if (String(item.vendorId) === String(ids[i])) {
          return true;
        }
      }
      return false;
    });
  }

  function findDataBySkuId(data: any, fileData: any) {
    const id = data?.map((v: any) => v.id) || [];
    return fileData.filter((item: any) => {
      for (let i = 0; i < id.length; i++) {
        if (String(item.skuId) === String(id[i])) {
          return true;
        }
      }
      return false;
    });
  }

  function findDataByIds(data: any, fileData: any) {
    const id = data?.map((v: any) => v.skuId) || [];
    return fileData.filter((item: any) => {
      for (let i = 0; i < id.length; i++) {
        if (String(item.skuId) === String(id[i])) {
          return true;
        }
      }
      return false;
    });
  }

  function findDataBySkuIdVendorId(actionError: any, fileData: any) {
    const ids =
      actionError?.map((errorItem: any) => ({
        skuId: String(errorItem.skuId),
        vendorId: String(errorItem.vendorId),
      })) || [];

    return fileData.filter((item: any) => {
      for (let i = 0; i < ids.length; i++) {
        if (String(item.skuId) === ids[i].skuId && String(item.vendorId) === ids[i].vendorId) {
          return true;
        }
      }
      return false;
    });
  }

  useEffect(() => {
    let idCounter = 1;
    if (csvFileDownload) {
      const vendor = data?.data?.vendor;
      const sku = data?.data?.sku;
      const id = data?.data?.ids;
      const actionError = data?.data?.action;

      // -------------------------------------
      const foundId = findDataByIds(id, fileData);
      const newIdData = foundId.map((data: any) => {
        const updatedMessage = id
          .filter((id: any) => data.skuId.includes(id.skuId))
          .map((ven: any) => ven.message)
          .join(', ');

        return { ...data, message: updatedMessage };
      });

      // -------------------------------------
      const foundData = findDataByVendorId(vendor, fileData);
      const newVendorData = foundData.map((data: any) => {
        const updatedMessage = vendor
          .filter((ven: any) => data.vendorId.includes(ven.id))
          .map((ven: any) => ven.message)
          .join(', ');

        return { ...data, message: updatedMessage };
      });

      // -------------------------------------
      const foundSku = findDataBySkuId(sku, fileData);
      const newSkuData = foundSku.map((data: any) => {
        const updatedMessage = sku
          .filter((ven: any) => data.skuId.includes(ven.id))
          .map((ven: any) => ven.message)
          .join(', ');

        return { ...data, message: updatedMessage };
      });

      // -------------------------------------
      const foundSkuIdVendorId = findDataBySkuIdVendorId(actionError, fileData);
      const newSkuVendorData = foundSkuIdVendorId.map((data: any) => {
        const updatedMessage = actionError
          .filter(
            (err: any) =>
              String(err.skuId) === String(data.skuId) &&
              String(err.vendorId) === String(data.vendorId)
          )
          .map((err: any) => err.message)
          .join(', ');
        return { ...data, message: updatedMessage };
      });

      const combinedData = [
        ...newVendorData,
        ...newSkuData,
        ...newIdData,
        ...newSkuVendorData,
        ...fileData
          .filter((item) => {
            return (
              !findDataByIds(id, [item]).length &&
              !findDataByVendorId(vendor, [item]).length &&
              !findDataBySkuId(sku, [item]).length &&
              !foundSkuIdVendorId.includes(item)
            );
          })
          .map((item: any) => ({ ...item, message: '' })),
      ];

      const combinedDataWithId: IMergedData[] = combinedData.map((obj: any) => ({
        ...obj,
        id: idCounter++,
      }));

      const mergedData: IMergedData[] = combinedDataWithId.reduce((acc: any, obj: IMergedData) => {
        const key = `${obj.skuId}-${obj.vendorId}-${obj.operation}${
          obj.message.split(',')[0] === Strings.csv.duplicateVendorAndSkuPairs ? `-${obj.id}` : ''
        }` as keyof typeof acc;

        if (!acc[key]) {
          acc[key] = { ...obj };
        } else {
          const newMessages = obj.message
            .split(', ')
            .filter((msg: any) => !acc[key].message.includes(msg));
          // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
          acc[key].message += newMessages.length > 0 ? ', ' + newMessages.join(', ') : '';
        }
        return acc;
      }, {});

      const uniqueMessagesData = Array.from(Object.values(mergedData)).map((item: any) => {
        const { id, ...restItems } = item;
        return {
          ...restItems,
          message: restItems.message
            .split(', ')
            .filter(
              (message: string, index: number, self: string[]) => self.indexOf(message) === index
            )
            .join(', '),
        };
      });

      const updatedFileData: any = Object.values(uniqueMessagesData);

      // ------------------------------------------------------------------------------------------------

      setModifiedFileData(updatedFileData);
      const csvBlob = new Blob([Papa.unparse(updatedFileData)], {
        type: 'text/csv;charset=utf-8;',
      });
      const csvUrl = URL.createObjectURL(csvBlob);
      const hiddenElement = document.createElement('a');
      hiddenElement.href = csvUrl;
      hiddenElement.target = '_blank';
      hiddenElement.download = 'inventories.csv';
      hiddenElement.click();
      onClose();
    }
  }, [csvFileDownload, data, fileData, onClose]);

  const handleFileLoaded = (data: string | any[], fileInfo: any, originalFile: any) => {
    setCsvFileDownload(false);
    const dataArray: any = [];
    const headers = data[0];
    headers.push('message');
    setHeadersCsv(headers);
    for (let i = 1; i < data.length; i++) {
      const row = data[i];
      if (row.length === 0 || row.every((cell: any) => cell.trim() === '')) {
        continue;
      }
      const rowData: any = {};
      for (let j = 0; j < headers.length; j++) {
        if (row[j] != null && typeof row[j] === 'string') {
          rowData[headers[j]] = row[j].trim();
        } else {
          rowData[headers[j]] = row[j] || '';
        }
      }
      dataArray.push(rowData);
    }
    setFileData(dataArray);
  };

  return (
    <>
      <Dialog open={open} fullWidth>
        <Header variant="dialog" title={Strings.button.import} onClose={onClose} />
        <Divider />
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={1} style={{ marginTop: 0, marginBottom: 2, padding: 30 }}>
            <Grid item xs={6}>
              <CSVReader onFileLoaded={handleFileLoaded} />
              {csvFileDownload && (
                <CSVLink
                  data={modifiedFileData}
                  headers={headersCsv}
                  filename={'inventories.csv'}
                ></CSVLink>
              )}
            </Grid>
            <Grid item xs={6} style={{ paddingTop: 3 }}>
              <LoadingButton
                type="submit"
                variant="contained"
                loading={loading}
                onClick={() => {
                  onSubmit();
                }}
              >
                {Strings.button.save}
              </LoadingButton>
            </Grid>
            <Grid container direction="column" alignItems="end" mt={1.5}>
              <CSVLink
                data=""
                headers={header}
                filename={'template.csv'}
                style={{ fontSize: '15px', color: theme.palette.success.main }}
              >
                {Strings.button.downloadTemplate}
              </CSVLink>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    </>
  );
};

export default AddInventoryInImport;
