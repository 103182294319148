import dayjs from 'dayjs';
import { Stack, Typography } from '@mui/material';

import { TableCellCustom } from '@app/components/table';
import { TruncatedTypography } from '@app/components/typography';

interface Props {
  selected: boolean;
  onSelectRow: VoidFunction;
  row: any;
}

const InventoryEditRow = (props: Props) => {
  const { onSelectRow, row } = props;

  const formattedDate = dayjs(row?.time).format('YYYY-MM-DD HH:mm:ss');

  return (
    <>
      <TableCellCustom width={40} onClick={onSelectRow}>
        <TruncatedTypography text={row?.id ? row?.id : 'N/A'} />
      </TableCellCustom>
      <TableCellCustom flex onClick={onSelectRow}>
        <TruncatedTypography text={row?.operation ? row?.operation : 'N/A'} />
      </TableCellCustom>
      <TableCellCustom onClick={onSelectRow} align="center">
        <Typography sx={{ fontSize: '14px' }}>
          {row?.reason ? row.reason.replace(/-ORD-/, '-ORD-\n') : 'N/A'}
        </Typography>
      </TableCellCustom>
      <TableCellCustom onClick={onSelectRow} align="center">
        <TruncatedTypography text={row?.lastStock ? row?.lastStock : 'N/A'} />
      </TableCellCustom>
      <TableCellCustom flex onClick={onSelectRow} align="center">
        <TruncatedTypography text={row?.operation === 'add' ? row?.stockUpdated : 'NA'} />
      </TableCellCustom>
      <TableCellCustom flex onClick={onSelectRow} align="center">
        <TruncatedTypography text={row?.operation === 'remove' ? row?.stockUpdated : 'NA'} />
      </TableCellCustom>
      <TableCellCustom flex onClick={onSelectRow} align="center">
        <TruncatedTypography text={row?.newStock ? row?.newStock : 'N/A'} />
      </TableCellCustom>
      <TableCellCustom flex onClick={onSelectRow}>
        <Stack sx={{ width: '150px' }}>
          <TruncatedTypography text={formattedDate || 'N/A'} />
        </Stack>
      </TableCellCustom>
    </>
  );
};

export { InventoryEditRow };
