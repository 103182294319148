import { useState, useCallback } from 'react';

// ----------------------------------------------------------------------

interface ReturnType {
  rowMenuAnchorElement: HTMLElement | null;
  hide: VoidFunction;
  show: (event: any) => void;
}

export default function useMenuPopover(): ReturnType {
  const [rowMenuAnchorElement, setMenuAnchorElement] = useState<HTMLElement | null>(null);

  const show = useCallback((event: any) => {
    setMenuAnchorElement(event.currentTarget);
  }, []);

  const hide = useCallback(() => {
    setMenuAnchorElement(null);
  }, []);

  return {
    rowMenuAnchorElement,
    show,
    hide,
  };
}
