import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Header } from '@app/sections/header';
import { Strings } from '@app/constants';
import { useTable } from '@app/components/table';
import { useDialog, useRequestState } from '@app/hooks';
import BannerList from './banner-list';
import AddBanner from './add-banner';
import { getBanner } from '../slice';
import { selectBannerState } from '../selectors';
import { usePageCount } from '@app/hooks/use-page-count';

const BannerPage = () => {
  const tableProps = useTable();
  const BannerDialog = useDialog();
  const dispatch = useDispatch();
  const { count } = usePageCount(getBanner);
  const page = tableProps.page;

  const [query, setQuery] = useState('');
  useEffect(() => {
    if (query.length !== 0) {
      dispatch(getBanner({ page, q: query }));
    } else {
      dispatch(getBanner({ page }));
    }
  }, [dispatch, page, query]);

  useEffect(() => {
    dispatch(getBanner({ page }));
  }, [dispatch, page]);

  const { data, loading } = useRequestState({
    stateSelector: selectBannerState,
    errorShown: true,
  });

  const handleSearchChange = (event: any) => {
    setQuery(event.target.value);
  };

  return (
    <>
      <Header
        title={Strings.pageTitle.banner}
        helperText={''}
        actionButtonTitle={Strings.button.addNewBanner}
        searchBarProps={{
          placeholder: Strings.common.search,
          onChange: handleSearchChange,
        }}
        onClickActionButton={BannerDialog.show}
      />

      <BannerList loading={loading} tableProps={tableProps} rows={data} count={count} />

      {BannerDialog.visible && <AddBanner open onClose={BannerDialog.hide} />}
    </>
  );
};

export { BannerPage };
